/**
 * Componente RowItem
 *
 * Props:
 * - draggableProps, dragHandleProps: Para integrar con react-beautiful-dnd (opcional)
 * - disableDrag (bool): Si está en true, no se muestra el ícono/columna de drag.
 * - showToggle (bool): Mostrar o no el botón +/- para expandir/colapsar.
 * - onToggle (func): Acción al dar clic en el botón +/-.
 * - isExpanded (bool): Estado de si está expandido o no (para cambiar el ícono).
 * - nombre (string): Texto a mostrar (Tipo, Categoría o Subcategoría).
 * - paddingClass (string): Clase CSS para manejar el padding (p. ej. "tipo-nombre", "categoria-nombre", "subcategoria-nombre").
 * - actions (JSX): Botones de acción (Editar, Eliminar, Crear, etc.).
 */
import { Button } from "react-bootstrap";
import React from "react";

const RowItem = ({
  draggableProps,
  dragHandleProps,
  disableDrag = false,
  showToggle = false,
  onToggle = () => {},
  isExpanded = false,
  nombre = "",
  actions = null,
  padding=0
}) => {
  return (
    <tr
      ref={draggableProps && draggableProps.innerRef}
      {...(draggableProps ? draggableProps.props : {})}
      style={{ height: '50px' }}
    >
      {/* Primera columna: arrastre (si no está deshabilitado) o vacía */}
      <td
        className="drag-column"
        {...(disableDrag ? {} : dragHandleProps)}
        style={{ width: "10%" }}
      >
        {!disableDrag && <span style={{ cursor: "grab" }}>=</span>}
      </td>

      {/* Segunda columna: Botón +/- y Nombre */}
      <td style={{ width: "60%", paddingLeft: padding }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {showToggle ? (
          <Button
            variant="link"
            onClick={onToggle}
            className="toggle-btn"
            aria-expanded={isExpanded}
            style={{paddingLeft:0}}
          >
            {isExpanded ? "-" : "+"}
          </Button>
        ) : null}
        <div style={{ fontWeight: "bold"}}>{nombre}</div>
        </div>

      </td>


      {/* Tercera columna: Acciones (alineadas a la derecha) */}
      <td className="actions-column" style={{ width: "30%" }}>
        {actions}
      </td>
    </tr>
  );
};

export default RowItem;