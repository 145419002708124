import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/proyectos/proyectos';
import { actions as user } from '../../../../redux/modules/cuenta/login';
import { actions as recursos } from '../../../../redux/modules/recursos/recursos';
import { actions as clientes } from '../../../../redux/modules/clientes/clientes';
import { actions as tipos } from '../../../../redux/modules/tipos_proyecto/tipos_proyecto';
import { actions as bitacoras } from '../../../../redux/modules/bitacora_detalle_proyecto/bitacora_detalle_proyecto';
import Listar from './Listar';


const ms2p = (state) => {
  return {
    ...state.proyectos,
    user: { ...state.login },
    recursos: { ...state.recursos },
    clientes: { ...state.clientes },
    tipos_proyecto: state.tipos_proyecto.filter,
    bitacora: state.bitacora_detalle_proyecto.bitacora,
  };
};

const md2p = { 
    ...actions, 
    getRole: user.getRole, 
    getScrums: recursos.getAll, 
    getClientes: clientes.listAll, 
    getTipos: tipos.filterList,
    cargarBitacora2: bitacoras.cargarBitacora2,
};

export default connect(ms2p, md2p)(Listar);
