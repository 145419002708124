import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
  renderField,
  renderSelectField,
  renderSelectUnidadesNegocio,
  renderSelectFieldObject,
} from "../../Utils/renderField";
import Popup from "reactjs-popup";
import CrearRecurso from "./CrearRecurso";
import TablaRecursos from "./TablaRecursos";
import Finalizar from "./Finalizar";

let CrearEditarForm = (props) => {
  const {
    handleSubmit,
    recursos,
    puestos,
    clientes,
    fases,
    paises,
    recursos_encargados,
    tipos_proyecto,
    edit,
    edit_type,
    role,
    finalizarProyecto,
    finalizado,
    estado_pausa,
    viene_de_presupuestos,
    proyecto_presupuesto,
    activo,
    activarProyecto,
    pausarProyecto,
    recursos_submit,
    setRecurosSubmit,
    recursos_update,
    planificacion,
    setPlanificacion,
    planificacion_update,
    finalizar_data,
    tags,
    setTags,
    temp_tag,
    change,
    tipo_proyecto,
    categoria,
    subcategoria,
  } = props;

  const select_style = {
    control: (styles) => ({
      ...styles,
      color: "#1F4D7B",
      borderColor: "#1F4D7B",
    }),
  };

  const [popup_finalizado, setPopupFinalizado] = useState(false);
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    const newPlanificacion = fases
      .filter((f) => f.aparece_proyecto)
      .map((fase) => ({
        id: fase.id,
        nombre: fase.nombre,
        cantidad_recursos: "",
        horas_planificadas: "",
        horas_ejecutadas: "",
        fecha_inicio: "",
        fecha_fin: "",
      }));

    if (planificacion_update && edit) {
      planificacion_update.forEach((f) => {
        const index = newPlanificacion.findIndex((x) => x.id === f.fase_proyecto);
        if (index !== -1) {
          newPlanificacion[index].cantidad_recursos = f.cantidad_recursos;
          newPlanificacion[index].horas_planificadas = f.horas_planificadas;
          newPlanificacion[index].fecha_inicio = f.fecha_inicio;
          newPlanificacion[index].fecha_fin = f.fecha_fin;
        }
      });
    }
    setPlanificacion(newPlanificacion);
  }, [fases, edit, planificacion_update, setPlanificacion]);

  useEffect(() => {
    const newRecursosSubmit = fases
      .filter((f) => f.aparece_proyecto)
      .map((f) => ({
        id: f.id,
        nombre: f.nombre,
        recursos: [],
        fecha_inicio: "",
        fecha_fin: "",
      }));

    if (recursos_update && edit) {
      recursos_update.forEach((r) => {
        const idx = newRecursosSubmit.findIndex((n) => n.id === r.fase_proyecto);
        if (idx !== -1) {
          newRecursosSubmit[idx].recursos.push(r);
        }
      });
    }
    planificacion.forEach((p) => {
      const idx = newRecursosSubmit.findIndex((n) => n.id === p.id);
      if (idx !== -1) {
        newRecursosSubmit[idx].fecha_inicio = p.fecha_inicio;
        newRecursosSubmit[idx].fecha_fin = p.fecha_fin;
      }
    });
    setRecurosSubmit(newRecursosSubmit);
  }, [fases, recursos_update, edit, planificacion, setRecurosSubmit]);

  const compareDate = (a, b) => {
    if (a.fecha_inicio < b.fecha_inicio) return -1;
    if (a.fecha_inicio > b.fecha_inicio) return 1;
    return 0;
  };

  const updateArray = (data, recurso_index = null) => {
    const recursosSubmitCopy = [...recursos_submit];
    if (recurso_index) {
      recursosSubmitCopy[recurso_index.fase_proyecto].recursos.splice(
        recurso_index.index,
        1
      );
    }
    if (data) {
      const index = recursosSubmitCopy.findIndex(
        (e) => e.id === data.fase_proyecto
      );
      if (index !== -1) {
        recursosSubmitCopy[index].recursos.push(data);
        recursosSubmitCopy[index].recursos.sort(compareDate);
      }
    }
    setRecurosSubmit(recursosSubmitCopy);
    setUpdate(!update);
  };

  const updateArray1 = (i, value, id) => {
    const planificacionCopy = [...planificacion];
    planificacionCopy[i] = { ...planificacionCopy[i], [id]: value };
    setPlanificacion(planificacionCopy);
  };

  const [selectedTipoProyecto, setSelectedTipoProyecto] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [selectedSubcategoria, setSelectedSubcategoria] = useState(null);

  useEffect(() => {
    setSelectedTipoProyecto(tipo_proyecto || null);
    setSelectedCategoria(categoria || null);
    setSelectedSubcategoria(subcategoria || null);
  }, [tipo_proyecto, categoria, subcategoria]);

  const handleTipoProyectoChange = (option) => {
    setSelectedTipoProyecto(option || null);
    setSelectedCategoria(null);
    setSelectedSubcategoria(null);
    change("tipo_proyecto", option || null);
    change("categoria", null);
    change("subcategoria", null);
  };

  const handleCategoriaChange = (cat) => {
    setSelectedCategoria(cat || null);
    setSelectedSubcategoria(null);
    change("categoria", cat || null);
    change("subcategoria", null);
  };

  const handleSubcategoriaChange = (sub) => {
    setSelectedSubcategoria(sub || null);
    change("subcategoria", sub || null);
  };

  return (
    <React.Fragment>
      <form name="CrearEditarForm" onSubmit={handleSubmit}>
        <div className="card card-login w-100">
          <div className="row d-flex justify-content-end">
            {edit && (role === 1 || role === 10 || role === 11) && activo && (
              <div className="d-flex align-items-center">
                <button
                  type="button"
                  disabled={finalizado}
                  className="btn btn-primary m-1"
                  onClick={() => setPopupFinalizado(true)}
                >
                  Finalizar proyecto
                </button>
                <Popup open={popup_finalizado} onClose={() => setPopupFinalizado(false)}>
                  <Finalizar
                    finalizarProyecto={finalizarProyecto}
                    finalizar_data={finalizar_data}
                    horas={planificacion}
                    updateArray1={updateArray1}
                  />
                </Popup>
              </div>
            )}
            {proyecto_presupuesto && (role === 1 || role === 11) && (
              <Link
                to={`/proyectos_operaciones/${proyecto_presupuesto}/editar`}
                className="btn btn-primary m-1 align-self-center"
              >
                Ver presupuesto
              </Link>
            )}
          </div>
          <div className="row">
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="nombre">Nombre del proyecto</label>
              <Field
                name="nombre"
                label="Nombre"
                component={renderField}
                type="text"
                className="input--style"
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="tipo_proyecto">Unidad de negocio</label>
              <Field
                name="tipo_proyecto"
                component={renderSelectUnidadesNegocio}
                options={tipos_proyecto}
                placeholder="Seleccionar unidad..."
                select_style={select_style}
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                onChangeSelect={handleTipoProyectoChange}
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="encargado">Encargado</label>
              <Field
                name="encargado"
                label="Encargado"
                options={recursos_encargados}
                isClearable
                component={renderSelectField}
                labelKey="nombre"
                valueKey="id"
                select_style={select_style}
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
          </div>
          <div className="row">
            {selectedTipoProyecto &&
              Array.isArray(selectedTipoProyecto.categorias) &&
              selectedTipoProyecto.categorias.length > 0 && (
                <div className="form-group has-feedback col-12 col-md-4">
                  <label htmlFor="categoria">Categoría</label>
                  <Field
                    name="categoria"
                    component={renderSelectFieldObject}
                    options={selectedTipoProyecto.categorias}
                    placeholder="Seleccionar categoría..."
                    select_style={select_style}
                    disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                    onChangeSelect={handleCategoriaChange}
                    isClearable
                  />
                </div>
              )}
            {selectedCategoria &&
              Array.isArray(selectedCategoria.subcategorias) &&
              selectedCategoria.subcategorias.length > 0 && (
                <div className="form-group has-feedback col-12 col-md-4">
                  <label htmlFor="subcategoria">Subcategoría</label>
                  <Field
                    name="subcategoria"
                    component={renderSelectFieldObject}
                    options={selectedCategoria.subcategorias}
                    placeholder="Seleccionar subcategoría..."
                    select_style={select_style}
                    disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                    onChangeSelect={handleSubcategoriaChange}
                    isClearable
                  />
                </div>
              )}
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="cliente">Cliente</label>
              <Field
                name="cliente"
                label="Cliente"
                options={clientes}
                isClearable
                component={renderSelectField}
                labelKey="label"
                valueKey="value"
                select_style={select_style}
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="fecha_inicio">Fecha de inicio programado</label>
              <Field
                name="fecha_inicio"
                label="Fecha de inicio"
                component={renderField}
                type="date"
                className="input--style"
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="fecha_fin">Fecha final programada</label>
              <Field
                name="fecha_fin"
                label="Fecha final programada"
                component={renderField}
                type="date"
                className="input--style"
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="encargado_porcentaje_asignacion">% de tiempo del encargado</label>
              <Field
                name="encargado_porcentaje_asignacion"
                label="% tiempo"
                component={renderField}
                className="input--style"
                type="number"
                min="0"
                max="100"
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="fecha_inicio_ejecucion">Fecha de inicio ejecución</label>
              <Field
                name="fecha_inicio_ejecucion"
                label="Fecha de inicio"
                component={renderField}
                className="input--style"
                type="date"
                disabled
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="fecha_fin_ejecucion">Fecha final ejecución</label>
              <Field
                name="fecha_fin_ejecucion"
                label="Fecha final ejecución"
                component={renderField}
                className="input--style"
                type="date"
                disabled
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="pais_asueto">País asueto</label>
              <Field
                name="pais_asueto"
                label="País asueto"
                options={paises}
                component={renderSelectField}
                labelKey="label"
                valueKey="value"
                select_style={select_style}
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group has-feedback col-12 col-md-4">
              <label htmlFor="tags_input">Etiquetas</label>
              <Field
                name="tags_input"
                label="Etiquetas"
                component={renderField}
                className="input--style"
                disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                onKeyPress={(key) => {
                  if (key.key.toUpperCase() === "ENTER") {
                    key.preventDefault();
                    const newTag = temp_tag.trim();
                    if (newTag && !tags.some((tag) => tag.trim().toLowerCase() === newTag.toLowerCase())) {
                      setTags([...tags, newTag]);
                    }
                    change("tags_input", "");
                  }
                }}
              />
            </div>
            <div className="form-group has-feedback col-12 col-md-8 row">
              {tags.map((tag, i) => (
                <div className="col text-center mt-3" key={i}>
                  <span className="badge badge-pill badge-info tag">
                    {tag}
                    <i
                      className="fa fa-window-close ml-2"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        if (role === 1 || role === 11) {
                          setTags(tags.filter((t) => t !== tag));
                        }
                      }}
                    />
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="form-group has-feedback col-12 row">
              <h5 style={{ marginLeft: "15px" }}>Fases</h5>
              <div className="table-responsive d-flex justify-content-center">
                <table className="table resources--table" style={{ width: "98%" }}>
                  <thead className="thead--blue">
                    <tr>
                      <th scope="col">Fase</th>
                      <th scope="col">Cantidad recursos</th>
                      <th scope="col">Horas Programadas</th>
                      <th scope="col">Fecha inicio</th>
                      <th scope="col">Fecha final</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planificacion.map((obj, index) => (
                      <tr key={index} className="border--lr border--bt border--last">
                        <td>{obj.nombre}</td>
                        <td>
                          <input
                            className="form-control input--style"
                            type="number"
                            placeholder="0"
                            value={obj.cantidad_recursos}
                            onChange={(e) =>
                              updateArray1(index, e.target.value, "cantidad_recursos")
                            }
                            disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control input--style"
                            type="number"
                            placeholder="0"
                            value={obj.horas_planificadas}
                            onChange={(e) =>
                              updateArray1(index, e.target.value, "horas_planificadas")
                            }
                            disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control input--style"
                            type="date"
                            value={obj.fecha_inicio || ""}
                            onChange={(e) =>
                              updateArray1(index, e.target.value, "fecha_inicio")
                            }
                            disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                          />
                        </td>
                        <td>
                          <input
                            className="form-control input--style"
                            type="date"
                            value={obj.fecha_fin || ""}
                            onChange={(e) =>
                              updateArray1(index, e.target.value, "fecha_fin")
                            }
                            disabled={(edit && edit_type !== "general") || (role !== 1 && role !== 11)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {edit && edit_type === "recursos" && (
          <div>
            <div className="d-flex flex-column align-items-start pt-3">
              <h3 className="title--blue">FASES Y RECURSOS DEL PROYECTO</h3>
            </div>
            <hr />
            <div className="card card-login w-100">
              <CrearRecurso updateArray={updateArray} estado_pausa={estado_pausa} />
              <TablaRecursos
                recursos_submit={recursos_submit}
                updateArray={updateArray}
                estado_pausa={estado_pausa}
              />
              <div className="form-group has-feedback invisible">
                <Field name="encargado_porcentaje_asignacion" component={renderField} />
              </div>
            </div>
          </div>
        )}
        <div className="buttons-box mt-3 mb-3">
          {(viene_de_presupuestos || estado_pausa) && (
            <button
              type="button"
              style={{ fontSize: 15 }}
              className="btn btn-warning m-1 align-self-center"
              onClick={activarProyecto}
            >
              Activar proyecto
            </button>
          )}
          {edit && activo && (
            <button
              type="button"
              style={{ fontSize: 15 }}
              className="btn btn-warning m-1 align-self-center"
              onClick={pausarProyecto}
            >
              Pausar proyecto
            </button>
          )}
          <Link to="/proyectos" className="btn btn-secondary" style={{ fontSize: 15 }}>
            Regresar
          </Link>
          <button type="submit" style={{ fontSize: 15 }} className="btn btn-primary m-1 align-self-center">
            Guardar
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

CrearEditarForm = reduxForm({
  form: "CrearEditarForm",
  validate: (data) =>
    validate(data, {
      nombre: validators.exists()("Este campo es requerido"),
      tipo_proyecto: validators.exists()("Este campo es requerido"),
      encargado_porcentaje_asignacion: validators.exists()("Este campo es requerido"),
    }),
})(CrearEditarForm);

const selector = formValueSelector("CrearEditarForm");
CrearEditarForm = connect((state) => {
  return {
    temp_tag: selector(state, "tags_input") || "",
    viene_de_presupuestos: selector(state, "viene_de_presupuestos"),
    estado_pausa: selector(state, "estado_pausa"),
    activo: selector(state, "activo"),
    proyecto_presupuesto: selector(state, "proyecto_presupuesto"),
    categoria: selector(state, "categoria"),
    subcategoria: selector(state, "subcategoria"),
    tipo_proyecto: selector(state, "tipo_proyecto"),
  };
})(CrearEditarForm);

export default CrearEditarForm;
