import { validate, validators } from "validate-redux-form";
import { validateArrayNoRepeatedField } from "../../Utils/Validators";

const validateFields = (data) => {
    const validations = validate(data, {
        codigo: validators.exists()("Este campo es requerido"),
        nombre: validators.exists()("Este campo es requerido"),
        email: validators.exists()("Este campo es requerido"),
        tipo_contratacion: validators.exists()("Este campo es requerido"),
        puesto: validators.exists()("Este campo es requerido"),
        proyecto_default: validators.exists()("Este campo es requerido"),
        fecha_inicio: validators.exists()("Este campo es requerido"),
        skills_experiencia: [
            {
                skill: validators.exists()("Este campo es requerido"),
                experiencia: validators.exists()("Este campo es requerido"),
            },
        ],
        tipo_contrato: validators.exists()("Este campo es requerido"),
        nacionalidad: validators.exists()("Este campo es requerido"),
        direccion: validators.exists()("Este campo es requerido"),
        //pais: validators.exists()("Este campo es requerido"),
        //departamento: validators.exists()("Este campo es requerido"),
        //municipio: validators.exists()("Este campo es requerido"),
        estado_civil: validators.exists()("Este campo es requerido"),
        genero: validators.exists()("Este campo es requerido"),
        nivel_academico: validators.exists()("Este campo es requerido"),
        nombre_contacto_emergencia: validators.exists()("Este campo es requerido"),
        telefono_contacto_emergencia: validators.exists()("Este campo es requerido"),
        titulacion: validators.exists()("Este campo es requerido"), 
        salario: validators.exists()("Este campo es requerido"),
        pretencion_salarial: validators.exists()("Este campo es requerido"),
        tipo_moneda: validators.exists()("Este campo es requerido"),
        telefono: validators.exists()("Este campo es requerido"),
        dpi: validators.exists()("Este campo es requerido"),
        nivel_ingles: validators.exists()("Este campo es requerido"),
        fecha_nacimiento: validators.exists()("Este campo es requerido"),
        dias_vacaciones: validators.number({ min: 0 })(
            "Valor mayor o igual a 0 requerido"
        ),
        nit: validators.exists()("Este campo es requerido"),
    });


    const validateCertificadods = [];
    if (data.certificados_ingles) {
        data.certificados_ingles.forEach((certificado) => {
            if (certificado.id < 0) {
                validateCertificadods.push(
                    validate(certificado, {
                        archivo: validators.exists()("Agregue un archivo"),
                    })
                );
            } else {
                validateCertificadods.push({});
            }
        });
    }

    return {
        ...validations,
        ...validateArrayNoRepeatedField({
            data,
            fieldname: "skills_experiencia",
            min: 0,
            message: "Skills repetidas",
            emptyMessage: "",
            fields: ["skill"],
        }),
        certificados_ingles: validateCertificadods,
    };
};

export default validateFields;
