import React from "react";
import moment from "moment";
import './TablaRecursos.css'; 

//Componente para listar recursos de un proyecto
const TablaRecursos = (props) => {
    return (
        <div className={`table-responsive ${props.estado_pausa ? 'disabled-table' : ''}`}>
            <table className="table resources--table">
                <thead className="thead--blue">
                    <tr>
                        <th scope="col">Fase</th>
                        <th scope="col">Recurso</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Porcentaje</th>
                        <th scope="col">Fecha inicio</th>
                        <th scope="col">Fecha final</th>
                    </tr>
                </thead>
                <tbody>
                    {props.recursos_submit
                        ? props.recursos_submit.map((e, index) => {
                              const primer_recurso =
                                  e.recursos.length > 0 ? e.recursos[0] : null;
                              const recursos = [];
                              if (primer_recurso) {
                                  for (let i = 1; i < e.recursos.length; i++) {
                                      recursos.push(e.recursos[i]);
                                  }
                              }
                              return (
                                  <React.Fragment key={index}>
                                      <tr
                                          className={`border--lr border--bt ${
                                              recursos.length == 0
                                                  ? "border--last"
                                                  : null
                                          }`}
                                      >
                                          <th
                                              scope="row"
                                              rowSpan={
                                                  primer_recurso
                                                      ? recursos.length + 1
                                                      : 1
                                              }
                                          >
                                              <p
                                                  style={{
                                                      marginBottom: "0px",
                                                  }}
                                              >
                                                  {e.nombre}
                                              </p>
                                              {e.fecha_inicio ? (
                                                  <p
                                                      style={{
                                                          marginBottom: "0px",
                                                      }}
                                                  >
                                                      {moment(
                                                          e.fecha_inicio
                                                      ).format(
                                                          "DD/MM/YYYY"
                                                      )}{" "}
                                                      -{" "}
                                                      {moment(
                                                          e.fecha_fin
                                                      ).format("DD/MM/YYYY")}
                                                  </p>
                                              ) : null}
                                          </th>
                                          {primer_recurso ? (
                                              <React.Fragment>
                                                  <td>
                                                      {
                                                          primer_recurso.recurso_nombre
                                                      }
                                                  </td>
                                                  <td>
                                                      {
                                                          primer_recurso.puesto_label
                                                      }
                                                  </td>
                                                  <td>
                                                      {
                                                          primer_recurso.porcentaje_asignacion
                                                      }
                                                      %
                                                  </td>
                                                  <td>
                                                  {moment(primer_recurso.fecha_inicio).format("DD/MM/YYYY")}
                                                  </td>
                                                  <td>
                                                  {moment(primer_recurso.fecha_fin).format("DD/MM/YYYY")}
                                                  </td>
                                              </React.Fragment>
                                          ) : (
                                              <React.Fragment>
                                                  <td colSpan={6}>
                                                      No hay recursos.
                                                  </td>
                                              </React.Fragment>
                                          )}
                                      </tr>
                                      {recursos.map((f, index1) => (
                                          <tr
                                              key={index1}
                                              className={`border--lr border--bt ${
                                                  index1 == recursos.length - 1
                                                      ? "border--last"
                                                      : null
                                              }`}
                                          >
                                              <td>{f.recurso_nombre}</td>
                                              <td>{f.puesto_label}</td>
                                              <td>
                                                  {f.porcentaje_asignacion}%
                                              </td>
                                              <td>{moment(f.fecha_inicio).format("DD/MM/YYYY")}</td>
                                              <td>{moment(f.fecha_fin).format("DD/MM/YYYY")}</td>
                                          </tr>
                                      ))}
                                  </React.Fragment>
                              );
                          })
                        : null}
                </tbody>
            </table>
        </div>
    );
};

export default TablaRecursos;
