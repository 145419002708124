import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/proyectos_operaciones/proyectos_operaciones";
import { actions as actionsActividades } from "../../../../redux/modules/actividades_entrega/actividades_entrega";
import { actions as actionsFasesProyecto } from "../../../../redux/modules/fases_proyecto/fases_proyecto";
import { actions as actionsPuestos } from "../../../../redux/modules/puestos/puestos";
import { actions as actionsClientes } from "../../../../redux/modules/clientes/clientes";
import { actions as actionTipos } from "../../../../redux/modules/tipos_proyecto/tipos_proyecto";
import { actions as actionsPlantillas } from "../../../../redux/modules/plantillas_proyectos/plantillas_proyectos";
import { actions as actionsGastos } from "../../../../redux/modules/gastos_administrativos/gastos_administrativos";
import { actions as actionsSeniority, actualizarSeniority } from "../../../../redux/modules/seniority/seniority";
import CrearEditar from "./CrearEditar";

const ms2p = (state) => {
    return {
        ...state.proyectos_operaciones,
        actividades: state.actividades_entrega.filter,
        loaderActividades: state.actividades_entrega.loader,
        fases: state.fases_proyecto.filter,
        loaderFases: state.fases_proyecto.loader,
        puestos: state.puestos.filterAll,
        loaderPuestos: state.puestos.loader,
        clientes: state.clientes.all_clients,
        tipos_proyectos: state.tipos_proyecto.unidades_negocio,
        plantillas: state.plantillas_proyectos.filter,
        loaderPlantillas: state.plantillas_proyectos.loader,
        gastos: state.gastos_administrativos.filter,
        loaderGastos: state.gastos_administrativos.loader,
        seniority: state.seniority.filter,
        loaderSeniority: state.seniority.loader,
    };
};

const md2p = {
    ...actions,
    obtenerActividades: actionsActividades.obtenerFilterList,
    crearActividad: actionsActividades.create,
    obtenerFases: actionsFasesProyecto.obtenerFilterList,
    obtenerPuestos: actionsPuestos.filterAll,
    obtenerClientes: actionsClientes.listAll,
    obtenerTipos: actionTipos.filterList,
    obtenerPlantillas: actionsPlantillas.filter,
    obtenerGastos: actionsGastos.obtenerFilterList,
    obtenerSeniority: actionsSeniority.filter,
    getUnidadesNegocio: actionTipos.fetchUnidadesNegocio,
};

export default connect(ms2p, md2p)(CrearEditar);
