// calculations.js

const FASES = {
    BACKLOG: 1,
    DISENO: 5,
    DESAROLLO: 2,
    QA: 4,
    AJUSTES_QA: 8,
    SERVIDOR: 7,
    IMPLEMENTACION: 3,
    DOCUMENTACION: 6,
};

const PUESTOS = {
    SCRUM_MASTER: 1,
    PROGRAMADOR: 2,
    DISENADOR: 3,
    QA: 4,
    SYS_ADMIN: 5,
};

const numberFormatter = new Intl.NumberFormat("es-GT", {
    style: "decimal",
    maximumFractionDigits: 2,
});
const currencyFormatter = new Intl.NumberFormat("es-GT", {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    maximumFractionDigits: 2,
});

/**
 * Función principal que arma el resumen de Presupuesto.
 */
export const calulateSummary = ({
    currentPlantilla,
    personalValue = [],
    modulosValue = [],
    gastosValue = [],
    actividadesValue = [],
    impuestosAdicionales,
    margenNegocio,
    gananciaAdicional,
    comisionVenta,
}) => {
    if (!currentPlantilla) currentPlantilla = {};
    const { fases_puestos = [] } = currentPlantilla;
    let horasSemanalesScrum = 0;
    let horasSemanalesDisenador = 0;

    personalValue.forEach((personalAsignado) => {
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );

        if (!puestoPlantilla) return;

        if (Number(personalAsignado.puesto) === PUESTOS.SCRUM_MASTER) {
            horasSemanalesScrum +=
                puestoPlantilla.horas_semanales *
                Number(personalAsignado.cantidad);
        }

        if (Number(personalAsignado.puesto) === PUESTOS.DISENADOR) {
            horasSemanalesDisenador +=
                puestoPlantilla.horas_semanales *
                Number(personalAsignado.cantidad);
        }
    });

    const [horas, semanas] = calculateTime({
        modulosValue,
        currentPlantilla,
        horasSemanalesDisenador,
        horasSemanalesScrum,
        actividadesValue,
        personalValue,
    });

    const finanzas = calculateFinances({
        horas,
        semanas,
        currentPlantilla,
        gastosValue,
        personalValue,
        impuestosAdicionales,
        margenNegocio,
        gananciaAdicional,
        comisionVenta,
    });

    return [
        {
            title: "Horas",
            values: horas,
            suffix: " hrs",
            formatter: numberFormatter,
        },
        {
            title: "Finanzas",
            values: finanzas,
            suffix: "",
            formatter: currencyFormatter,
        },
        {
            title: "Semanas",
            values: semanas,
            suffix: " semanas",
            formatter: numberFormatter,
        },
    ];
};

/**
 * Cálculo de módulos.
 */
export const calculateModulos = (modulosValue) => {
    let total = 0;
    modulosValue.forEach((modulo) => {
        total += Number(modulo.horas_ideales) || 0;
    });
    return total;
};

/**
 * Cálculo de actividades.
 */
export const calculateActividades = (actividadesValue) => {
    let total = 0;
    actividadesValue.forEach((actividad) => {
        total += Number(actividad.total_horas) || 0;
    });
    return total;
};

/**
 * Cálculo de gastos.
 */
export const calculateGastos = (gastosValue) => {
    let total = 0;
    gastosValue.forEach((gasto) => {
        total += Number(gasto.total) || 0;
    });
    return total;
};

/**
 * Obtiene las fases a partir de la plantilla.
 */
export const getFasesFromPlantilla = (plantilla = {}) => {
    const { fases_puestos = [] } = plantilla;
    const fases = {};
    fases_puestos.forEach((f) => {
        fases[f.fase] = { value: f.fase, label: f.fase_label };
    });
    return Object.values(fases);
};

/**
 * Función que calcula las horas y semanas.
 * Se ha agregado la propiedad "horas_garantia" para incluir las horas de garantía.
 */
export const calculateTime = ({
    modulosValue,
    currentPlantilla = {},
    horasSemanalesDisenador,
    horasSemanalesScrum,
    actividadesValue,
    personalValue,
}) => {
    let horas = {
        horas_desarollo: { label: "Horas desarollo", value: 0 },
        horas_totales: { label: "Horas totales", value: 0, highlight: true },
        horas_scrum: { label: "Horas SCRUM", value: 0 },
        horas_diseno: { label: "Horas diseño", value: 0 },
        margen_error: { label: "Margen de error", value: 0 },
        horas_entrega: { label: "Horas entrega", value: 0 },
        // NUEVO: Horas de garantía se obtiene de currentPlantilla
        horas_garantia: {
            label: "Horas garantía",
            value: Number(currentPlantilla.total_horas_garantia || 0),
        },
    };

    let semanas = {
        total_semanas: { label: "Total semanas", value: 0, highlight: true },
    };

    const { part_margen_error = 0, porcentajes = [] } = currentPlantilla;

    /* HORAS DE DESARROLLO */
    horas.horas_desarollo.value += calculateModulos(modulosValue);
    actividadesValue.forEach((actividad) => {
        if (actividad.fase === FASES.DESAROLLO) {
            horas.horas_desarollo.value += Number(actividad.total_horas);
        }
    });

    horas.margen_error.value =
        horas.horas_desarollo.value * (part_margen_error / 100);
    horas.horas_desarollo.value += horas.margen_error.value;

    /* OBTENER LAS FASES DE LA PLANTILLA */
    const fases = getFasesFromPlantilla(currentPlantilla);

    /* CALCULAR HORAS POR FASE */
    const horasCalculadas = {};
    fases.forEach((fase) => {
        if (fase.value === FASES.DESAROLLO) return;
        horasCalculadas[fase.value] = { label: fase.label, value: 0 };
        const porcentaje = porcentajes.find((p) => p.fase === fase.value);
        if (porcentaje) {
            horasCalculadas[porcentaje.fase].value +=
                horas.horas_desarollo.value * (porcentaje.porcentaje / 100);
        }
        let totalHorasActividades = 0;
        actividadesValue.forEach((actividad) => {
            if (actividad.fase === fase.value) {
                totalHorasActividades += Number(actividad.total_horas);
            }
        });
        horasCalculadas[fase.value].value += totalHorasActividades;
    });

    horas = { ...horasCalculadas, ...horas };

    /* CALCULAR HORAS TOTALES */
    const horasCalculadasValues = Object.values(horasCalculadas);
    horasCalculadasValues.forEach((h) => {
        horas.horas_totales.value += h.value;
    });
    horas.horas_totales.value += horas.horas_desarollo.value;
    // Incluir las horas de garantía en el total
    horas.horas_totales.value += horas.horas_garantia.value;

    /* HORAS DE ACTIVIDADES DE ENTREGA */
    horas.horas_entrega.value += calculateActividades(actividadesValue);

    /* CALCULAR SEMANAS POR FASE */
    const { fases_puestos = [] } = currentPlantilla;
    const horasSemanaPorFase = {};

    personalValue.forEach((personalAsignado) => {
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );
        if (!horasSemanaPorFase[personalAsignado.fase]) {
            horasSemanaPorFase[personalAsignado.fase] = 0;
        }
        if (puestoPlantilla && puestoPlantilla.suma_semana) {
            horasSemanaPorFase[personalAsignado.fase] +=
                personalAsignado.cantidad * puestoPlantilla.horas_semanales;
        }
    });

    const semanasPorFase = {};
    fases.forEach((f) => {
        const fase = f.value;
        const cantidadHoras = horasSemanaPorFase[fase] || 0;
        semanasPorFase[fase] = {
            label: `Semanas ${f.label}`,
            value: 0,
        };
        if (cantidadHoras > 0) {
            if (fase === FASES.DESAROLLO) {
                semanasPorFase[fase].value =
                    horas.horas_desarollo.value / cantidadHoras;
            } else {
                semanasPorFase[fase].value =
                    horasCalculadas[fase].value / cantidadHoras;
            }
        }
    });
    Object.values(semanasPorFase).forEach((s) => {
        semanas.total_semanas.value += s.value;
    });
    semanas = { ...semanasPorFase, ...semanas };

    /* HORAS PARA SCRUM Y DISEÑO */
    horas.horas_scrum.value = horasSemanalesScrum * semanas.total_semanas.value;
    horas.horas_diseno.value =
        horasSemanalesDisenador * semanas.total_semanas.value;

    return [horas, semanas];
};

/**
 * Función que calcula las finanzas a partir de las horas, semanas y otros parámetros.
 */
export const calculateFinances = ({
    semanas,
    horas,
    currentPlantilla = {},
    gastosValue,
    personalValue,
    impuestosAdicionales,
    margenNegocio,
    gananciaAdicional,
    comisionVenta,
}) => {
    let finanzas = {
        project_manager: { label: "Project Manager", value: 0 },
        desarrollo: {
            label: "Precio desarrollo con impuesto",
            value: 0,
            highlight: true,
        },
        desarrollo_sin_impuestos: {
            label: "Precio desarrollo sin impuesto (17%)",
            value: 0,
            highlight: true,
            className: "text-danger",
        },
        administrativos: { label: "Gastos administrativos", value: 0 },
        garantia: { label: "Garantía", value: 0 },
        comision_venta: { label: "Comisión venta", value: 0 },
        margen_negociacion: { label: "Margen negociación", value: 0 },
        ganancia_adicional: { label: "Ganancia adicional", value: 0 },
        impuestos_adicionales: { label: "Impuestos adicionales", value: 0 },
        total: { label: "Total", value: 0, highlight: true },
        total_sin_iva: {
            label: "Total sin IVA (12%)",
            value: 0,
            highlight: true,
            className: "text-danger",
        },
    };

    const { fases_puestos = [] } = currentPlantilla;

    /* CALCULAR TARIFAS: HORAS SEMANALES POR FASE Y SCRUM */
    const tarifaSemanaPorFase = {};
    let tarifaSemanaScrum = 0;
    personalValue.forEach((personalAsignado) => {
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );

        if (!tarifaSemanaPorFase[personalAsignado.fase]) {
            tarifaSemanaPorFase[personalAsignado.fase] = 0;
        }

        if (
            personalAsignado.puesto === PUESTOS.SCRUM_MASTER &&
            puestoPlantilla
        ) {
            tarifaSemanaScrum +=
                Number(personalAsignado.cantidad) *
                Number(personalAsignado.tarifa) *
                puestoPlantilla.horas_semanales;
        } else if (puestoPlantilla && puestoPlantilla.suma_semana) {
            tarifaSemanaPorFase[personalAsignado.fase] +=
                Number(personalAsignado.cantidad) *
                Number(personalAsignado.tarifa) *
                puestoPlantilla.horas_semanales;
        }
    });

    /* TOTAL TARIFAS POR FASE */
    const totalTarifas = {};
    const fases = getFasesFromPlantilla(currentPlantilla);
    fases.forEach((f) => {
        const label = f.label;
        const fase = f.value;
        totalTarifas[fase] = { label, value: 0 };
        const tarifaSemana = tarifaSemanaPorFase[fase] || 0;
        totalTarifas[fase].value = tarifaSemana * (semanas[fase] ? semanas[fase].value : 0);
    });

    let semanasDesarrollo = semanas.total_semanas;
    semanasDesarrollo = semanasDesarrollo ? semanasDesarrollo.value : 0;
    finanzas.project_manager.value += tarifaSemanaScrum * semanasDesarrollo;

    /* AGREGAR GASTOS DE LOS PUESTOS QUE NO CUENTAN POR SEMANA */
    personalValue.forEach((personalAsignado) => {
        if (personalAsignado.puesto === PUESTOS.SCRUM_MASTER) return;
        const puestoPlantilla = fases_puestos.find(
            (fasePuesto) =>
                Number(personalAsignado.puesto) === fasePuesto.puesto &&
                personalAsignado.fase === fasePuesto.fase
        );

        if (puestoPlantilla && !puestoPlantilla.suma_semana) {
            totalTarifas[personalAsignado.fase].value +=
                Number(personalAsignado.cantidad) *
                Number(personalAsignado.tarifa) *
                (horas[personalAsignado.fase] ? horas[personalAsignado.fase].value : 0);
        }
    });

    /* SUMAR TOTALES */
    Object.values(totalTarifas).forEach((tarifa) => {
        finanzas.desarrollo.value += tarifa.value;
    });
    finanzas.desarrollo.value += finanzas.project_manager.value;

    finanzas.desarrollo_sin_impuestos.value =
        finanzas.desarrollo.value - finanzas.desarrollo.value * 0.17;

    finanzas.total.value += finanzas.desarrollo.value;

    // Sumar gastos administrativos
    finanzas.administrativos.value += calculateGastos(gastosValue);
    finanzas.total.value += finanzas.administrativos.value;

    // CALCULAR GARANTÍA (si tu lógica incluye algún cálculo, aquí podrías asignarlo; por defecto se deja en 0)
    finanzas.total.value += finanzas.garantia.value;

    // CALCULAR COMISIÓN DE VENTA
    finanzas.comision_venta.value =
        finanzas.desarrollo_sin_impuestos.value * (Number(comisionVenta) / 100);
    finanzas.total.value += finanzas.comision_venta.value;

    // CALCULAR MARGEN DE NEGOCIACIÓN
    finanzas.margen_negociacion.value =
        (finanzas.desarrollo.value +
            finanzas.administrativos.value +
            finanzas.garantia.value) *
        (Number(margenNegocio) / 100);
    finanzas.total.value += finanzas.margen_negociacion.value;

    // CALCULAR GANANCIA ADICIONAL
    finanzas.ganancia_adicional.value =
        (finanzas.desarrollo.value +
            finanzas.administrativos.value +
            finanzas.garantia.value) *
        (Number(gananciaAdicional) / 100);
    finanzas.total.value += finanzas.ganancia_adicional.value;

    // CALCULAR IMPUESTOS ADICIONALES
    finanzas.impuestos_adicionales.value =
        (finanzas.desarrollo.value +
            finanzas.administrativos.value +
            finanzas.garantia.value +
            finanzas.comision_venta.value +
            finanzas.margen_negociacion.value +
            finanzas.ganancia_adicional.value) *
        (Number(impuestosAdicionales) / 100);
    finanzas.total.value += finanzas.impuestos_adicionales.value;

    finanzas.total_sin_iva.value =
        finanzas.total.value - finanzas.total.value * 0.12;

    return finanzas;
};

export { numberFormatter, currencyFormatter, FASES, PUESTOS };
