import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import {
  initialize as initializeForm,
  destroy as destroyForm,
  change as changeFormField,
} from "redux-form";
import Swal from "sweetalert2";
import { NotificationManager } from "react-notifications";
import { api } from "api";

const LOADER = "PROYECTO_OPERACIONES_LOADER";
const DATA = "PROYECTO_OPERACIONES_DATA";
const SUBMIT = "PROYECTO_OPERACIONES_SUBMIT";
const PLANTILLA = "PROYECTO_OPERACIONES_PLANTILLA";
const DRAFT = "PROYECTO_OPERACIONES_DRAFT";

const ENDPOINT = "proyecto_operaciones";

export const constants = {
  SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
  type: LOADER,
  loader,
});

export const setData = (data) => ({
  type: DATA,
  data,
});

export const setCurrentPlantilla = (currentPlantilla) => ({
  type: PLANTILLA,
  currentPlantilla,
});

export const setDraft = (draft) => ({
  type: DRAFT,
  draft,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = (data = {}, revision = false) => (dispatch, getStore) => {
  dispatch(setLoader(true));
  const endpoint = revision ? `${ENDPOINT}/create_send_to_check` : ENDPOINT;

  // Hacemos una copia de data para manipular
  const finalData = { ...data };

  // Aquí convertimos objetos a IDs:
  if (finalData.tipo_proyecto && finalData.tipo_proyecto.value) {
    finalData.tipo_proyecto = finalData.tipo_proyecto.value;
  }
  if (finalData.categoria && finalData.categoria.value) {
    finalData.categoria = finalData.categoria.value;
  }
  if (finalData.subcategoria && finalData.subcategoria.value) {
    finalData.subcategoria = finalData.subcategoria.value;
  }

  // NOTA: El campo "codigo" ya viene actualizado con el prefijo (por la lógica en el componente)
  console.log(finalData);

  api.post(endpoint, finalData)
    .then(() => {
      Swal.fire({
        title: "Éxito",
        text: "Se ha guardado correctamente",
        type: "success",
        showConfirmButton: false,
        timer: 500,
      });
      dispatch(setDraft(null));
      dispatch(destroyForm("ProyectoOperacionesForm"));
      dispatch(push("/proyectos_operaciones"));
    })
    .catch((error) => {
      let textError =
        error && error.non_field_errors
          ? error.non_field_errors[0]
          : "Ha ocurrido un error, inténtelo nuevamente.";

      if (error) {
        textError = Object.entries(error).reduce(
          (textError, currentError) => {
            return textError + currentError[0] + ": " + currentError[1].join(". ") + "\n";
          },
          ""
        );
      }
      Swal.fire({
        title: "Error",
        text: textError,
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};


export const listar =
  (page = 1, params = {}) =>
    (dispatch) => {
      dispatch(setLoader(true));
      api.get(`${ENDPOINT}`, { page, ...params })
        .then((response) => {
          dispatch(setData(response));
        })
        .catch(() => { })
        .finally(() => {
          dispatch(setLoader(false));
        });
    };

export const obtenerProyectoOperaciones = (id = null) => (dispatch) => {
  dispatch(setLoader(true));
  api.get(`${ENDPOINT}/${id}`)
    .then((response) => {
      // Si "tipo_proyecto" viene como objeto { id, nombre }, lo transformamos
      // Transformar tipo_proyecto a { value, label, hasChildren, ...rest }
      response.tipo_proyecto = response.tipo_proyecto
        ? {
          ...response.tipo_proyecto,
          value: response.tipo_proyecto.id,
          label: response.tipo_proyecto.nombre,
          hasChildren: response.categoria ? true : false,
        }
        : null;

      // Transformar categoria a { value, label, hasChildren, ...rest }
      response.categoria = response.categoria
        ? {
          ...response.categoria,
          value: response.categoria.id,
          label: response.categoria.nombre,
          hasChildren: response.categoria.subcategoria ? true : false,
        }
        : null;

      // Transformar subcategoria (sin hasChildren ya que no se mencionan sub-subcategorias)
      response.subcategoria = response.subcategoria
        ? {
          ...response.subcategoria,
          value: response.subcategoria.id,
          label: response.subcategoria.nombre,
        }
        : null;

      // Convertimos cliente y plantilla como lo hacías antes
      response.cliente = response.cliente ? response.cliente.id : null;
      response.plantilla = response.plantilla ? response.plantilla.id : null;

      // Lo mismo para archivos, etc.
      if (response.archivos) {
        response.archivos = response.archivos.map((archivo) => ({
          id: archivo.id,
          photo: archivo.archivo,
        }));
      }
      if (response.tipo_proyecto) {
        response.tipo_proyecto = Object.assign({}, response.tipo_proyecto, {
          value: response.tipo_proyecto.id,
          label: response.tipo_proyecto.nombre,
          categorias:
            response.tipo_proyecto.categorias &&
              response.tipo_proyecto.categorias.length > 0
              ? response.tipo_proyecto.categorias.map(function (cat) {
                return Object.assign({}, cat, {
                  value: cat.id,
                  label: cat.nombre,
                });
              })
              : []
        });
      } else {
        response.tipo_proyecto = null;
      }

      // Transformar categoría: asignar value y label, y formatear sus subcategorías
      if (response.categoria) {
        response.categoria = Object.assign({}, response.categoria, {
          value: response.categoria.id,
          label: response.categoria.nombre,
          subcategorias:
            response.categoria.subcategorias &&
              response.categoria.subcategorias.length > 0
              ? response.categoria.subcategorias.map(function (sub) {
                return Object.assign({}, sub, {
                  value: sub.id,
                  label: sub.nombre,
                });
              })
              : []
        });
      } else {
        response.categoria = null;
      }

      // Transformar subcategoría: asignar value y label
      if (response.subcategoria) {
        response.subcategoria = Object.assign({}, response.subcategoria, {
          value: response.subcategoria.id,
          label: response.subcategoria.nombre,
        });
      } else {
        response.subcategoria = null;
      }
      // Inicializamos el formulario con la data transformada
      console.log("response", response);
      dispatch(initializeForm("ProyectoOperacionesForm", response));

      // Si necesitas cargar la plantilla asociada
      obtenerPlantilla(response.plantilla, false)(dispatch);
    })
    .catch(() => { })
    .finally(() => {
      dispatch(setLoader(false));
    });
};


export const actualizarProyecto =
  (data = {}, id = null) =>
    async (dispatch) => {
      console.log("data", data);
      dispatch(setLoader(true));
      let correct = false;
      console.log("DATA: ",data)

      // Hacemos una copia de data para manipular
      const finalData = { ...data };
      // Aquí convertimos objetos a IDs:
      if (finalData.tipo_proyecto && finalData.tipo_proyecto.value) {
        finalData.tipo_proyecto = finalData.tipo_proyecto.value;
      }
      if (finalData.categoria && finalData.categoria.value) {
        finalData.categoria = finalData.categoria.value;
      }
      if (finalData.subcategoria && finalData.subcategoria.value) {
        finalData.subcategoria = finalData.subcategoria.value;
      }

      // NOTA: El campo "codigo" ya viene actualizado con el prefijo (por la lógica en el componente)
      console.log(finalData);

      try {
        await api.patch(`${ENDPOINT}/${id}`, finalData);
        NotificationManager.success("Cambios guardados", "Actualizado", 1000);
        correct = true;
      } catch (error) {
        correct = false;
        let textError =
          error && error.non_field_errors
            ? error.non_field_errors[0]
            : "Ha ocurrido un error, inténtelo nuevamente.";

        if (error) {
          textError = Object.values(error).reduce(
            (textError, currentError) => {
              return textError + currentError.join(". ") + "\n";
            },
            ""
          );
        }

        Swal.fire({
          title: "Error",
          text: textError,
          type: "error",
        });
      } finally {
        dispatch(setLoader(false));
      }

      return correct;
    };

export const actualizarProyectoOperaciones = (data = {}, id = null) => async (dispatch) => {
  dispatch(setLoader(true));
  const endpoint = `${ENDPOINT}/${id}`;

  // Hacemos una copia de data para manipular
  const finalData = { ...data };
  // Aquí convertimos objetos a IDs:
  if (finalData.tipo_proyecto && finalData.tipo_proyecto.value) {
    finalData.tipo_proyecto = finalData.tipo_proyecto.value;
  }
  if (finalData.categoria && finalData.categoria.value) {
    finalData.categoria = finalData.categoria.value;
  }
  if (finalData.subcategoria && finalData.subcategoria.value) {
    finalData.subcategoria = finalData.subcategoria.value;
  }

  // NOTA: El campo "codigo" ya viene actualizado con el prefijo (por la lógica en el componente)
  console.log("ACA", finalData);

  try {
    await api.put(endpoint, finalData);
    Swal.fire({
      title: "Éxito",
      text: "Se ha actualizado correctamente",
      type: "success",
      showConfirmButton: false,
      timer: 500,
    });
    dispatch(setDraft(null));
    dispatch(destroyForm("ProyectoOperacionesForm"));
    dispatch(push("/proyectos_operaciones"));
  } catch (error) {
    let textError =
      error && error.non_field_errors
        ? error.non_field_errors[0]
        : "Ha ocurrido un error, inténtelo nuevamente.";

    if (error) {
      textError = Object.entries(error).reduce(
        (textError, currentError) => {
          return textError + currentError[0] + ": " + currentError[1].join(". ") + "\n";
        },
        ""
      );
    }
    Swal.fire({
      title: "Error",
      text: textError,
      type: "error",
    });
  } finally {
    dispatch(setLoader(false));
  }
};

export const eliminar = (id) => (dispatch) => {
  dispatch(setLoader(true));
  api.eliminar(`${ENDPOINT}/${id}`)
    .then(() => {
      Swal.fire({
        title: "Éxito",
        text: "Se ha eliminado correctamente",
        type: "success",
      });
    })
    .catch(() => { })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

// Se utiliza el endpoint junto con el método enviarAOperaciones para enviar proyecto a operaciones 
export const enviarAOperaciones = (id, params = {}) => (dispatch) => {
  dispatch(setLoader(true));
  api.post(`${ENDPOINT}/${id}/enviarAOperaciones`)
    .then((response) => {
      Swal.fire({
        title: "Éxito",
        text: "Se ha enviado el proyecto a operaciones correctamente",
        type: "success",
      });
    })
    .catch(() => {
      Swal.fire({
        title: "Error",
        text: "El proyecto ya fue enviado a operaciones",
        type: "error",
      });
    })
    .finally(() => {
      dispatch(setLoader(false));
    });
};

const limpiarForm = () => (dispatch) => {
  dispatch(setCurrentPlantilla(null));
  dispatch(initializeForm("ProyectoOperacionesForm", {}));
};

// actions/plantillaActions.js
export const obtenerPlantilla =
  (id = null, setValues = true) =>
    (dispatch) => {
      dispatch(setLoader(true));
      api.get(`plantilla_proyecto/${id}`)
        .then((response) => {
          if (setValues) {
            const actividades = response.requisitos_actividades.map((actividad) => ({
              ...actividad,
              total_horas: actividad.horas,
              de_plantilla: true,
            }));

            const personal = response.fases_puestos.map((puesto) => ({
              ...puesto,
              de_plantilla: true,
              cantidad: 1,
            }));
            dispatch(
              changeFormField("ProyectoOperacionesForm", "actividades", actividades)
            );
            dispatch(
              changeFormField("ProyectoOperacionesForm", "personal", personal)
            );
          }
          // Aquí se guarda el objeto completo de la plantilla
          console.log("plantilla: ", response)
          dispatch(setCurrentPlantilla(response));
        })
        .catch(() => { })
        .finally(() => {
          dispatch(setLoader(false));
        });
    };


const updateDraft = (draft) => (dispatch) => {
  dispatch(setDraft(draft));
};

const clearDraft = () => (dispatch) => {
  dispatch(setDraft(null));
};

const loadFromDraft = (draft) => (dispatch) => {
  if (draft && draft.plantilla) {
    obtenerPlantilla(draft.plantilla, false)(dispatch);
    dispatch(initializeForm("ProyectoOperacionesForm", draft));
  }
};

const aprobarProyecto = (id = null) => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    await api.put(`${ENDPOINT}/${id}/approve`);
    Swal.fire({
      title: "Éxito",
      text: "Se ha aprobado el proyecto",
      type: "success",
      showConfirmButton: false,
      timer: 500,
    });
    dispatch(push("/proyectos_operaciones"));
  } catch (error) {
    let textError =
      error && error.non_field_errors
        ? error.non_field_errors[0]
        : "Ha ocurrido un error, inténtelo nuevamente.";

    if (error) {
      textError = Object.values(error).reduce(
        (textError, currentError) => {
          return textError + currentError.join(". ") + "\n";
        },
        ""
      );
    }

    Swal.fire({
      title: "Error",
      text: textError,
      type: "error",
    });
  } finally {
    dispatch(setLoader(false));
  }
};

const enviarRevision = (id = null) => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    await api.put(`${ENDPOINT}/${id}/update_send_to_check`);
    Swal.fire({
      title: "Éxito",
      text: "Se ha enviado a revisión",
      type: "success",
      showConfirmButton: false,
      timer: 500,
    });
    dispatch(push("/proyectos_operaciones"));
  } catch (error) {
    let textError =
      error && error.non_field_errors
        ? error.non_field_errors[0]
        : "Ha ocurrido un error, inténtelo nuevamente.";

    if (error) {
      textError = Object.values(error).reduce(
        (textError, currentError) => {
          return textError + currentError.join(". ") + "\n";
        },
        ""
      );
    }

    Swal.fire({
      title: "Error",
      text: textError,
      type: "error",
    });
  } finally {
    dispatch(setLoader(false));
  }
};


const ventaGanadaPerdida = (id = null, ganada = true) => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const endpoint = ganada
      ? `${ENDPOINT}/${id}/won_sale`
      : `${ENDPOINT}/${id}/lost_sale`;

    await api.put(endpoint);
    Swal.fire({
      title: "Éxito",
      text: ganada ? "Venta ganada" : "Venta perdida",
      type: ganada ? "success" : "info",
      showConfirmButton: false,
      timer: 500,
    });
    dispatch(push("/proyectos_operaciones"));
  } catch (error) {
    let textError =
      error && error.non_field_errors
        ? error.non_field_errors[0]
        : "Ha ocurrido un error, inténtelo nuevamente.";

    if (error) {
      textError = Object.values(error).reduce(
        (textError, currentError) => {
          return textError + currentError.join(". ") + "\n";
        },
        ""
      );
    }

    Swal.fire({
      title: "Error",
      text: textError,
      type: "error",
    });
  } finally {
    dispatch(setLoader(false));
  }
};


const uploadContract = async (projectId, file) => {
  const formData = new FormData();
  formData.append('contrato', file);

  const attachments = [{ name: 'contrato', file }];

  try {
    const response = await api.postAttachments(`proyecto_operaciones/${projectId}/upload_contract/`, {}, attachments);
    console.log('Contrato subido exitosamente:', response);
    return response;
  } catch (error) {
    console.error('Error al subir el contrato:', error);
    throw error;
  }
};


// Esta función se encarga de actualizar un campo en el formulario
// "ProyectoOperacionesForm" utilizando el helper changeFormField.

export const updateField = (field, value) => (dispatch) => {
  console.log("activado")
  dispatch(changeFormField("ProyectoOperacionesForm", field, value));
};


export const actions = {
  onSubmit,
  eliminar,
  obtenerProyectoOperaciones,
  actualizarProyectoOperaciones,
  enviarAOperaciones,
  listar,
  limpiarForm,
  obtenerPlantilla,
  updateDraft,
  clearDraft,
  loadFromDraft,
  actualizarProyecto,
  aprobarProyecto,
  enviarRevision,
  ventaGanadaPerdida,
  updateField,
  uploadContract,
};

export const reducers = {
  [LOADER]: (state, { loader }) => {
    return {
      ...state,
      loader,
    };
  },
  [DATA]: (state, { data }) => {
    return {
      ...state,
      data,
    };
  },
  [PLANTILLA]: (state, { currentPlantilla }) => {
    return {
      ...state,
      currentPlantilla,
    };
  },
  [DRAFT]: (state, { draft }) => {
    return {
      ...state,
      draft,
    };
  },
};

export const initialState = {
  loader: false,
  data: {},
  currentPlantilla: null,
  draft: null,
};

export default handleActions(reducers, initialState);
