import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { proyectos_operaciones_estados } from "../../../../utility/constants";
import FiltersData from "./FiltersData";
import Swal from 'sweetalert2';

import moment from "moment";

const { EN_PROCESO, EN_REVISION, APROBADO, VENTA_GANADA, VENTA_PERDIDA } =
    proyectos_operaciones_estados;

const estados = [
    { label: "En proceso", value: EN_PROCESO },
    { label: "En revisión", value: EN_REVISION },
    { label: "Aprobado", value: APROBADO },
    { label: "Venta ganada", value: VENTA_GANADA },
    { label: "Venta perdida", value: VENTA_PERDIDA },
];

class Listar extends Component {
    state = {
        page: 1,
        search: "",
        BreakLine: {
            whiteSpace: "normal",
        },
        params: {
            activo: true,
            estado: estados[0].value,
        },
    };

    componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.params !== prevState.params) {
            this.props.listar(this.state.page, this.state.params);
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };


    handleUploadContract = (idProyecto) => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = ".pdf,.doc,.docx"; // Ajusta los tipos de archivo según tus necesidades

        fileInput.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                const formData = new FormData();
                // Usamos "archivo" para que el backend guarde el archivo en el campo "archivos"
                formData.append("archivo", file);

                // Se asume que en this.props tienes la función uploadContract
                // que realiza la llamada a la API correspondiente
                this.props.uploadContract(idProyecto, formData)
                    .then(response => {
                        Swal.fire({
                            title: 'Éxito',
                            text: 'Contrato subido exitosamente',
                            icon: 'success',
                            confirmButtonText: 'OK'
                        });
                        // Manejo de éxito (por ejemplo, mostrar un mensaje o actualizar la lista)
                    })
                    .catch(error => {
                        Swal.fire({
                            title: 'Error',
                            text: 'Error al subir el contrato',
                            icon: 'error',
                            confirmButtonText: 'OK'
                        });
                        // Manejo de error (por ejemplo, mostrar notificación de error)
                    });
            }
        };

        fileInput.click();
    };



    render() {
        const { data, loader, eliminar, draft, clearDraft, enviarAOperaciones } =
            this.props;




        const uploadContract = (idProyecto, file) => dispatch => {
            const formData = new FormData();
            formData.append("contrato", file);

            return api.post(`/proyecto_operaciones/${idProyecto}/upload_contract/`, formData)
                .then(response => {
                    // Manejo de éxito
                })
                .catch(error => {
                    // Manejo de error
                });
        };
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTA DE PRESUPUESTOS</h2>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div
                        className="col-12 d-flex justify-content-end mb-3"
                    >
                        {draft ? (
                            <button
                                onClick={() => {
                                    this.props.history.push(
                                        "/proyectos_operaciones/crear"
                                    );
                                }}
                                className="btn btn-primary mt-4 mr-4"
                                style={{ fontSize: 15, borderRadius: "5px" }}
                            >
                                Continuar...
                            </button>
                        ) : null}
                        <button
                            onClick={() => {
                                clearDraft();
                                this.props.history.push(
                                    "/proyectos_operaciones/crear"
                                );
                            }}
                            className="btn btn-primary mt-4"
                            style={{ fontSize: 15, borderRadius: "5px" }}
                        >
                            Nuevo proyecto
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8 display--fieldset">
                        {estados.map((estado) => (
                            <label className="container--1" key={estado.value}>
                                {estado.label}
                                <input
                                    disabled={loader}
                                    type="radio"
                                    name="proyectos_operaciones"
                                    value={estado.value}
                                    checked={
                                        this.state.params.estado ===
                                        estado.value
                                    }
                                    onChange={() =>
                                        this.setState({
                                            params: {
                                                activo: true,
                                                estado: estado.value,
                                            },
                                        })
                                    }
                                />
                            </label>
                        ))}
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12 col-md-6"
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                        }}
                    >
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control mt-4 input--style"
                            value={this.state.search}
                            onKeyDown={(key) => {
                                if (key.key == "Enter") {
                                    const { page, search, params: { fecha_inicio, fecha_fin } } = this.state;
                                    let filterParams = { search, estado: this.state.params.estado };
                                    if (fecha_inicio) filterParams.fecha_inicio = fecha_inicio;
                                    if (fecha_fin) filterParams.fecha_fin = fecha_fin;
                                    this.props.listar(page, filterParams);
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                width: "55%",
                                float: "right",
                            }}
                        />
                    </div>
                    {/* coso filtro */}
                    <FiltersData
                        onChangeParams={this.onChangeParams}
                        currentParams={this.state.params}
                    />
                    {/* fin coso filtro */}
                </div>
                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn dataField="nombre" dataSort>
                        Nombre
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        dataFormat={(cell, row) => `${cell.nombre}`}
                    >
                        Cliente
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="plantilla"
                        dataSort
                        dataFormat={(cell, row) =>
                            `${cell.complejidad.descripcion}`
                        }
                    >
                        Complejidad
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="plantilla"
                        dataSort
                        dataFormat={(cell, row) => `${cell.nombre}`}
                    >
                        Plantilla
                    </TableHeaderColumn>

                    {/* se agrega una nueva columna, esta aparece en base a la fase seleccionada y muestra la fecha correspondiente */}

                    <TableHeaderColumn
                        dataField="fecha_ingreso"
                        dataSort
                        dataFormat={(cell, row) => {
                            let value = "---";
                            switch (this.state.params.estado) {
                                case EN_PROCESO:
                                    value = row.fecha_ingreso_en_proceso;
                                    break;
                                case EN_REVISION:
                                    value = row.fecha_ingreso_en_revision;
                                    break;
                                case APROBADO:
                                    value = row.fecha_ingreso_aprobado;
                                    break;
                                case VENTA_GANADA:
                                    value = row.fecha_ingreso_venta_ganada;
                                    break;
                                case VENTA_PERDIDA:
                                    value = row.fecha_ingreso_venta_perdida;
                                    break;
                                default:
                                    break;
                            }
                            // Formateo de la fecha si existe
                            if (value) {
                                const date = moment(value, "YYYY-MM-DD HH:mm:ss.SSSSSS");
                                value = date.format("DD/MM/YYYY");
                            }
                            return <span className="m-0 text-center">{value}</span>;
                        }}
                    >
                        Fecha de ingreso
                    </TableHeaderColumn>


                    {/* se agrega una nueva columna, esta solo aparece si la venta esta ganada */}

                    {this.state.params.estado === VENTA_GANADA && (
                        <TableHeaderColumn
                            dataField="enviado_a_operaciones"
                            dataSort
                            dataFormat={(cell, row) => (cell ? "Sí" : "No")}
                        >
                            Enviado a Operaciones
                        </TableHeaderColumn>
                    )}

                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            // Prepara las props para las acciones estándar
                            let props = {};
                            props.editar = "proyectos_operaciones"; // ejemplo
                            props.eliminar = eliminar;

                            // Agrega la acción de enviar a Operaciones si se está en VENTA_GANADA
                            if (this.state.params.estado === VENTA_GANADA) {
                                props.enviarAOperaciones = enviarAOperaciones;
                            }

                            // Obtiene los botones de acciones estándar
                            const standardActionButtons = standardActions({ ...props })(cell, row);

                            return (
                                <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                    {standardActionButtons}
                                    {this.state.params.estado === VENTA_GANADA && (
                                        <button
                                            className="btn btn-success"
                                            onClick={() => this.handleUploadContract(row.id)}
                                            style={{
                                                border: "none",
                                                background: "transparent",
                                                cursor: "pointer",
                                                padding: '2px'
                                            }}
                                        >
                                            <i className="material-icons" style={{ fontSize: 22, color: "#28a745" }}>
                                                file_upload
                                            </i>
                                        </button>
                                    )}
                                </div>
                            );
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>





                </Grid>
            </React.Fragment>
        );
    }

}

export default Listar;
