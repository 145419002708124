import React from "react";

import FirstStep, {
  fieldsToUpdate as fieldsFirstStep,
} from "./FormSteps/FirstStep";
import SecondStep, {
  fieldsToUpdate as fieldsSecondStep,
} from "./FormSteps/SecondStep";
import ThirdStep from "./FormSteps/ThirdStep";
import ProgressBarButton from "./FormSteps/ProgressBarButton";

const CrearEditarForm = (props) => {
  const {
    onSubmit,
    actividades,
    fases,
    puestos,
    clientes,
    tipos_proyectos,
    plantillas,
    gastos,
    currentPlantilla,
    obtenerPlantilla,
    seniority,
    crearActividad,
    updateDraft,
    edit,
    history,
    aprobarProyecto,
    enviarRevision,
    ventaGanadaPerdida,
    updateField,
  } = props;

  const [step, setStep] = React.useState(1);


  const nextStep = async (data) => {
    console.log("data Next Step: ", data)
    if (edit) {
      const result = await onSubmit(data);
      if (result) setStep(step + 1);
    } else {
      updateDraft(data);
      setStep(step + 1);
    }
  };

  const nextStepNoSubmit = () => {
    setStep(step + 1);
  };

  const previusStep = () => {
    setStep(step - 1);
  };

  return (
    <div className="d-flex mx-auto row">
      <div className="col-1 p-0 pt-3">
        <ul className="progressbar">
          <li className={`${step >= 1 && "active"}`}>
            <ProgressBarButton
              className={`btn ${step === 1 && "active"}`}
              setStep={setStep}
              currentStep={step}
              step={1}
              edit={edit}
            >
              Paso 1
            </ProgressBarButton>
          </li>
          <li className={`${step >= 2 && "active"}`}>
            <ProgressBarButton
              className={`btn ${step === 2 && "active"}`}
              setStep={setStep}
              currentStep={step}
              step={2}
              edit={edit}
            >
              Paso 2
            </ProgressBarButton>
          </li>
          <li className={`${step >= 3 && "active"}`}>
            <ProgressBarButton
              className={`btn ${step === 3 && "active"}`}
              setStep={setStep}
              currentStep={step}
              step={3}
              edit={edit}
            >
              Paso 3
            </ProgressBarButton>
          </li>
        </ul>
      </div>
      <div className="col">
        {step === 1 && (
          <FirstStep
            onSubmit={(data) => nextStep(data, fieldsFirstStep)}
            clientes={clientes}
            tipos_proyectos={tipos_proyectos}
            plantillas={plantillas}
            obtenerPlantilla={obtenerPlantilla}
            currentPlantilla={currentPlantilla}
            gastos={gastos}
            actividades={actividades}
            fases={fases}
            crearActividad={crearActividad}
            nextStepNoSubmit={nextStepNoSubmit}
            updateField={updateField}
          />
        )}
        {step === 2 && (
          <SecondStep
            onSubmit={(data) => nextStep(data, fieldsSecondStep)}
            previusStep={previusStep}
            fases={fases}
            puestos={puestos}
            seniority={seniority}
            currentPlantilla={currentPlantilla}
            edit={edit}
            nextStepNoSubmit={nextStepNoSubmit}
          />
        )}
        {step === 3 && (
          <ThirdStep
            previusStep={previusStep}
            onSubmit={
              edit
                ? () => history.push("/proyectos_operaciones")
                : (data) => onSubmit(data, false)
            }
            checkCreate={(data) => onSubmit(data, true)}
            currentPlantilla={currentPlantilla}
            aprobarProyecto={aprobarProyecto}
            enviarRevision={enviarRevision}
            ventaGanadaPerdida={ventaGanadaPerdida}
            edit={edit}
          />
        )}
      </div>
    </div>
  );
};

export default CrearEditarForm;
