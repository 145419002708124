import React, { useState, useEffect } from "react";
import moment from "moment";
import './TablaBitacora.css';
import { connect } from 'react-redux';
import { cargarBitacora, addBitacora, setBitacora } from '../../../../redux/modules/bitacora_detalle_proyecto/bitacora_detalle_proyecto';
import Swal from "sweetalert2";
import { api } from "api";
import Modal from 'react-modal';

//Componente para mostrar la bitacora de un proyecto
const TablaBitacora = (props) => {
    //Estados
    const [nuevaObservacion, setNuevaObservacion] = useState("");
    const [nuevaFechaInicio, setNuevaFechaInicio] = useState("");
    const [usuarios, setUsuarios] = useState({});
    const [showModal, setShowModal] = useState(false);

    //Se utiliza para cambiar los datos si el id del proyecto cambia
    useEffect(() => {
        if (props.idProyecto) {
            props.cargarBitacora(props.idProyecto);
        }
    }, [props.idProyecto]);


    // Obtener la información de los usuarios de los registros de la bitácora
    useEffect(() => {
        const obtenerUsuarios = async () => {
            const usuariosIds = [...new Set(props.bitacora.map(registro => registro.usuario))];
            const usuariosData = {};

            await Promise.all(usuariosIds.map(async id => {
                const response = await api.get(`user/${id}`);
                usuariosData[id] = response;
            }));

            setUsuarios(usuariosData);
        };

        if (props.bitacora.length > 0) {
            obtenerUsuarios();
        }
    }, [props.bitacora]);


    //Agregar bitacoras a redux
    const handleInsert = (event) => {
        event.preventDefault();
        if (!nuevaObservacion || props.id_usuario === "" || !nuevaFechaInicio || props.idProyecto === "") {
            Swal.fire({
                title: "Error",
                text: "Todos los campos deben estar llenos.",
                icon: "error",
            });
            return;
        }

        const nuevoRegistro = {
            observacion: nuevaObservacion,
            usuario: props.id_usuario,
            fecha_inicio: nuevaFechaInicio,
            proyecto: props.idProyecto 
        };

        try {
            // Agregar el nuevo registro al estado de Redux
            props.addBitacora(nuevoRegistro);

            // Limpiar los campos del formulario
            setNuevaObservacion("");
            setNuevaFechaInicio("");
            // Cerrar el modal
            setShowModal(false);
        } catch (error) {
            console.error("Error al agregar el registro de bitácora:", error);
            Swal.fire({
                title: "Error",
                text: "Ha ocurrido un error al agregar el registro de bitácora.",
                icon: "error",
            });
        }
    };


    return (
        <div>
            <button type="button" className="btn btn-primary" style={{ float: 'right', marginBottom: '10px' }} onClick={(e) => { e.preventDefault(); setShowModal(true); }}>Insertar Nueva Bitácora</button>
                <table className="table resources--table">
                    <thead className="thead--blue">
                        <tr>
                            <th scope="col">Fecha</th>
                            <th scope="col">Observaciones</th>
                            <th scope="col">Usuario</th>
                        </tr>
                    </thead>
                    <tbody>
                    {props.bitacora && props.bitacora.map((bitacora, index) => (
                        <tr key={index} className="blue-border">
                            <td>{moment(bitacora.fecha_inicio).format("DD/MM/YYYY")}</td>
                            <td>
                                <textarea
                                id="observacion"
                                className="form-control"
                                value={bitacora.observacion}
                                rows="1" 
                                disabled =  {true}
                                ></textarea>
                            </td>
                            <td>{usuarios[bitacora.usuario] ? usuarios[bitacora.usuario].first_name +" "+ usuarios[bitacora.usuario].last_name  : bitacora.usuario}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                contentLabel="Agregar Nueva Bitácora"
                ariaHideApp={false}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)"
                    },
                    content: {
                        width: "50%", // ajusta el ancho del modal
                        height: "50%", // ajusta la altura del modal
                        margin: "auto", // centra el modal horizontalmente
                        overflow: "auto" // permite hacer scroll dentro del modal si es necesario
                    }
                }}
            >
                <h2>Agregar Nueva Bitácora</h2>
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="form-group">
                        <label htmlFor="observacion">Observación</label>
                        <textarea
                            id="observacion"
                            className="form-control"
                            value={nuevaObservacion}
                            onChange={(e) => setNuevaObservacion(e.target.value)}
                            placeholder="Nueva Observación"
                            rows="10"  // Aumenta el número de filas
                        ></textarea>

                    </div>

                    <div className="form-group">
                        <label htmlFor="fechaInicio">Fecha</label>
                        <input
                            type="date"
                            id="fechaInicio"
                            className="form-control"
                            value={nuevaFechaInicio}
                            onChange={(e) => setNuevaFechaInicio(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="btn btn-primary m-1 align-self-center" onClick={handleInsert}>Guardar</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Cancelar</button>
                </form>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    bitacora: state.bitacora_detalle_proyecto.bitacora,
});

const mapDispatchToProps = (dispatch) => ({
    addBitacora: (bitacora) => {
        dispatch(addBitacora(bitacora));
    },
    setBitacora: (bitacora) => {
        dispatch(setBitacora(bitacora));
    },
    cargarBitacora: (idProyecto) => {
        dispatch(cargarBitacora(idProyecto));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TablaBitacora);