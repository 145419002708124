// redux/modules/bitacora_detalle_proyecto.js

import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import { api } from "api";
import Swal from "sweetalert2";

// Define constants
const SUBMIT = "PROYECTO_SUBMIT";
const LOADER = "PROYECTO_LOADER";
const DATA = "PROYECTO_DATA";
const FORM_DATA = "PROYECTO_FORM_DATA";
const ALL_PROJECTS = "ALL_PROJECTS";
const ENDPOINT = "proyecto";
const ADD_BITACORA = "ADD_BITACORA";
const SET_BITACORA = "SET_BITACORA";

export const FILTER_LIST = `${ENDPOINT}/filter_list`;

export const constants = {
    SUBMIT,
};

// Pure Actions
export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setAllProjects = (all_projects) => ({
    type: ALL_PROJECTS,
    all_projects
});

export const setFormData = (formData) => ({
    type: FORM_DATA,
    formData,
});

// Bitacora Actions
export const addBitacora = (bitacora) => ({
    type: ADD_BITACORA,
    payload: bitacora,
});

export const setBitacora = (bitacora) => ({
    type: SET_BITACORA,
    payload: bitacora,
});

// Obtener proyecto de la api
export const obtenerProyecto = (id = null) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${ENDPOINT}/${id}`)
        .then((response) => {
            response.encargado = response.encargado ? response.encargado.id : null;
            response.pais_asueto = response.pais_asueto ? response.pais_asueto.id : null;
            if (localStorage.getItem('role') == 10) {
                if (response.encargado) {
                    if (response.encargado != localStorage.getItem('uid')) {
                        dispatch(push("/proyectos"));
                    }
                }
            }
            response.tipo_proyecto = response.tipo_proyecto.id;
            response.cliente = response.cliente ? response.cliente.id : null;
            dispatch(initializeForm("CrearEditarForm", response));
            dispatch(setFormData(response));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const cargarBitacora = (idProyecto) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const response = await api.get(`bitacora_detalle_proyecto/filter_list`);

        // Filtrar los registros para obtener solo los del proyecto específico
        const bitacoraProyecto = idProyecto ? response.filter((registro) => registro.proyecto === idProyecto).map((registro) => ({
            ...registro,
            fuente: 'db' // Indicar que estos registros vienen de la base de datos
        })) : [];

        dispatch(setBitacora(bitacoraProyecto));
    } catch (error) {
        console.error("Error al cargar los registros de la bitácora:", error);
    } finally {
        dispatch(setLoader(false));
    }
};

//Carga de bitacora obteniendo nombres de usuarios
export const cargarBitacora2 = (idProyecto) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const response = await api.get(`bitacora_detalle_proyecto/filter_list`);
        const bitacoraProyecto = idProyecto ? response.filter((registro) => registro.proyecto === idProyecto) : [];

        // Obtener los detalles del usuario
         const bitacoraConUsuario = await Promise.all(bitacoraProyecto.map(async (registro) => {
            const usuarioResponse = await api.get(`user/${registro.usuario}`);
            const usuario = usuarioResponse.first_name + " " + usuarioResponse.last_name;
            return {
                ...registro,
                usuario,
                fuente: 'db' // Indicar que estos registros vienen de la base de datos
            };
        }));

        dispatch(setBitacora(bitacoraConUsuario));
    } catch (error) {
        console.error("Error al cargar los registros de la bitácora:", error);
    } finally {
        dispatch(setLoader(false));
    }
};


export const actualizarProyecto = () => async (dispatch, getState) => {
    dispatch(setLoader(true));
    try {
        const { bitacora } = getState().bitacora_detalle_proyecto;

        console.log("Los datos de bitacora son:  ")
        console.log(bitacora)

        // Obtener los registros de la bitácora que ya existen en la base de datos
        const bitacoraEnDB = await api.get('bitacora_detalle_proyecto/filter_list');

        console.log("Los datos de la api son:  ")
        console.log(bitacoraEnDB)

        // Filtrar los registros de la bitácora para obtener solo los nuevos
        const nuevosRegistros = bitacora.filter(registro => {
    // Filtrar solo los registros que no vienen de la base de datos
    return registro.fuente !== 'db';
});

        // Insertar solo los nuevos registros de la bitácora en la base de datos
        await Promise.all(nuevosRegistros.map(registro =>
            api.post('bitacora_detalle_proyecto/', registro)
        ));

        Swal.fire({
            title: 'Éxito',
            text: 'Se han guardado los datos correctamente',
            icon: 'success',
        });

        dispatch(push('/proyectos/reporte_detallado'));
    } catch (error) {
        const textError = error && error.non_field_errors
            ? error.non_field_errors[0]
            : 'Ha ocurrido un error, inténtelo nuevamente.';

        Swal.fire({
            title: 'Error',
            text: textError,
            icon: 'error',
        });
    } finally {
        dispatch(setLoader(false));
    }
};

//exportacion de actions
export const actions = {
    obtenerProyecto,
    addBitacora,
    setBitacora,
    actualizarProyecto,
    cargarBitacora,
    cargarBitacora2,
};

// Reducers
export const reducers = {
    [LOADER]: (state, { loader }) => ({
        ...state,
        loader,
    }),
    [DATA]: (state, { data }) => ({
        ...state,
        data,
    }),
    [FORM_DATA]: (state, { formData }) => ({
        ...state,
        formData,
    }),
    [ALL_PROJECTS]: (state, { all_projects }) => ({
        ...state,
        all_projects,
    }),
    [ADD_BITACORA]: (state, { payload }) => {
        const updatedBitacora = [...state.bitacora, payload];
        return {
            ...state,
            bitacora: updatedBitacora,
        };
    },

    [SET_BITACORA]: (state, { payload }) => {
        return {
            ...state,
            bitacora: payload,
        };
    },
};

// Estados iniciales
export const initialState = {
    loader: false,
    formData: {},
    data: {},
    all_projects: [],
    bitacora: [],
};

export default handleActions(reducers, initialState);
