import React, { useReducer, useState } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { Chart, registerables } from "chart.js";
import { api } from "api";
import moment from "moment";
import { ColaboradoresCard } from "./ColaboradoresCard";

Chart.register(...registerables);

export const Visualizar = ({ goToColaborador }) => {
    const types = [
        {
            id: 1,
            nombre: "Vacaciones completas",
        },
        {
            id: 2,
            nombre: "Vacaciones restantes",
        },
        {
            id: 3,
            nombre: "Sin vacaciones",
        },
    ];

    const onRowClick = (row) => {
        goToColaborador(row.id);
    };

    const [state, dispatch] = useReducer(reducer, initialState);


    React.useEffect(() => {
        getReports();
    }, []);

    const getReports = async () => {
        try {
            onLoad();
            const reports = await api.get("permiso/reportes_vacaciones");
            onGetReports(reports);
            const now = moment();
        } catch (e) {
            console.error(e);
        }
    };

    const { reports = {}, loading, type } = state;
    const { completados = [], restantes = [], sin_permisos = [] } = reports;

    const onLoad = () => dispatch({ type: actionTypes.loading });
    const onGetReports = (reports = {}) => {
        dispatch({ type: actionTypes.setReports, payload: reports });
        console.log("Estado actualizado:", reports);
    };

    const handleSelectChange = (e) => {
        dispatch({
            type: actionTypes.changeType,
            payload: parseInt(e.target.value),
        });
    };


    return (
        <LoadMask loading={loading} blur={true}>
            <div className="flex row mt-2">
                <div className="col-12">
                    <h2 className="title--blue">Reportes de vacaciones</h2>
                </div>
            </div>
            <hr />
            <div className="card--reportes mx-auto display--fieldset justify-content-around">
                {types.map((t) => (
                    <label className="container--1 w-100" key={t.id}>
                        {t.nombre}
                        <input
                            type="radio"
                            value={t.id}
                            name="reportes_vacaciones"
                            className="input--style"
                            onChange={handleSelectChange}
                            checked={type === t.id}
                        />
                    </label>
                ))}
            </div>
            <ColaboradoresCard
                title={"Colaboradores con vacaciones completas"}
                colaboradores={completados}
                show={type === 1}
                onRowClick={onRowClick}
            >
                <TableHeaderColumn
                    dataField="dias_restantes"
                    dataSort
                    thStyle={{ textAlign: "center" }}
                    tdStyle={{ textAlign: "center" }}
                >
                    Días restantes
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="ultima_vacacion"
                    dataSort
                    thStyle={{ textAlign: "center" }}
                    tdStyle={{ textAlign: "center" }}
                    dataFormat={(cell) => {
                        if (!cell) return "---";
                        return moment(cell).format("l");
                    }}
                >
                    Últimas vacaciones
                </TableHeaderColumn>
            </ColaboradoresCard>
            <ColaboradoresCard
                title={"Colaboradores con vacaciones restantes"}
                colaboradores={restantes}
                show={type === 2}
                onRowClick={onRowClick}
            >
                <TableHeaderColumn
                    dataField="dias_restantes"
                    dataSort
                    thStyle={{ textAlign: "center" }}
                    tdStyle={{ textAlign: "center" }}
                >
                    Días restantes
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="dias_usados"
                    dataSort
                    thStyle={{ textAlign: "center" }}
                    tdStyle={{ textAlign: "center" }}
                >
                    Días usados
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="ultima_vacacion"
                    dataSort
                    thStyle={{ textAlign: "center" }}
                    tdStyle={{ textAlign: "center" }}
                    dataFormat={(cell) => {
                        if (!cell) return "---";
                        return moment(cell).format("l");
                    }}
                >
                    Últimas vacaciones
                </TableHeaderColumn>
            </ColaboradoresCard>
            <ColaboradoresCard
                title={"Colaboradores sin derecho a vacaciones"}
                colaboradores={sin_permisos}
                show={type === 3}
            >
                <TableHeaderColumn
                    dataField="fecha_inicio"
                    dataSort
                    thStyle={{ textAlign: "center" }}
                    tdStyle={{ textAlign: "center" }}
                    dataFormat={(cell) => {
                        if (!cell) return "---";
                        return moment(cell).format("l");
                    }}
                >
                    Fecha de ingreso
                </TableHeaderColumn>
            </ColaboradoresCard>

        </LoadMask>
    );
};

const initialState = {
    loading: false,
    reports: {},
    type: 2,
};

const actionTypes = {
    loading: "LOADING",
    setReports: "SET_REPORTS",
    changeType: "CHANGE_TYPE",
};

const reducerObject = (state, payload) => ({
    [actionTypes.setReports]: {
        ...state,
        reports: payload,
        loading: false,
    },
    [actionTypes.loading]: {
        ...state,
        loading: true,
    },
    [actionTypes.changeType]: {
        ...state,
        type: payload,
    },
});

const reducer = (state, action) => {
    return reducerObject(state, action.payload)[action.type] || state;
};
