import moment from "moment";
import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import Select from "react-select";
import '../../Recursos/Listar/Listado.css';
import { api } from "api";
import BSTable from  './BSTable';

class Listar extends Component {
    state = {
        page: 1,
        tags: "",
        search: "",
        BreakLineCenter: {
            whiteSpace: "normal",
            textAlign: "center",
        },
        BreakLine: {
            whiteSpace: "normal",
        },
        AlignCenter: {
            textAlign: "center",
        },
        params: {
            activo: true,
            finalizado: false,
            viene_de_presupuestos: false,
            encargado__id: null,
        },
        recursos_encargadosFilt: [],
        expandedRows: {}, // Estado para manejar las filas expandidas
    };

    async componentDidMount() {
        this.props.getClientes();
        this.props.getTipos();
        this.props.getScrums({activo: true, despedido: false, renuncia: false});
        this.props.getRole();
        this.props.listar(this.state.page, this.state.params);
        try {
            const recursos_encargadosFilt = await api.get("recurso/encargados_proyectos");
            this.setState({ recursos_encargadosFilt })
        } catch (error) {
            console.error("Error fetching recursos_encargados:", error);
        }
    }

    onPageChange = (page) => {
        const params = { ...this.state.params, search: this.state.search };
        if (this.state.search === "") delete params.search;
        this.props.listar(page, params);
        this.setState({ page });
    };

    expandComponent = (row) => {
        const { expandedRows } = this.state;
        if (expandedRows[row.id]) {
            return <BSTable data={row} />;
        } else {
            return false;
        }
    };

    isExpandableRow = () => {
        return true;
    };

    onRowClick = (row) => {
        const { expandedRows } = this.state;
        const newExpandedRows = { ...expandedRows };
        if (newExpandedRows[row.id]) {
            delete newExpandedRows[row.id];
        } else {
            newExpandedRows[row.id] = true;
        }
        this.setState({ expandedRows: newExpandedRows });
    };

    render() {
        const { data, loader, tipos_proyecto, bitacora } = this.props;
        const clientes = this.props.clientes.all_clients;
        const role = this.props.user.role;
        const { recursos_encargadosFilt, showDetails } = this.state;

        const opcionesRecursosEncargados = recursos_encargadosFilt.map(recurso => ({
            label: recurso.nombre,
            value: recurso.id, 
        }));

        const proyectosFiltrados = tipos_proyecto.filter(item => 
            item.label === "Project Based" || item.label === "Dedicated"
        );

        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };


        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTADO DE PROYECTOS ACTIVOS</h2>
                    </div>
                </div>
                <hr />

                <div className="row mt-4">
                    <div className="col-12 col-md-3" style={{ display: "flex" }}>
                        <input
                            placeholder="Buscar por nombre..."
                            type="text"
                            className="form-control input--style"
                            value={this.state.search}
                            onKeyDown={(key) => {
                                if (key.key === "Enter") {
                                    const { search, params } = this.state;
                                    this.props.listar(1, { ...params, search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                float: "right",
                            }}
                        />
                    </div>

                    <div className="col-12 col-md-3">
                        <Select
                            styles={select_style}
                            name="tipos"
                            placeholder="Tipo"
                            isSearchable={true}
                            isClearable={true}
                            options={proyectosFiltrados}
                            onChange={(e) => {
                                const { search, params } = this.state;
                                if(e) {
                                    params.tipo_proyecto = e.value;
                                } else {
                                    delete params.tipo_proyecto;
                                }
                                this.setState({params});
                                this.props.listar(1, { ...params, search });
                            }}
                        />
                    </div>

                    <div className="col-12 col-md-3">
                        <Select
                            styles={select_style}
                            name="encargados"
                            placeholder="Encargado"
                            isSearchable={true}
                            isClearable={true}
                            options={opcionesRecursosEncargados}
                            onChange={(e) => {
                                const { search, params } = this.state;
                                if (e) {
                                    params.encargado__id = e.value;
                                } else {
                                    delete params.encargado__id;
                                }
                                this.setState({ params });
                                this.props.listar(1, { ...params, search });
                            }}
                        />
                    </div>

                    {role === 1 || role === 11 ? (
                        <React.Fragment>
                            <div className="col-12 col-md-3">
                                <Select
                                    styles={select_style}
                                    name="clientes"
                                    placeholder="Cliente"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={clientes}
                                    onChange={(e) => {
                                        const { search, params } = this.state;
                                        if(e) {
                                            params.cliente__id = e.value;
                                        } else {
                                            delete params.cliente__id;
                                        }
                                        this.setState({params});
                                        this.props.listar(1, { ...params, search });
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>

                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                    onRowClick={this.onRowClick}
                    expandableRow={ this.isExpandableRow }
                    expandComponent={ this.expandComponent }
                >
                    <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            tdStyle={this.state.BreakLine}
                            dataFormat={(cell, row) => {
                                return cell ? <span>{cell}</span> : "---";
                            }}
                            // className="thead--blue--individual"
                        >
                            Proyecto
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="tipo_proyecto"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell ? cell.nombre : "---";
                        }}
                        tdStyle={this.state.BreakLine}
                    >
                        Tipo de Proyecto
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="encargado"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell ? cell.nombre : "---";
                        }}
                        tdStyle={this.state.BreakLine}
                    >
                        Encargado
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell ? (
                                <div>
                                    {cell.nombre}
                                </div>
                            ) : (
                                "---"
                            );
                        }}
                        tdStyle={this.state.BreakLine}
                    >
                        Cliente
                    </TableHeaderColumn>

                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};

                            if (row.activo === true && row.finalizado === false && row.estado_pausa === false) {
                                if (role === 1 || role === 11) 
                                    props.reporteDetallado = true;
                            }

                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>

            </React.Fragment>
        );
    }
}

export default Listar;
