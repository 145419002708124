import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/bitacora_detalle_proyecto/bitacora_detalle_proyecto';
import { actions as user } from '../../../../redux/modules/cuenta/login';
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
    ...state.proyectos,
    user: { ...state.login },
    bitacora: state.bitacora_detalle_proyecto.bitacora
  };
};

const md2p = { 
    ...actions, 
    getRole: user.getRole, 
    cargarBitacora: actions.cargarBitacora 
};

export default connect(ms2p, md2p)(CrearEditar);