import React, { useState, Fragment } from "react";
import moment from "moment";

const TableData = ({ recursos, semanas, fechas, tableReporte }) => {
    const [openModal, setOpenModal] = useState(false);
    const [dataRecurso, setDataRecurso] = useState({});
    const [index, setIndex] = useState(0);

    let matriz_disponibilidad = []
    for (let i = 0; i < Object.values(recursos[0])[0].length; i++) {
        let array_colum = []
        recursos.map((colab) => {
            array_colum.push(Object.values(colab)[0][i])
        })
        matriz_disponibilidad.push(array_colum)
    }
    let totales = []
    for (let i = 0; i < matriz_disponibilidad.length; i++) {
        let total = matriz_disponibilidad[i].reduce((a, b) => a + b, 0).toFixed(2)
        totales.push(total)
    }

    return (
        <Fragment>
        
            <table
                className="table table-bordered text--blue--1"
                ref={tableReporte}
            >
                <thead>
                    <tr>
                        <th className="text-center position_table--thtd">#</th>
                        <th className="position_table--thtd--2">Colab.</th>
                        {/* <th className="position_table--thtd--3">Skills</th> */}
                        <th className="position_table--thtd--4 ">
                            Proyecto Asignado
                        </th>
                        {semanas.map((semana, index) => (
                            <th key={index}>
                                {moment(semana).format("DD/MM/YYYY")}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {recursos.map((colab, index_recurso) => (
                        <tr key={index_recurso} className="text--grey">
                            <td className="position_table--thtd text-center">
                                {index_recurso + 1}
                            </td>
                            <td className="position_table--thtd--2">
                                {Object.keys(colab)[0]}
                            </td>
                            <td className="position_table--thtd--4  "  style={{
                                backgroundColor: colab.color,
                                color: colab.color === "#FFF" ? "#000" : "#FFF",
                                
                            }}>
                                {colab.proyecto_default}
                            </td>
                            {Object.values(colab)[0].map(
                                (porcentaje, index) => (
                                    <td
                                        key={index}
                                        className="text-center"
                                        onClick={() => {
                                            setOpenModal(true);
                                            setDataRecurso(colab);
                                            setIndex(index_recurso);
                                        }}
                                    >
                                            {Math.round(Number(porcentaje) * 100)}%
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                    <tr className="text--grey">
                        <td className="position_table--thtd text-center text--blueBold bg--greySoft">
                            #
                        </td>
                        <td className="position_table--thtd--2 text--blueBold bg--greySoft">
                            Total
                        </td>
                        <td className="position_table--thtd--4 text--blueBold bg--greySoft">
                            ---
                        </td>

                        {totales.map((item) => (
                            <td className="text-center text--blueBold bg--greySoft">
                                {Math.round(Number(item) * 100)}%
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
};

export default TableData;
