import React from "react";

import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { RenderFields } from "./RenderFields";
import validateFields from "./validate";
import { renderSelectField , renderFieldRadio} from "../../Utils/renderField";
import { renderDocumentos } from "./renderCertificados";

function DatosPersonalesForm({ 
    prevStep, 
    handleSubmit, 
    select_style, 
    edit, 
    nivelesIngles, 
}) {
    const fields = [
        [
            {
                styleContainer: { zIndex: 2 },
                label: "Nivel de inglés",
                props: {
                    name: "nivel_ingles",
                    label: "Nivel de inglés",
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    options: nivelesIngles,
                    className: "input--style",
                    placeholder: "Nivel de inglés...",
                },
            },
        ],
        [
            {
                label: "Certificados",
                array: true,
                className: "col-12",
                props: {
                    name: "certificados_ingles",
                    component: renderDocumentos,
                    edit: edit,
                    label: "Certificados",
                    rerenderOnEveryChange: true,
                },
            },
        ],
    ];
    


    return (
        <React.Fragment>
            <form
                name="RecursosCrearEditarForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <RenderFields fields={fields} />
                <div className="buttons-box">
                    <button
                        onClick={prevStep}
                        type="button"
                        className="btn btn-secondary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </button>
                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Siguiente
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

// export { DatosPersonalesForm };

const form = reduxForm({
    form: "RecursosCrearEditarForm", // a unique identifier for this  form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateFields,
})(DatosPersonalesForm);

const selector = formValueSelector("RecursosCrearEditarForm");
export default connect((state) => {
    const fotoDpiUrl = selector(state, "foto_dpi_url") || "";
    const fotoPerfilUrl = selector(state, "foto_perfil_url") || "";
    return {
        fotoDpiUrl,
        fotoPerfilUrl, 
    };
})(form);
