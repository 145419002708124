import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/tipos_proyecto/tipos_proyecto';
import CrearEditar from './CrearEditar';

const ms2p = (state) => {
  return {
    ...state.tipos_proyecto,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CrearEditar);  