import moment from "moment";
import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import Select from "react-select";
import '../../Recursos/Listar/Listado.css';

class Listar extends Component {
    state = {
        page: 1,
        tags: "",
        search: "",
        BreakLineCenter: {
            whiteSpace: "normal",
            textAlign: "center",
        },
        BreakLine: {
            whiteSpace: "normal",
        },
        AlignCenter: {
            textAlign: "center",
        },
        params: {
            activo: true,
            finalizado: false,
            viene_de_presupuestos: false,
            encargado__id: null,
        },
    };

    componentDidMount() {
        this.props.getClientes();
        this.props.getTipos();
        this.props.getScrums({ activo: true, despedido: false, renuncia: false });
        this.props.getRole();
        this.props.listar(this.state.page, this.state.params);
    }

    onPageChange = (page) => {
        const params = { ...this.state.params, search: this.state.search };
        if (this.state.search == "") delete params.search;
        this.props.listar(page, params);
        this.setState({ page });
    };

    radioChange = (params) => {
        params = { encargado__id: this.state.params.encargado__id, ...params }
        const params1 = { ...params, search: this.state.search };
        if (this.state.search == "") delete params1.search;
        this.setState({ params });
        this.setState({ page: 1 });
        this.props.listar(1, params1);
    };

    render() {
        const { data, loader, eliminar, tipos_proyecto, user } = this.props;
        const clientes = this.props.clientes.all_clients;
        const recursos = this.props.recursos.all;
        const _options = [];
        if (recursos) {
            const recursos_filter = recursos.filter((recurso) => recurso.puesto.nombre.toUpperCase().includes("SCRUM"));
            recursos_filter.forEach(option => {
                _options.push({ ...option, label: option['nombre'], value: option['id'] });
            });
        }
        const role = this.props.user.role;
        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };
        const isAdmin = user.me.is_superuser;
        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTADO DE PROYECTOS</h2>
                    </div>
                </div>
                <hr />
                {role == 1 || role == 11 ? (
                    <div className="row">
                        <div className="col-12 col-md-6" />
                        <div className="col-12 col-md-6" style={{ display: "flex", justifyContent: "end" }}>
                            <Link
                                to="/proyectos/crear"
                                className="btn btn-primary"
                            >
                                Crear Proyecto
                            </Link>
                        </div>
                    </div>
                ) : null}
                <div className="row mt-2">
                    <div className="col-12">
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <span>Cantidad de proyectos: {data.count}</span>
                        </div>
                    </div>
                </div>
                <div className="flex row mt-8">
                    <div className="col-12 col-md-8">
                        <fieldset id="proyectos" className="display--fieldset">


                            {/* Tabs para mostrar proyectos asignadas y no asignados(provenientes de presupuesto de proyectos)  */}
                            {/* Tabs de proyectos asignados y sin asignar visibles solo para admins  */}
                            {role == 1 && (
                                <label className="container--1">Proyectos sin asignar
                                    <input
                                        type="radio"
                                        value="false"
                                        name="proyectos"
                                        checked={
                                            this.state.params.encargado_proyecto === false &&
                                            this.state.params.activo === false &&
                                            this.state.params.viene_de_presupuestos === true
                                        }
                                        onChange={() =>
                                            this.radioChange({

                                                encargado_proyecto: false,
                                                activo: false,
                                                viene_de_presupuestos: true,

                                            })
                                        }
                                    />
                                </label>
                            )}


                            {role == 1 && (
                                <label className="container--1">Proyectos asignados
                                    <input
                                        type="radio"
                                        value="false"
                                        name="proyectos"
                                        checked={
                                            this.state.params.encargado_proyecto === true &&
                                            this.state.params.activo === false &&
                                            this.state.params.viene_de_presupuestos === true

                                        }
                                        onChange={() =>
                                            this.radioChange({
                                                encargado_proyecto: true,
                                                activo: false,
                                                viene_de_presupuestos: true,
                                            })
                                        }
                                    />
                                </label>
                            )}

                            <label className="container--1">Activos
                                <input
                                    type="radio"
                                    value="true"
                                    // name="proyectos"
                                    className="input--style"
                                    checked={
                                        this.state.params.activo &&
                                        !this.state.params.finalizado

                                    }
                                    onChange={() =>
                                        this.radioChange({
                                            activo: true,
                                            finalizado: false,

                                        })
                                    } />
                            </label>


                            <label className="container--1">Finalizados

                                <input
                                    type="radio"
                                    value="false"
                                    name="proyectos"
                                    checked={this.state.params.finalizado == true}
                                    onChange={() =>
                                        this.radioChange({
                                            finalizado: true,

                                        })
                                    }
                                />
                            </label>

                            <label className="container--1">Eliminados
                                <input
                                    type="radio"
                                    value="false"
                                    name="proyectos"
                                    checked={
                                        this.state.params.viene_de_presupuestos === false &&
                                        this.state.params.activo == false &&
                                        this.state.params.estado_pausa === false
                                    }
                                    onChange={() =>
                                        this.radioChange({
                                            activo: false,
                                            viene_de_presupuestos: false,
                                            estado_pausa: false
                                        })
                                    }
                                />
                            </label>

                            {/* Tab para mostrar los proyectos en pausa  */}

                            <label className="container--1">Proyectos en pausa
                                <input
                                    type="radio"
                                    value="true"
                                    name="proyectos"
                                    checked={
                                        this.state.params.estado_pausa === true
                                    }
                                    onChange={() =>
                                        this.radioChange({
                                            estado_pausa: true,
                                        })
                                    }
                                />
                            </label>

                        </fieldset>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12 col-md-3" style={{ display: "flex" }}>
                        <input
                            placeholder="Buscar por nombre..."
                            type="text"
                            className="form-control input--style"
                            value={this.state.search}
                            onKeyDown={(key) => {
                                if (key.key == "Enter") {
                                    const { search, params } = this.state;
                                    this.props.listar(1, { ...params, search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                float: "right",
                            }}
                        />
                    </div>
                    {role == 1 || role == 11 ?
                        <React.Fragment>
                            <div className="col-12 col-md-3">
                                <Select
                                    styles={select_style}
                                    name="scrum"
                                    placeholder="Scrum Master"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={_options}
                                    onChange={(e) => {
                                        const { search, params } = this.state;
                                        if (e) {
                                            params.encargado__id = e.value;
                                        } else {
                                            delete params.encargado__id;
                                        }
                                        this.setState({ params });
                                        this.props.listar(1, { ...params, search });
                                    }}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <Select
                                    styles={select_style}
                                    name="clientes"
                                    placeholder="Cliente"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={clientes}
                                    onChange={(e) => {
                                        const { search, params } = this.state;
                                        if (e) {
                                            params.cliente__id = e.value;
                                        } else {
                                            delete params.cliente__id;
                                        }
                                        this.setState({ params });
                                        this.props.listar(1, { ...params, search });
                                    }}
                                />
                            </div>
                        </React.Fragment> : null}
                    <div className="col-12 col-md-3">
                        <Select
                            styles={select_style}
                            name="tipos"
                            placeholder="Tipo"
                            isSearchable={true}
                            isClearable={true}
                            options={tipos_proyecto}
                            onChange={(e) => {
                                const { search, params } = this.state;
                                if (e) {
                                    params.tipo_proyecto = e.value;
                                } else {
                                    delete params.tipo_proyecto;
                                }
                                this.setState({ params });
                                this.props.listar(1, { ...params, search });
                            }}
                        />
                    </div>
                </div>
                <Grid
                    // trClassName="td--individual border--lr border--last"
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                        tdStyle={this.state.BreakLine}
                        dataFormat={(cell, row) => {
                            return cell ? <span>{cell}</span> : "---";
                        }}
                    // className="thead--blue--individual"
                    >
                        Proyecto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="tipo_proyecto"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell ? cell.nombre : "---";
                        }}
                        tdStyle={this.state.BreakLine}
                    // className="thead--blue--individual"
                    >
                        Tipo de Proyecto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_fin"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell
                                ? moment(cell).format("DD/MM/YYYY")
                                : "";
                        }}
                    // className="thead--blue--individual"
                    >
                        Fecha de final
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="encargado"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell ? cell.nombre : "---";
                        }}
                        tdStyle={this.state.BreakLine}
                    // className="thead--blue--individual"
                    >
                        Encargado
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="cliente"
                        dataSort
                        dataFormat={(cell, row) => {
                            return cell ? (
                                <div>
                                    {cell.nombre}
                                </div>
                            ) : (
                                "---"
                            );
                        }}
                        tdStyle={this.state.BreakLine}
                    // className="thead--blue--individual"
                    >
                        Cliente
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};

                            if (row.activo === false && row.finalizado === false && row.estado_pausa === true) {
                                if (role === 1 || role === 10 || role === 11) props.recursos = true;
                            }

                            if (row.activo === true && row.finalizado === false) {
                                if (role === 1 || role === 11) props.general = true;
                                if (role === 1 || role === 10 || role === 11) props.recursos = true;
                                if (role === 1) props.eliminar = eliminar;
                            }

                            if (row.viene_de_presupuestos === true) {
                                if (role === 1 || role === 11) props.general = true;
                            }

                            const actions = standardActions({ ...props });
                            const standardActionButtons = actions(cell, row);

                            return (
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    {standardActionButtons}
                                    {row.contrato && isAdmin && (
                                        <a
                                            href={row.contrato}
                                            download
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ cursor: "pointer", textDecoration: "none" }}
                                            title="Descargar contrato"
                                        >
                                            <i
                                                className="material-icons"
                                                style={{ fontSize: 22, color: "#007bff" }}
                                            >
                                                cloud_download
                                            </i>
                                        </a>
                                    )}

                                </div>
                            );
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>

                </Grid>
            </React.Fragment>
        );
    }
}

export default Listar;