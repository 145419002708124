import React from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import validate from "./validate";
import Swal from "sweetalert2";
import { calulateSummary } from "./calculations"; // asegúrate de importar la función actualizada
import { proyectos_operaciones_estados } from "../../../../../utility/constants";
import { api } from "api"; 

const ThirdStep = (props) => {
  const {
    handleSubmit,
    previusStep,
    currentPlantilla,
    personalValue = [],
    modulosValue = [],
    actividadesValue = [],
    gastosValue = [],
    impuestosAdicionales,
    margenNegocio,
    gananciaAdicional,
    comisionVenta,
    estadoValue,
    edit,
    onSubmit,
    checkCreate,
    aprobarProyecto,
    enviarRevision,
    ventaGanadaPerdida,
  } = props;

  const role = Number(localStorage.getItem("role"));

  // 1. Creamos un estado local para los puestos
  const [puestos, setPuestos] = React.useState([]);
  // 1.1 Creamos un estado local para los seniority
  const [seniority, setSeniority] = React.useState([]);

  // 2. useEffect para cargar los puestos al montar el componente
  React.useEffect(() => {
    const loadPuestos = async () => {
      try {
        const response = await api.get("puesto");
        if (response) {
          setPuestos(response.results);
        }
      } catch (error) {
        console.error("Error cargando puestos:", error);
      }
    };

    const loadSeniority = async () => {
      try {
        const response = await api.get("seniority");
        if (response) {
          setSeniority(response.results);
        }
      } catch (error) {
        console.error("Error cargando seniority:", error);
      }
    };

    loadPuestos();
    loadSeniority();
  }, []);

  // 3. Construimos un mapa (diccionario) id -> nombre, para los puestos
  const puestoLabels = React.useMemo(() => {
    const labels = {};
    puestos.forEach((p) => {
      labels[p.id] = p.nombre;
    });
    return labels;
  }, [puestos]);

  // 3.1 Construimos un mapa (diccionario) id -> nombre, para los seniority
  const seniorityLabels = React.useMemo(() => {
    const labels = {};
    seniority.forEach((s) => {
      labels[s.id] = s.nombre;
    });
    return labels;
  }, [seniority]);

  // Se calcula el resumen, que ahora incluye "Horas garantía"
  const summaries = React.useMemo(() =>
    calulateSummary({
      currentPlantilla,
      personalValue,
      modulosValue,
      gastosValue,
      actividadesValue,
      impuestosAdicionales,
      margenNegocio,
      gananciaAdicional,
      comisionVenta,
    }), [currentPlantilla, personalValue, modulosValue, actividadesValue]
  );

  const askAlert =
    ({ message, confirmText, callback }) =>
    () => {
      Swal.fire({
        title: message,
        showCancelButton: true,
        confirmButtonText: confirmText,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          callback();
        }
      });
    };

  return (
    <form name="ProyectoOperacionesForm" className="form-validate mb-lg">
      <div className="d-flex align-items-center justify-content-end">
        <button
          type="button"
          style={{ fontSize: 15 }}
          className="btn"
          onClick={previusStep}
        >
          Regresar
        </button>
        <button
          type="button"
          style={{ fontSize: 15 }}
          className="btn btn-primary m-1 align-self-center"
          onClick={handleSubmit(onSubmit)}
        >
          {edit ? "Finalizar" : "Guardar"}
        </button>
      </div>


      {/* ========================
          SECCIÓN: Resumen (Horas/Finanzas/Semanas)
      ========================= */}
      <div
        className="card-form pb-5 mb-2 mx-auto"
        style={{
          maxWidth: "1000px",
        }}
      >
        {summaries.map((summary) => (
          <div className="project-summary" key={summary.title}>
            <h5>{summary.title}</h5>
            <hr />
            {Object.values(summary.values).map((row) => (
              <div
                className={`project-summary--row ${
                  row.highlight ? "highlight" : ""
                } ${row.className || ""}`}
                key={row.label}
              >
                <h6>{row.label}: </h6>
                <p>
                  {`${summary.formatter.format(row.value)}${summary.suffix}`}
                </p>
              </div>
            ))}
          </div>
        ))}

        <div className="project-summary">
          <h5>Personal</h5>
          <hr />
          <table className="table table-bordered">
            <thead>
              <tr style={{ height: '30px' }}>
                <th style={{ color: '#5a6169' }}>Puesto</th>
                <th style={{ color: '#5a6169' }}>Seniority</th>
                <th style={{ color: '#5a6169' }}>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {personalValue.map((item, i) => {
                // item.puesto es el ID del puesto
                // Consultamos "puestoLabels" para mostrar su nombre real.
                const puestoNombre = puestoLabels[item.puesto] || `Puesto #${item.puesto}`;
                // item.seniority es el ID de la seniority
                // Consultamos "seniorityLabels" para mostrar su nombre real.
                const seniorityNombre = seniorityLabels[item.seniority] || `Seniority #${item.seniority}`;
                return (
                  <tr key={item.id || i} style={{ height: '30px' }}>
                    <td style={{ color: '#5a6169' }}>{puestoNombre}</td>
                    <td style={{ color: '#5a6169' }}>{seniorityNombre}</td>
                    <td style={{ color: '#5a6169' }}>{item.cantidad}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Botonera final para Acciones */}
      <div className="d-flex align-items-center justify-content-center mb-5">
        {/* Revisión sin crear */}
        {!edit ? (
          <button
            type="button"
            style={{ fontSize: 15 }}
            className="btn btn-primary m-1 align-self-center"
            onClick={askAlert({
              message: "¿Desea enviar este proyecto a revisión?",
              confirmText: "Enviar",
              callback: handleSubmit(checkCreate),
            })}
          >
            Guardar y enviar a revisión
          </button>
        ) : null}
        {/* Revisión editando */}
        {edit &&
          estadoValue &&
          estadoValue == proyectos_operaciones_estados.EN_PROCESO && (
            <button
              type="button"
              style={{ fontSize: 15 }}
              className="btn btn-primary m-1 align-self-center"
              onClick={askAlert({
                message: "¿Desea enviar este proyecto a revisión?",
                confirmText: "Enviar",
                callback: handleSubmit(enviarRevision),
              })}
            >
              Enviar a revisión
            </button>
          )}
        {/* Aprobar */}
        {edit &&
          estadoValue &&
          estadoValue == proyectos_operaciones_estados.EN_REVISION &&
          (role === 1 || role === 5) && (
            <button
              type="button"
              style={{ fontSize: 15 }}
              className="btn btn-primary m-3 align-self-center"
              onClick={askAlert({
                message: "¿Desea aprobar este proyecto?",
                confirmText: "Aprobar",
                callback: handleSubmit(aprobarProyecto),
              })}
            >
              Aprobar proyecto
            </button>
          )}
        {estadoValue === proyectos_operaciones_estados.APROBADO && (
          <button
            type="button"
            style={{ fontSize: 15 }}
            className="btn btn-success m-3 align-self-center"
            onClick={askAlert({
              message: "¿Marcar como venta ganada?",
              confirmText: "Confirmar",
              callback: handleSubmit(() => ventaGanadaPerdida(true)),
            })}
          >
            Venta ganada
          </button>
        )}
        {estadoValue === proyectos_operaciones_estados.APROBADO && (
          <button
            type="button"
            style={{ fontSize: 15 }}
            className="btn btn-warning m-3 align-self-center"
            onClick={askAlert({
              message: "¿Marcar como venta perdida?",
              confirmText: "Confirmar",
              callback: handleSubmit(() => ventaGanadaPerdida(false)),
            })}
          >
            Venta perdida
          </button>
        )}
      </div>
    </form>
  );
};

const form = reduxForm({
  form: "ProyectoOperacionesForm",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
})(ThirdStep);

const selector = formValueSelector("ProyectoOperacionesForm");
export default connect((state) => {
  const personalValue = selector(state, "personal") || [];
  const modulosValue = selector(state, "modulos") || [];
  const actividadesValue = selector(state, "actividades") || [];
  const gastosValue = selector(state, "gastos") || [];
  const impuestosAdicionales = selector(state, "impuestos_adicionales");
  const margenNegocio = selector(state, "margen_negocio");
  const gananciaAdicional = selector(state, "ganancia_adicional");
  const comisionVenta = selector(state, "comision_venta");
  const estadoValue = selector(state, "estado");
  return {
    personalValue,
    modulosValue,
    actividadesValue,
    gastosValue,
    impuestosAdicionales,
    margenNegocio,
    gananciaAdicional,
    comisionVenta,
    estadoValue,
    // currentPlantilla se obtiene de otro reducer:
    currentPlantilla: state.proyectos_operaciones.currentPlantilla,
  };
})(form);
