import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import moment from "moment";

import { api } from "api";

import { NotificationManager } from "react-notifications";

const SUBMIT = "RECURSO_SUBMIT";
const LOADER = "RECURSO_LOADER";
const DATA = "RECURSO_DATA";
const ALL = "RECURSO_ALL";
const FILTER = "RECURSO_FILTER";

const ENDPOINT = "recurso";

export const LIST_CONTACTO = `${ENDPOINT}/list_contacto`;

export const BIRTHDAYS = `${ENDPOINT}/birthdays`;

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setAll = (all) => ({
    type: ALL,
    all,
});

export const setFilter = (filter) => ({
    type: FILTER,
    filter,
});

// ------------------------------------
// Actions
// ------------------------------------

export const renunciaDespido =
    (data = {}, params = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}/despido_renuncia`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(listar(1, params));
                dispatch(push("/recursos"));
            })
            .catch((e) => {
                if (e  && e.message && e.projects) {
                    const { message, projects } = e;
                    let text = `${message}<br />`;
                    const projectsLabel  = projects.map((p) => (
                        `${p.project} - salida ${moment(p.end_date).format("DD/MM/YYYY")}`
                    ))

                    Swal.fire({
                        title: "Error",
                        html: `${text}${projectsLabel.join(".<br />")}.`,
                        type: "error",
                    });
                }else {
                    Swal.fire({
                        title: "Error",
                        text: "Ha ocurrido un error, inténtelo nuevamente.",
                        type: "error",
                    });
                }
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });

                dispatch(initializeForm("RecursosCrearEditarForm", {}));
                dispatch(push("/recursos"));
            }) // then formData
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const createNewAddSolicitud =
    ({ data = {}, tipoAsignacion, callback }) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then((response) => {
                NotificationManager.success("Recurso creado", "Éxito", 1000);
                dispatch(initializeForm("RecursosCrearEditarForm", {}));
                callback({
                    tipo_asignacion: tipoAsignacion,
                    recurso: response.id,
                });
            })
            .catch((error) => {
                console.log(error);
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const proyectFilter =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get("/user/me")
            .then((response) => {
                if (response.type == 10) {
                    params.encargado = response.recurso;
                }
                api.get(`${ENDPOINT}/proyect_filter`, { page, ...params })
                    .then((response) => {
                        dispatch(setData(response));
                        dispatch(setLoader(false));
                    })
                    .catch(() => {
                        dispatch(setLoader(false));
                    });
            })
            .catch(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerRecurso =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                response.puesto = response.puesto.id;
                response.proyecto_default = response.proyecto_default.id;
                response.tipo_contratacion = response.tipo_contratacion
                    ? response.tipo_contratacion.id
                    : null;

                response.departamento_organizacion =
                    response.departamento_organizacion
                        ? response.departamento_organizacion.id
                        : null;

                response.seniority_puesto = response.seniority_puesto
                    ? response.seniority_puesto.id
                    : null;

                response.seniority_salario = response.seniority_salario
                    ? response.seniority_salario.id
                    : null;

                if (response.certificados_ingles) {
                    response.certificados_ingles =
                        response.certificados_ingles.map((certificado) => {
                            return {
                                id: certificado.id,
                                photo: certificado.archivo,
                            };
                        });
                }

                dispatch(
                    initializeForm("RecursosCrearEditarForm", {
                        codigo: response.codigo,
                        nombre: response.nombre,
                        email_empresarial: response.email_empresarial,
                        email: response.email,
                        puesto: response.puesto,
                        tipo_contratacion: response.tipo_contratacion,
                        proyecto_default: response.proyecto_default,
                        skills: response.skills,
                        fecha_inicio: response.fecha_inicio,
                        departamento_organizacion:
                            response.departamento_organizacion,
                        skills_experiencia: response.skills_experiencia,
                        tipo_contrato: response.tipo_contrato,
                        seniority_puesto: response.seniority_puesto,

                        nacionalidad: response.nacionalidad,
                        pais: response.pais,
                        direccion: response.direccion,
                        municipio: response.municipio,
                        departamento: response.departamento,
                        salario: response.salario,
                        pretencion_salarial: response.pretencion_salarial,
                        tipo_moneda: response.tipo_moneda,

                        telefono: response.telefono,
                        dpi: response.dpi,
                        nit: response.nit,
                        foto_perfil_url: response.foto_perfil,
                        foto_dpi_url: response.foto_dpi,

                        cv_empresa_url: response.cv_empresa,
                        cv_personal_url: response.cv_personal,
                        nivel_ingles: response.nivel_ingles,

                        fecha_nacimiento: response.fecha_nacimiento,
                        seniority_salario: response.seniority_salario,
                        estado: response.estado,
                        certificados_ingles: response.certificados_ingles,
                        genero: response.genero,
                        estado_civil: response.estado_civil,
                        nivel_academico: response.nivel_academico,  
                        nombre_contacto_emergencia: response.nombre_contacto_emergencia,
                        telefono_contacto_emergencia: response.telefono_contacto_emergencia,
                        titulacion: response.titulacion,
                    })
                );
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarRecurso =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(({}) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(initializeForm("RecursosCrearEditarForm", {}));
                dispatch(push("/recursos"));
            }) // then formData
            .catch((error) => {
                let text = "Ha ocurrido un error, inténtelo nuevamente.";
                if (
                    error &&
                    error.detail &&
                    error.detail.includes("Duplicate")
                ) {
                    text = "Código ya asignado, por favor inténtelo con otro.";
                } else if (error && error.non_field_errors) {
                    text = error.non_field_errors[0];
                }

                Swal.fire({
                    title: "Error",
                    text,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar =
    (id, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.eliminar(`${ENDPOINT}/${id}`)
            .then((response) => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha eliminado correctamente",
                    type: "success",
                });
                dispatch(listar(1, params));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

//se utiliza el endpoint junto con el metodo reactivar para reactivar 
export const reactivar = (id, params = {}) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`${ENDPOINT}/${id}/reactivar`) 
        .then((response) => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha restablecido correctamente",
                type: "success",
            });
            dispatch(listar(1, params));
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};



export const getMe = () => (dispatch) => {
    api.get("/user/me")
        .then((me) => {
            dispatch(initializeForm("profile", me));
            dispatch(setMe(me));
        })
        .catch(() => {})
        .finally(() => {});
};

export const getAll =
    (params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/all`, { ...params })
            .then((all) => {
                dispatch(setAll(all));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const limpiarForm = () => (dispatch) => {
    dispatch(initializeForm("RecursosCrearEditarForm", {}));
};

export const filter =
    (params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/filter_list`, params)
            .then((response) => {
                dispatch(setFilter(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const getDatosBasicos =
    (id, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}/contacto`, params)
            .then((response) => {
                dispatch(
                    initializeForm("VisualizarDatosBasicosForm", response)
                );
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actions = {
    listar,
    eliminar,
    reactivar,
    onSubmit,
    obtenerRecurso,
    actualizarRecurso,
    getAll,
    proyectFilter,
    renunciaDespido,
    limpiarForm,
    filter,
    getDatosBasicos,
    createNewAddSolicitud,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ALL]: (state, { all }) => {
        return {
            ...state,
            all,
        };
    },
    [FILTER]: (state, { filter }) => {
        return {
            ...state,
            filter,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    all: [],
    filter: [],
};

export default handleActions(reducers, initialState);
