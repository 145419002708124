import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSelectField, renderSelectFieldText } from "../../Utils/renderField";
import _ from "lodash";
import { api } from "api";
import TablaRecursos from "./TablaRecursos";
import "reactjs-popup/dist/index.css";

//Reporte
import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


//Bitacora
import TablaBitacora from "./TablaBitacora";

let CrearEditarForm = (props) => {
    const {
        handleSubmit,
        recursos,
        estado_pausa,
        tags,
        setTags,
        tipos_proyecto,
        puestos,
        clientes,
        fases,
        recursos_submit,
        setRecurosSubmit,
        recursos_update,
        edit,
        role,
        id_usuario,
        edit_type,
        planificacion,
        setPlanificacion,
        planificacion_update,
        recursos_encargados,
        bitacora,
        idProyecto,
    } = props;

    //obtener nombre de usuarios
    const [usuarios, setUsuarios] = useState({});
    useEffect(() => {
        // Obtener la información de los usuarios de los registros de la bitácora
        const obtenerUsuarios = async () => {
            const usuariosIds = [...new Set(props.bitacora.map(registro => registro.usuario))];
            const usuariosData = {};

            await Promise.all(usuariosIds.map(async id => {
                const response = await api.get(`user/${id}`);
                usuariosData[id] = response;
            }));

            setUsuarios(usuariosData);
        };

        if (props.bitacora.length > 0) {
            obtenerUsuarios();
        }
    }, [bitacora]);

    //tiene los nombres de los usuarios
    const nuevaBitacora = bitacora.map(registro => {
        // Verificamos si el id de usuario de este registro existe en el objeto de usuarios
        if (usuarios[registro.usuario]) {
            // Si existe, agregamos las propiedades first_name y last_name al registro de la bitácora
            return {
                ...registro,
                first_name: usuarios[registro.usuario].first_name,
                last_name: usuarios[registro.usuario].last_name
            };
        } else {
            // Si no existe, simplemente devolvemos el registro de la bitácora sin cambios
            return registro;
        }
    });

    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    const [_fases, setFases] = useState([]);
    const [_tipo, setTipos] = useState([]);
    const [_recursos, setRecursos] = useState([]);
    const [fase_proyecto, setFase] = useState(null);
    const [tipo, setTipo] = useState(null);
    const [persona, setPersona] = useState(null);
    const [edit_index, setEditIndex] = useState(null);
    const [porcentaje, setPorcentaje] = useState("");
    const [update, setUpdate] = useState(true);
    const [edit_recurso, setEditRecurso] = useState(null);

    const compareDate = (a, b) => {
        if (a.fecha_inicio < b.fecha_inicio) {
            return -1;
        }
        if (a.fecha_inicio > b.fecha_inicio) {
            return 1;
        }
        return 0;
    };

    const updateArray = (data, recurso_index = null) => {
        const recursos_submit_copy = [...recursos_submit];
        if (recurso_index) {
            recursos_submit_copy[recurso_index.fase_proyecto].recursos.splice(
                recurso_index.index,
                1
            );
        }
        if (data) {
            const index = recursos_submit.findIndex(
                (e) => e.id == data.fase_proyecto
            );
            recursos_submit_copy[index].recursos.push(data);
            recursos_submit_copy[index].recursos.sort(compareDate);
        }
        setRecurosSubmit(recursos_submit_copy);
        setUpdate(!update);
    };

    const updateArray1 = (i, value, id) => {
        const planificacion_copy = [...planificacion];
        const planificacion_item_copy = { ...planificacion_copy[i] };
        planificacion_item_copy[id] = value;
        planificacion_copy[i] = planificacion_item_copy;
        setPlanificacion(planificacion_copy);
    };

        //Variables dinámicas
        useEffect(() => {
            const filteredFases = fases.filter(fase => fase.aparece_proyecto);
            const new_array = filteredFases.map(fase => ({
                id: fase.id,
                nombre: fase.nombre,
                cantidad_recursos: "",
                horas_planificadas: "",
                horas_ejecutadas: "",
                fecha_inicio: "",
                fecha_fin: "",
            })
        );
            if (new_array.length > 0) {
                if (planificacion_update) {
                    if (planificacion_update.length > 0 && edit) {
                        planificacion_update.forEach((f) => {
                            let index = new_array.findIndex(
                                (h) => h.id === f.fase_proyecto
                            );
                            if (index !== -1) {
                                new_array[index].cantidad_recursos =
                                 f.cantidad_recursos;
                                new_array[index].horas_planificadas = f.horas_planificadas;
                                new_array[index].fecha_inicio = f.fecha_inicio;
                                new_array[index].fecha_fin = f.fecha_fin;
                            }
                        });
                    }
                }
                setPlanificacion(new_array);
            }
        }, [fases, edit, planificacion_update]);
        
        useEffect(() => {
            const filteredFases = fases.filter(fase => fase.aparece_proyecto);
            const new_array = filteredFases.map(fase => ({
                id: fase.id,
                nombre: fase.nombre,
                recursos: [],
                fecha_inicio: "",
                fecha_fin: "",
            })
            );
            if (new_array.length > 0) {
                if (recursos_update) {
                    if (recursos_update.length > 0 && edit) {
                        recursos_update.forEach((f) => {
                            let index = new_array.findIndex(
                                (h) => h.id === f.fase_proyecto
                            );
                            index = index === -1 ? 0 : index;
                            new_array[index].recursos.push(f);
                            new_array[index].recursos.sort(compareDate);
                        });
                    }
                }
                if (planificacion) {
                    planificacion.forEach((f) => {
                        let index = new_array.findIndex((h) => h.id === f.id);
                        if (index !== -1) {
                            new_array[index].fecha_inicio = f.fecha_inicio;
                            new_array[index].fecha_fin = f.fecha_fin;
                        }
                    });
                }
                setRecurosSubmit(new_array);
            }
        }, [fases, recursos_update, edit, planificacion]);
        
        useEffect(() => {
            const filteredFases = fases.filter(fase => fase.aparece_proyecto);
            const _fases_copy = filteredFases.map(option => ({
                ...option,
                label: option["nombre"],
                value: option["id"],
            }));
            setFases(_fases_copy);
            if (fase_proyecto !== null && fase_proyecto !== undefined) {
                setFase(_.find(_fases_copy, { fase_proyecto }));
            }
        }, [fases, fase_proyecto]);
    
        useEffect(() => {
            setTipos(puestos);
            if (tipo !== null && tipo !== undefined) {
                setTipo(_.find(_tipo, { tipo }));
            }
        }, [puestos]);
    
        useEffect(() => {
            const _recursos_copy = [..._recursos];
            recursos.forEach((option) => {
                _recursos_copy.push({
                    ...option,
                    label: option["nombre"],
                    value: option["id"],
                });
            });
            setRecursos(_recursos_copy);
            if (persona !== null && persona !== undefined) {
                setPersona(_.find(_recursos, { persona }));
            }
        }, [recursos]);



        //Reporte
        //Entrega el nombre que coincida con el id en la lista 
        const obtenerLabelTipo = (tipo_proyecto, tipos_proyecto) => {
            const selectedOption = tipos_proyecto.find(option => option.value === tipo_proyecto);
            return selectedOption ? selectedOption.label : "";
        };

        //Entrega el nombre que coincida con el id en la lista(trata un array diferente)
        const obtenerNombreEncargado = (encargadoId, encargados) => {
            const encargado = encargados.find(persona => persona.id === encargadoId);
            return encargado ? encargado.nombre : "";
        };
        

        const handleExportExcel = () => {
            //variables de estilo
            const estilo_contenido = { 
                font: 
                { 
                    bold: false, 
                    color: { rgb: '0f4ef5' } 
                }, 
                fill: 
                { 
                    patternType: "solid", 
                    fgColor: { rgb: "f3f3f3" } 
                },
                border: {
                    top: { style: "thin", color: { rgb: "0f4ef5" } },
                    bottom: { style: "thin", color: { rgb: "0f4ef5" } },
                    left: { style: "thin", color: { rgb: "0f4ef5" } },
                    right: { style: "thin", color: { rgb: "0f4ef5" } }
                } 
            } 
            const estilo_encabezado = { 
                font: 
                { 
                    bold: true, 
                    color: { rgb: 'ffffff' } 
                }, 
                fill: 
                { 
                    patternType: "solid", 
                    fgColor: { rgb: "0f4ef5" } 
                } ,
            }
            const estilo_titulo_tabla = {
                alignment: { horizontal: "left" }, 
                font: 
                { 
                    bold: true, 
                    sz: 12, 
                    name: "Calibri", 
                    color: { rgb: '000000' } 
                }, 
                fill: {
                    patternType: "solid",
                    fgColor: { rgb: "ffffff" }
                },
            }
            //variables datos
            const { 
                    nombre = "", 
                    tipo_proyecto = "", 
                    encargado = "", 
                    cliente = "", 
                    fecha_fin = "" } = props.formValues || {};
        
            const tipo_proyecto_label = obtenerLabelTipo(tipo_proyecto, tipos_proyecto);
            const cliente_label = obtenerLabelTipo(cliente, clientes);
        
            const encargado_nombre = obtenerNombreEncargado(encargado, recursos_encargados);
        
            // Inicializar el array de datos con los datos principales del proyecto
            const data = [
                [
                    { value: nombre, style: estilo_contenido  },
                    { value: tipo_proyecto_label, style: estilo_contenido },
                    { value: encargado_nombre, style: estilo_contenido},
                    { value: cliente_label, style: estilo_contenido},
                    { value: fecha_fin, style: estilo_contenido },
                ]
            ];
        
            // Fila en blanco como separador
            data.push(Array(data[0].length).fill({ value: "" }));

            // Insertar titulo de tabla
            data.push([{value: "FASES", style: estilo_titulo_tabla},]);
        
            // Encabezados para las columnas de las fases
            data.push([
                { value: "Fase", width: { wpx: 150 }, style:estilo_encabezado },
                { value: "Cantidad recursos", width: { wpx: 150 }, style: estilo_encabezado },
                { value: "Horas Planificadas", width: { wpx: 150 }, style: estilo_encabezado },
                { value: "Fecha Inicio", width: { wpx: 150 }, style: estilo_encabezado},
                { value: "Fecha Fin", width: { wpx: 150 }, style: estilo_encabezado },
            ]);
        
            // Agregar cada elemento de la planificación al array data
            planificacion.forEach((fase) => {
                const nombre = fase.nombre || "---"; // Valor predeterminado en caso de que sea nulo
                const cantidadRecursos = fase.cantidad_recursos || "---";
                const horasPlanificadas = fase.horas_planificadas || "---";
                const fechaInicio = fase.fecha_inicio || "---";
                const fechaFin = fase.fecha_fin || "---";
        
                data.push([
                    { value: nombre, style: estilo_contenido},
                    { value: cantidadRecursos, style: estilo_contenido},
                    { value: horasPlanificadas, style: estilo_contenido},
                    { value: fechaInicio, style: estilo_contenido},
                    { value: fechaFin, style: estilo_contenido},
                ]);
            });

            // Fila en blanco como separador antes de los datos de la tabla de recursos
            data.push(Array(data[0].length).fill({ value: "" }));

            // Insertar titulo de tabla
            data.push([{value: "FASES Y RECURSOS DEL PROYECTO", style: estilo_titulo_tabla},]);

        
            // Encabezado para los recursos del proyecto
            data.push([
                { value: "Fase", width: { wpx: 150 }, style: estilo_encabezado },
                { value: "Recurso", width: { wpx: 150 }, style: estilo_encabezado},
                { value: "Tipo", width: { wpx: 150 }, style: estilo_encabezado},
                { value: "Porcentaje", width: { wpx: 150 }, style: estilo_encabezado},
                { value: "Fecha Inicio", width: { wpx: 150 }, style: estilo_encabezado },
                { value: "Fecha Fin", width: { wpx: 400 }, style: estilo_encabezado},
            ]);

            props.recursos_submit.forEach((fase) => {
                if (fase.recursos.length > 0) {
                    fase.recursos.forEach((recurso) => {
                        const faseNombre = fase.nombre || "---";
                        const recursoNombre = recurso.recurso_nombre || "---";
                        const tipo = recurso.puesto_label || "---";
                        const porcentaje = recurso.porcentaje_asignacion || "---";
                        const fechaInicio = recurso.fecha_inicio ||  "---";
                        const fechaFin = recurso.fecha_fin ||  "---";
            
                        data.push([
                            { value: faseNombre, style: estilo_contenido},
                            { value: recursoNombre, style: estilo_contenido},
                            { value: tipo, style: estilo_contenido},
                            { value: porcentaje, style: estilo_contenido },
                            { value: fechaInicio, style: estilo_contenido },
                            { value: fechaFin, style: estilo_contenido},
                        ]);
                    });
                } else {
                    // Si no hay recursos, agregar una entrada con valores predeterminados
                    const faseNombre = fase.nombre || "---";
                    const recursoNombre = "---";
                    const tipo = "---";
                    const porcentaje = "---";
                    const fechaInicio = "---";
                    const fechaFin = "---";
            
                    data.push([
                        { value: faseNombre, style: estilo_contenido},
                        { value: recursoNombre,  style: estilo_contenido},
                        { value: tipo, style: estilo_contenido},
                        { value: porcentaje, style: estilo_contenido },
                        { value: fechaInicio, style: estilo_contenido },
                        { value: fechaFin, style: estilo_contenido },
                    ]);
                }
            });


            //BITACORA
            // Fila en blanco como separador antes de los datos de la tabla de recursos
            data.push(Array(data[0].length).fill({ value: "" }));

            // Insertar titulo de tabla
            data.push([{value: "BITACORA", style: estilo_titulo_tabla},]);

            // Encabezado para los recursos del proyecto
            data.push([
                { value: "Fecha", width: { wpx: 150 }, style: estilo_encabezado },
                { value: "Observacion", width: { wpx: 150 }, style: estilo_encabezado },
                { value: "Usuario", width: { wpx: 150 }, style: estilo_encabezado },
            ]);

            nuevaBitacora.forEach((registro,index) => {
                const fecha_inicio = registro.fecha_inicio || "---"; 
                const observacion = registro.observacion || "---";
                const usuario = registro.first_name + " " + registro.last_name || "---";

                data.push([
                    { value: fecha_inicio , style: estilo_contenido},
                    { value: observacion, style: estilo_contenido},
                    { value: usuario, style: estilo_contenido },
                ]);
            });

            return [
                {
                    columns: [
                        { title: "Nombre del proyecto", width: { wpx: 210 }, style: estilo_encabezado },
                        { title: "Tipo de proyecto", width: { wpx: 220 }, style: estilo_encabezado },
                        { title: "Encargado", width: { wpx: 210 }, style: estilo_encabezado },
                        { title: "Cliente", width: { wpx: 210 }, style: estilo_encabezado },
                        { title: "Fecha final programada", width: { wpx: 180 }, style: estilo_encabezado },
                        { title: "", width: { wpx: 180 }  },
                    ],
                    data: data,
                }
            ];
            
        };
        
        const multiDataSet = handleExportExcel();



    return (
        <React.Fragment>
            <ExcelFile element={<button className="btn btn-success"
                style={{ float: 'right', marginBottom: '30px' }}>Exportar</button>} filename="Detalle_de_proyecto">
               
                <ExcelSheet dataSet={multiDataSet} name="Detalle de proyecto" />
            </ExcelFile>
            <form  onSubmit={handleSubmit}>
                <div className="card card-login w-100">
                    <div className="row">
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="nombre">Nombre del proyecto</label>
                            <Field
                                name="nombre"
                                label="Nombre"
                                component={renderField}
                                type="text"
                                className="input--style"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    (role != 1 && role != 11)
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="puesto">Tipo de proyecto</label>
                            <Field
                                name="tipo_proyecto"
                                label="Tipo de proyecto"
                                options={tipos_proyecto}
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                select_style={select_style}
                                disabled={
                                    (edit && edit_type != "general") ||
                                    (role != 1 && role != 11)
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="puesto">Encargado</label>
                            <Field
                                name="encargado"
                                label="Encargado"
                                options={recursos_encargados}
                                isClearable={true}
                                component={renderSelectField}
                                labelKey="nombre"
                                valueKey="id"
                                select_style={select_style}
                                disabled={
                                    (edit && edit_type != "general") ||
                                    (role != 1 && role != 11)
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="cliente">Cliente</label>
                            <Field
                                name="cliente"
                                label="Cliente"
                                options={clientes}
                                isClearable={true}
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                select_style={select_style}
                                disabled={
                                    (edit && edit_type != "general") ||
                                    (role != 1 && role != 11)
                                }
                            />
                        </div>
                        <div className="form-group has-feedback col-12 col-md-4">
                            <label htmlFor="tipo_contratacion">
                                Fecha final programada
                            </label>
                            <Field
                                name="fecha_fin"
                                label="Fecha final programada"
                                component={renderField}
                                type="date"
                                className="input--style"
                                disabled={
                                    (edit && edit_type != "general") ||
                                    (role != 1 && role != 11)
                                }
                            />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="form-group has-feedback col-12 row">
                            <h5 style={{ marginLeft: "15px" }}>Fases</h5>
                            <div className="table-responsive d-flex justify-content-center">
                                <table
                                    className="table resources--table"
                                    style={{ width: "98%" }}
                                >
                                    <thead className="thead--blue">
                                        <tr>
                                            <th scope="col">Fase</th>
                                            <th scope="col">
                                                Cantidad recursos
                                            </th>
                                            <th scope="col">Horas Programadas</th>
                                            <th scope="col">Fecha inicio</th>
                                            <th scope="col">Fecha final</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {planificacion.map((e, i) => (
                                            <tr
                                                key={i}
                                                className="border--lr border--bt border--last"
                                            >
                                                <td>{e.nombre}</td>
                                                <td>
                                                    <input
                                                        name={`${i}_cantidad_recursos`}
                                                        id={`${i}_cantidad_recursos`}
                                                        className="form-control input--style"
                                                        type="number"
                                                        placeholder="0"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "cantidad_recursos"
                                                            )
                                                        }
                                                        value={
                                                            e.cantidad_recursos
                                                        }
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            (role != 1 && role != 11)
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`${i}_horas_planificadas`}
                                                        id={`${i}_horas_planificadas`}
                                                        className="form-control input--style"
                                                        type="number"
                                                        placeholder="0"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "horas_planificadas"
                                                            )
                                                        }
                                                        value={e.horas_planificadas}
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            (role != 1 && role != 11)
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`${i}_fecha_inicio`}
                                                        id={`${i}_fecha_inicio`}
                                                        className="form-control input--style"
                                                        type="date"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "fecha_inicio"
                                                            )
                                                        }
                                                        value={e.fecha_inicio || ''}
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            (role != 1 && role != 11)
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        name={`${i}_fecha_fin`}
                                                        id={`${i}_fecha_fin`}
                                                        className="form-control input--style"
                                                        type="date"
                                                        onChange={(e) =>
                                                            updateArray1(
                                                                i,
                                                                e.target.value,
                                                                "fecha_fin"
                                                            )
                                                        }
                                                        value={e.fecha_fin || ''}
                                                        disabled={
                                                            (edit && edit_type != "general") ||
                                                            (role != 1 && role != 11)
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                        <div className="d-flex flex-column align-items-start pt-3">
                            <h3 className="title--blue">
                                FASES  Y RECURSOS DEL PROYECTO
                            </h3>
                        </div>
                        <hr />
                        <div className="card card-login w-100">
                            <TablaRecursos
                                recursos_submit={recursos_submit}
                                setEditIndex={setEditIndex}
                                setFase={setFase}
                                setPersona={setPersona}
                                setTipo={setTipo}
                                setPorcentaje={setPorcentaje}
                                updateArray={updateArray}
                                _fases={_fases}
                                _recursos={_recursos}
                                _tipo={_tipo}
                                setEditRecurso={setEditRecurso}
                                estado_pausa ={estado_pausa}
                            />
                        </div>
                </React.Fragment>

                <hr />
                <React.Fragment>
                    <div className="d-flex flex-column align-items-start pt-3">
                        <h3 className="title--blue">
                            BITACORA
                        </h3>
                    </div>
                    <hr />
                    <div className="card card-login w-100">
                        <TablaBitacora 
                                idProyecto = { idProyecto }
                                id_usuario = { id_usuario }
                        />
                    </div>
                </React.Fragment>

                    <hr />
                    <Link
                        to="/proyectos/reporte_detallado"
                        className="btn btn-secondary"
                        style={{ fontSize: 15 }}
                    >
                        Regresar
                    </Link>
                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Guardar
                    </button>
            </form>


        </React.Fragment>
    );
};

// VALIDACIONES
CrearEditarForm = reduxForm({
    form: "CrearEditarForm",
    validate: (data) => {
        return validate(data, {
            nombre: validators.exists()("Este campo es requerido"),
            tipo_proyecto: validators.exists()("Este campo es requerido"),
            encargado_porcentaje_asignacion: validators.exists()(
                "Este campo es requerido"
            ),
        });
    },
})(CrearEditarForm);

// SELECTOR DE FORM PROPS
const selector = formValueSelector("CrearEditarForm");
CrearEditarForm = connect((state) => {
    const formValues = selector(state, "nombre", "tipo_proyecto", "encargado", "cliente", "fecha_fin");
    const temp_tag = selector(state, "tags_input");
    const selected_recursos = selector(state, "recursos");
    const fecha_inicio_form = selector(state, "fecha_inicio");
    const fecha_fin_form = selector(state, "fecha_fin");
    const fecha_fin_retraso_form = selector(state, "fecha_fin_retraso");
    const viene_de_presupuestos = selector(state, "viene_de_presupuestos");
    const estado_pausa = selector(state, "estado_pausa");
    const activo = selector(state, "activo");
    const proyecto_presupuesto = selector(state, "proyecto_presupuesto");
    const idProyecto = selector(state, "id");
    let fecha_inicio_calculated = null,
        fecha_fin_calculated = null,
        fecha_fin_retraso_calculated = null;
    return {
        formValues,
        temp_tag,
        selected_recursos,
        fecha_inicio_form,
        fecha_fin_form,
        fecha_inicio_calculated,
        fecha_fin_calculated,
        fecha_fin_retraso_form,
        viene_de_presupuestos,
        estado_pausa,
        activo,
        proyecto_presupuesto,
        fecha_fin_retraso_calculated,
        idProyecto,
    };
})(CrearEditarForm);

export default CrearEditarForm;