import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/tipos_proyecto/tipos_proyecto';
import CrearEditarSubCategoria from './CrearEditarSubCategoria';

const mapStateToProps = (state) => ({
    ...state.tipos_proyecto,
    categorias: state.tipos_proyecto.categorias,
    subcategorias: state.tipos_proyecto.subcategorias,
});

const mapDispatchToProps = { 
    ...actions 
};

export default connect(mapStateToProps, mapDispatchToProps)(CrearEditarSubCategoria);
