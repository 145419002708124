import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Swal from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Listar.css'; // Tu estilo personalizado
import RowItem from './RowItem'; // Ajusta la ruta si lo tienes en otro lado

class Listar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            pageSize: 10,
            filters: { activo: true },
            search: '',

            expandedTipos: {},
            expandedCategorias: {},
        };
    }

    componentDidMount() {
        this.fetchData();
    }


    // 1) Llama a la acción Redux con page, pageSize, filters
    fetchData = () => {
        const { listarTiposProyecto } = this.props;
        const { page, pageSize, filters } = this.state;
        listarTiposProyecto(page, filters, pageSize);
    };

    // 2) Paginación sin depender de data.next
    goToNextPage = () => {
        const totalPages = this.getTotalPages();
        if (this.state.page < totalPages) {
            this.setState({ page: this.state.page + 1 }, () => {
                this.fetchData();
            });
        }
    };

    goToPreviousPage = () => {
        if (this.state.page > 1) {
            this.setState({ page: this.state.page - 1 }, () => {
                this.fetchData();
            });
        }
    };

    // Retorna cuántas páginas totales hay, basado en 'count' y 'pageSize'
    getTotalPages = () => {
        const { data } = this.props; // data: { count, results: [...], etc. }
        const { pageSize } = this.state;
        if (!data || !data.count) return 1;

        return Math.ceil(data.count / pageSize);
    };

    // Simplemente devolvemos this.state.page
    getCurrentPage = () => {
        return this.state.page;
    };

    // 3) Buscador
    handleSearchEnter = (e) => {
        if (e.key === 'Enter') {
            const newFilters = { ...this.state.filters, search: this.state.search };
            this.setState({ filters: newFilters, page: 1 }, this.fetchData);
        }
    };

    // 4) Drag & Drop
    handleDragEnd = (result) => {
        if (!result.destination) return;

        const { data, actualizarOrdenTipos } = this.props;
        if (!data || !data.results) return;

        const tipos = [...data.results];
        const [moved] = tipos.splice(result.source.index, 1);
        tipos.splice(result.destination.index, 0, moved);

        const newOrder = tipos.map((item, i) => ({
            id: item.id,
            orden: i + 1,
        }));
        actualizarOrdenTipos(newOrder);
    };

    // 5) Eliminar
    eliminarTipo = (id) => {
        this.props.eliminarTipo(id);
    };

    // ----------------------------------------------------------------
    // 6) Expandir/Colapsar Tipos y Categorías
    // ----------------------------------------------------------------
    toggleTipo = (tipoId) => {
        this.setState((prevState) => {
            const expanded = { ...prevState.expandedTipos };
            expanded[tipoId] = !expanded[tipoId];
            return { expandedTipos: expanded };
        });
    };

    toggleCategoria = (catId) => {
        this.setState((prevState) => {
            const expanded = { ...prevState.expandedCategorias };
            expanded[catId] = !expanded[catId];
            return { expandedCategorias: expanded };
        });
    };

    // ----------------------------------------------------------------
    // 7) Render de Subcategorías
    // ----------------------------------------------------------------
    renderSubcategorias = (subcategorias) => {
        return subcategorias.map((sub) => {
            return (
                <RowItem
                    key={`sub-${sub.id}`}
                    disableDrag
                    showToggle={false}
                    nombre={sub.nombre}
                    padding="90px"
                    actions={
                        <div>
                            <Link
                                className="standard-action action--edit"
                                to={`/subcategorias/${sub.id}/editar`}
                            >
                                <i className="material-icons">edit</i>
                            </Link>
                            <a
                                className="standard-action action--delete"
                                style={{ cursor: 'pointer', color: '#c4183c' }}
                                onClick={() => {
                                    // Si tuvieras props.eliminarSubCategoria, lo llamas:
                                    // this.props.eliminarSubCategoria(sub.id);
                                    Swal.fire('Info', 'Eliminar Subcategoría no está implementado', 'info');
                                }}
                            >
                                <i className="material-icons">delete</i>
                            </a>
                        </div>
                    }
                />
            );
        });
    };

    // ----------------------------------------------------------------
    // 8) Render de Categorías
    // ----------------------------------------------------------------
    renderCategorias = (categorias) => {
        const rows = [];
        for (let i = 0; i < categorias.length; i++) {
            const cat = categorias[i];
            const subcategorias = cat.subcategorias ? cat.subcategorias : [];
            const hasSub = subcategorias.length > 0;

            rows.push(
                <RowItem
                    key={`cat-${cat.id}`}
                    disableDrag
                    showToggle={hasSub}
                    isExpanded={this.state.expandedCategorias[cat.id] || false}
                    onToggle={() => this.toggleCategoria(cat.id)}
                    nombre={cat.nombre}
                    padding="40px"
                    actions={
                        <div>
                            <Link
                                to={`/subcategorias/crear?categoria=${cat.id}`}
                                className="btn btn-success btn-sm"
                            >
                                Crear Subcategoría
                            </Link>
                            <Link
                                className="standard-action action--edit"
                                to={`/categorias/${cat.id}/editar`}
                            >
                                <i className="material-icons">edit</i>
                            </Link>
                            <a
                                className="standard-action action--delete"
                                style={{ cursor: 'pointer', color: '#c4183c' }}
                                onClick={() => {
                                    // this.props.eliminarCategoria(cat.id);
                                    Swal.fire('Info', 'Eliminar Categoría no está implementado', 'info');
                                }}
                            >
                                <i className="material-icons">delete</i>
                            </a>
                        </div>
                    }
                />
            );

            if (hasSub && this.state.expandedCategorias[cat.id]) {
                rows.push(...this.renderSubcategorias(subcategorias));
            }
        }
        return rows;
    };

    // ----------------------------------------------------------------
    // 9) Render Paginación
    // ----------------------------------------------------------------
    renderPagination = () => {
        const { data } = this.props;
        if (!data) {
            return null;
        }
        if (!data.count) {
            return null;
        }

        const currentPage = this.getCurrentPage();
        const totalPages = this.getTotalPages();

        return (
            <div className="d-flex justify-content-center align-items-center mt-3">
                <button
                    className="btn btn-sm btn-primary"
                    onClick={this.goToPreviousPage}
                >
                    <i className="material-icons">chevron_left</i>
                </button>
                <span className="mx-3">
                    Página {currentPage} de {totalPages}
                </span>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={this.goToNextPage}
                >
                    <i className="material-icons">chevron_right</i>
                </button>
            </div>
        );
    };

    // ----------------------------------------------------------------
    // 10) Render principal
    // ----------------------------------------------------------------
    render() {
        const { data, loader } = this.props;
        // Sacamos el array de tipos
        let tipos = [];
        if (data && data.results) {
            tipos = data.results;
        }

        return (
            <div>
                <div className="row">
                    <div className="col">
                        <h2 className="title--blue">LISTADO DE TIPOS</h2>
                    </div>
                </div>

                {/* Buscador */}
                <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <input
                            placeholder="Buscar..."
                            type="text"
                            className="form-control mt-4 input--style"
                            value={this.state.search}
                            onKeyPress={this.handleSearchEnter}
                            onChange={(e) => this.setState({ search: e.target.value })}
                            style={{ color: 'black', fontSize: 15, width: '55%' }}
                        />
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-end">
                        <Link
                            to="/tipos-proyecto/crear"
                            className="btn btn-primary mt-4 ml-3"
                            style={{ fontSize: 15 }}
                        >
                            Nuevo Tipo
                        </Link>
                        <button
                            className="btn btn-secondary mt-4 ml-3"
                            style={{ fontSize: 15 }}
                            onClick={() => console.log(this.props.data)}
                        >
                            Log Data
                        </button>
                    </div>
                </div>

                {/* Loader vs. contenido */}
                {loader ? (
                    <div className="text-center">
                        <Spinner animation="border" /> Cargando...
                    </div>
                ) : tipos.length > 0 ? (
                    <div>
                        <DragDropContext onDragEnd={this.handleDragEnd}>
                            <div className="table-responsive">
                                <table className="table table-borderless content--table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}>Ordenar</th>
                                            <th style={{ width: '60%' }}>Nombre</th>
                                            <th style={{ width: '30%' }}>Acciones</th>
                                        </tr>
                                    </thead>
                                    <Droppable droppableId="droppable-tipos">
                                        {(provided) => (
                                            <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                                {tipos.map((tipo, index) => {
                                                    const categorias = tipo.categorias ? tipo.categorias : [];
                                                    const hasCat = categorias.length > 0;
                                                    return (
                                                        <Draggable
                                                            key={`tipo-${tipo.id}`}
                                                            draggableId={`tipo-${tipo.id}`}
                                                            index={index}
                                                        >
                                                            {(providedDrag) => (
                                                                <React.Fragment>
                                                                    <RowItem
                                                                        draggableProps={{
                                                                            innerRef: providedDrag.innerRef,
                                                                            ...providedDrag.draggableProps,
                                                                        }}
                                                                        dragHandleProps={providedDrag.dragHandleProps}
                                                                        disableDrag={false}
                                                                        showToggle={hasCat}
                                                                        isExpanded={
                                                                            this.state.expandedTipos[tipo.id] || false
                                                                        }
                                                                        onToggle={() => this.toggleTipo(tipo.id)}
                                                                        nombre={tipo.nombre}
                                                                        actions={
                                                                            <div>
                                                                                <Link
                                                                                    to={`/categorias/crear?tipo_proyecto=${tipo.id}`}
                                                                                    className="btn btn-success btn-sm"
                                                                                >
                                                                                    Crear Categoría
                                                                                </Link>
                                                                                <Link
                                                                                    className="standard-action action--edit"
                                                                                    to={`/tipos-proyecto/${tipo.id}/editar`}
                                                                                >
                                                                                    <i className="material-icons">edit</i>
                                                                                </Link>
                                                                                <a
                                                                                    className="standard-action action--delete"
                                                                                    style={{ cursor: 'pointer', color: '#c4183c' }}
                                                                                    onClick={() => this.eliminarTipo(tipo.id)}
                                                                                >
                                                                                    <i className="material-icons">delete</i>
                                                                                </a>
                                                                            </div>
                                                                        }
                                                                    />
                                                                    {hasCat && this.state.expandedTipos[tipo.id]
                                                                        ? this.renderCategorias(categorias)
                                                                        : null}
                                                                </React.Fragment>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </tbody>
                                        )}
                                    </Droppable>
                                </table>
                            </div>
                        </DragDropContext>
                        {/* Paginación */}
                        {this.renderPagination()}
                    </div>
                ) : (
                    <div className="text-center">
                        <p>No se encontraron Tipos de Proyecto.</p>
                    </div>
                )}
            </div>
        );
    }
}

export default Listar;
