import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/proyectos/proyectos";
import { actions as user } from "../../../../redux/modules/cuenta/login";
import { actions as actionTipos } from "../../../../redux/modules/tipos_proyecto/tipos_proyecto";
import CrearEditar from "./CrearEditar";

const ms2p = (state) => {
    return {
        ...state.proyectos,
        user: { ...state.login },
        tipos_proyectos: state.tipos_proyecto.unidades_negocio,
        loaderTipos: state.tipos_proyecto.loader,
    };
};

const md2p = {
    ...actions,
    getRole: user.getRole,
    obtenerTipos: actionTipos.filterList,
    getUnidadesNegocio: actionTipos.fetchUnidadesNegocio,
};

export default connect(ms2p, md2p)(CrearEditar);
