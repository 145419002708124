import React from "react";

import { renderSelectField, renderCurrency, renderField } from "../../../Utils/renderField";
import { Field } from "redux-form";

export const renderGastos = ({
    fields,
    meta: { error },
    gastos = [],
    show = true,
    isDisabled = false,
}) => {
    const select_style = {
        control: (styles, state) => ({
            ...styles,
            color: "#1F4D7B",
            borderWidth: state.isFocused || !state.hasValue ? "1px" : "0px",
        }),
        indicatorSeparator: (styles) => ({
            ...styles,
            backgroundColor: "white",
        }),
    };

    return (
        <div
            className="col-10 mt-4"
            style={{
                display: show ? "block" : "none",
            }}
        >
            <div className="row mb-1">
                <span className="col-4 title--blue--1">Descripción</span>
                <span className="col-2 title--blue--1 p-0">Total</span>
                <span className="col-4 title--blue--1 p-0">Observaciones</span>

            </div>

            {fields.length <= 0 && (
                <div className="col-12">
                    <span className="text-center">---</span>
                </div>
            )}

            <span className="text-danger">{error}</span>

            {fields.map((gasto, index, fields) => {
                const currentField = fields.get(index);

                return (
                    <div className="row field--array bg-white" key={index}>
                        {/* Descripción (Select con listado de gastos) */}
                        <div className="form-group has-feedback col-4 m-0">
                            <Field
                                name={`${gasto}.gasto`}
                                select_style={select_style}
                                component={renderSelectField}
                                label={`#${index + 1}`}
                                options={gastos}
                                labelKey="label"
                                valueKey="value"
                                className="form-group"
                                placeholder="Gastos..."
                                extra_change={true}
                                extraChange={(value) => {
                                    const gastoSeleccionado = gastos.find(
                                        (g) => g.value === value
                                    );
                                    if (gastoSeleccionado) {
                                        currentField.total = gastoSeleccionado.monto;
                                    }
                                }}
                                disabled={isDisabled}
                            />
                        </div>

                        {/* Total (readOnly) */}
                        <div className="form-group has-feedback col-2 m-0 p-0">
                            <Field
                                name={`${gasto}.total`}
                                component={renderCurrency}
                                prefix="$"
                                label="Total"
                                placeholder="$"
                                readOnly={true}
                            />
                        </div>
                        {/* NUEVO CAMPO: Observaciones */}
                        <div className="form-group has-feedback col-4 m-0 p-0">
                            <Field
                                name={`${gasto}.observaciones`}
                                placeholder="Ingrese observaciones..."
                                className="form-control"
                                disabled={isDisabled}
                                component={renderField}
                            />
                        </div>

                        {/* Botón eliminar */}
                        <div className="col d-flex justify-content-end">
                            <button
                                className="btn standard-action action--delete"
                                type="button"
                                style={{
                                    color: "rgb(246, 81, 96)",
                                    backgroundColor: "rgba(246, 81, 96, 0.3)",
                                    padding: "0",
                                }}
                                onClick={() => fields.remove(index)}
                                disabled={isDisabled}
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>

                    </div>
                );
            })}

            {/* Botón Agregar */}
            <button
                type="button"
                className="btn mb-3 d-flex align-items-center justify-conten-center"
                onClick={() => fields.push({})}
                disabled={isDisabled}
            >
                <i
                    className="material-icons title--blue--1"
                    style={{ fontSize: "24px" }}
                >
                    add
                </i>
                <p
                    className="title--blue--1"
                    style={{ margin: "0", fontSize: "14px" }}
                >
                    Agregar
                </p>
            </button>
        </div>
    );
};
