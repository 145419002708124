import Popup from "reactjs-popup";

import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Field, reduxForm, formValueSelector,initialize } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import { renderField, renderSelectField } from '../../Utils/renderField';
import { connect } from 'react-redux';

const CrearEditarForm = (props) => {
    const {
        handleSubmit, 
        codigo_recurso, 
        puestos, 
        departamentos_organizacion,
        change,
        obtenerPuestos, 
        departamentoOrganizacionValue,

    } = props;


    React.useEffect(() => {
        obtenerPuestos({ departamento: departamentoOrganizacionValue });
    }, [departamentoOrganizacionValue]);

    console.log("departamentoOrganizacionValue", departamentoOrganizacionValue)

    useEffect(() => {  
        props.initialize({ codigo_recurso});
    }, [codigo_recurso]);  

    
    return(
        <form name="CrearEditarForm" className="form-validate mb-lg" onSubmit={handleSubmit}>
            <div className='row'>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Departamento</label>
                    <Field 
                        name="departamento_organizacion" 
                        label="departamento" 
                        component={renderSelectField} 
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={departamentos_organizacion }
                        labelKey="label"    
                        valuesKey="value"   
                        className="input--style"
                        placeholder= "Departamento..."
                        //onChange={handleDepartamentoChange}
                         
                    />
                </div>
                <div className="form-group has-feedback col-6">
                    <label htmlFor="nombre">Puesto</label>
                    <Field 
                        name="puesto" 
                        label="puesto" 
                        component={renderSelectField} 
                        select_style={{
                            control: (styles) => ({
                                ...styles,
                                color: "#1F4D7B",
                                borderColor: "#1F4D7B",
                            }),
                        }}
                        options={puestos}
                        labelKey="label"    
                        valuesKey="value"   
                        className="input--style"  
                        placeholder= "Puesto..."
                    />
                </div>
                
                <div className="form-group has-feedback col-6">
                    <label htmlFor="fecha_inicio">Fecha de aplicación</label>
                    <Field 
                        name="fecha_inicio" 
                        label="fecha_inicio" 
                        component={renderField}
                        type= "date"    
                        className="input--style" 
                        
                     />
                </div>
                <input type="hidden" name="codigo_recurso" />
            </div>
            <div className="buttons-box">
                <Link
                    to="/historial_interno"
                    className="btn btn-secondary"
                    style={{ fontSize: 15 }}
                >
                    Regresar
                </Link>
                <button type="submit" style={{ fontSize: 15 }} className="btn btn-primary m-1 align-self-center">Agregar</button>
            </div>
        
        </form>
    )

};

//export default reduxForm({form: 'CrearEditarForm',})(CrearEditarForm);

const form = reduxForm({
    form: 'CrearEditarForm', 
    validate: (data) => {
        return validate(data, {
            departamento_organizacion: validators.exists()('Este campo es requerido'),
            puesto: validators.exists()('Este campo es requerido'),
            fecha_inicio: validators.exists()('Este campo es requerido'),
        });
    },
})(CrearEditarForm);


const selector = formValueSelector('CrearEditarForm');
export default connect((state) => {
    const departamentoOrganizacionValue= selector(state, 'departamento_organizacion');
    const puestoValue = selector(state, "puesto") || null;

    return {
        departamentoOrganizacionValue,
        puestoValue,
    };
})(form);
