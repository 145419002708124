import moment from "moment";
import React, { useState, useEffect, useMemo, useRef } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";

//=================== Componentes =====================
import TableData from "./Tables/TableData";

//=================== CSS =============================
import "reactjs-popup/dist/index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";


const Visualizar = () => {
    const [loading, setLoading] = useState(false);

    const [disenadores, setDisenadores] = useState([]);
    const [programadores, setProgramadores] = useState([]);
    const [semanas, setSemanas] = useState([]);
    const [fechas, setFechas] = useState([]);

    const tablaTotal = useRef(null);

    // Filtrar datos por tipo de proyecto
    const [tiposProyecto, setTiposProyecto] = useState([]);
    const [tipoSelected, setTipoSelected] = useState(null);

     // Fechas de inicio y fin estandarizadas
     const fechaInicio = moment().startOf("week");
     const fechaFin = moment().add(4, "weeks").endOf("week");


    useEffect(() => {
        // Obtiene todos los tipos de proyectos y los pasa al formato requerido
        // El formato debe de ser un objeto { value: <id del tipo>, label: <nombre del tipo> }
        const getTipos = async () => {
            try {
                const tipos = await api.get("tipo_proyecto", { activo: true });
                const tiposFormat = tipos.results.map(tipo => ({ value: tipo.nombre, label: tipo.nombre }));
                setTiposProyecto(tiposFormat);
            } catch (err) {
                setTiposProyecto([]);
            }
        };

        getTipos();
    }, []);

    // Fin filtro por tipo de proyecto


    /*
    * Esta función filtra los recursos tomando en cuenta el nombre
    * del recurso y el tipo del proyecto en el que participa.
    */
    function filtrarRecursos(listaRecursos) {
        return () => listaRecursos.filter((recurso) => {
            const porTipo = tipoSelected ? recurso.proyecto_default === tipoSelected : true;
            return  porTipo ;
        });
    }

    /*
    * Filtrar programadores
    */
    const filterRecursos_P = useMemo(
        filtrarRecursos(programadores),
        [programadores]
    );

    /*
    * Filtrar diseñadores
    */
    const filterRecursos_D = useMemo(
        filtrarRecursos(disenadores),
        [disenadores]
    );

  
    useEffect(() => {
        setLoading(true);
        api.get("/reporte/getData", {
            fecha_inicio: fechaInicio.format("YYYY-MM-DD"),
            fecha_fin: fechaFin.format("YYYY-MM-DD"),
        })
            .then((res) => {
                setFechas(res.semanas_fechas);
                setSemanas(res.semanas_totales);
                setProgramadores(res.p);
                setDisenadores(res.d);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <React.Fragment>
            <div className="flex row mt-2">
                <div className="col text-left">
                    <h2 className="title--blue">DISPONIBILIDAD</h2>
                </div>
            </div>
            <hr />
            <LoadMask loading={loading} light>
                <table className="card bg-transparent shadow-none" ref={tablaTotal}>
                    <div className="card mb-3 mt-3">
                        <div className="card-header card--header">
                            Programadores
                        </div>
                        <br className="d-none" />
                        <div className="card-body">
                            {filterRecursos_P.length > 0 ? (
                                <div className="tabla-resumen table--scroll">
                                    <TableData
                                        recursos={filterRecursos_P}
                                        semanas={semanas}
                                        fechas={fechas}
                                    />
                                </div>
                            ) : (
                                <div className="text-center">
                                    {loading ? (
                                        <h5 className="text-secondary">
                                            Cargando...
                                        </h5>
                                    ) : (
                                        <h5 className="text-secondary">
                                            No hay datos
                                        </h5>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <br /> <br />
                    <div className="card mb-3 mt-3">
                        <div className="card-header card--header">
                            Diseñadores
                        </div>
                        <br className="d-none" />
                        <div className="card-body">
                            {filterRecursos_D.length > 0 ? (
                                <div className="tabla-resumen table--scroll">
                                    <TableData
                                        recursos={filterRecursos_D}
                                        semanas={semanas}
                                        fechas={fechas}
                                    />
                                </div>
                            ) : (
                                <div className="text-center">
                                    {loading ? (
                                        <h5 className="text-secondary">
                                            Cargando...
                                        </h5>
                                    ) : (
                                        <h5 className="text-secondary">
                                            No hay datos
                                        </h5>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </table>
            </LoadMask>
        </React.Fragment>
    );
};

export default Visualizar;
