import { Visualizar as VisualizarReportes } from "./Visualizar";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/permisos/permisos";

const ms2p = (state) => {
    return {};
};

const md2p = { goToColaborador: actions.colaboradorFilter2 };

export const Visualizar = connect(ms2p, md2p)(VisualizarReportes);


