import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarCategoriaForm from "./CrearEditarCategoriaForm";

class CrearEditarCategoria extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
        tipoId: null
    };

    componentDidMount() {
        const { match, location } = this.props;
        const categoriaId = match.params.id;
        const tipoId = new URLSearchParams(location.search).get('tipo_proyecto');

        if (categoriaId) {
            this.setState({ edit: true, id: categoriaId, tipoId, loader: false });
            this.props.obtenerCategoria(categoriaId);
        } else {
            this.setState({ edit: false, tipoId, loader: false });
        }
    }

    onSubmit = (data) => {
        const { edit, id, tipoId } = this.state;
        const { crearCategoria, actualizarCategoria, history } = this.props;
        if (edit) {
            actualizarCategoria(id, data).then(() => {
                history.push("/tipos-proyecto");
            });
        } else {
            crearCategoria({...data, tipo_proyecto: tipoId}).then(() => {
                history.push("/tipos-proyecto");
            });
        }
    };

    render() {
        const { loader, data } = this.props;
        const { edit } = this.state;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {edit ? "EDITAR" : "CREAR"} CATEGORÍA
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader} light>
                            <CrearEditarCategoriaForm
                                onSubmit={this.onSubmit}
                                categoria={data.results}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditarCategoria;
