import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";

import { renderField, renderSelectField } from "../../Utils/renderField";

import { RenderFields } from "./RenderFields";
import { renderSkills } from "./renderSkills";
import CrearSkill from "./CrearSkill";

import validateFields from "./validate";

const email = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? "Correo inválido"
        : undefined;

    const phone = (value) =>
    value && !/^\+?\d+([-\s]?\d+)*$/.test(value)
        ? "Número de teléfono inválido. Solo se permiten números, guiones, espacios, y puede comenzar con +."
        : undefined;
        

function DatosBasicosForm({
    edit,
    setPopupOpened,
    select_style,
    handleSubmit,
    skills,
    estadoCivil,
    genero,
    fromModalCreate = false,
    nivelAcademico,
    skillsColaborador,
}) {
    const fields = [
        [
            // row
            {
                label: "Código",
                props: {
                    name: "codigo",
                    label: "codigo",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Código...",
                },
            },
            {
                label: "Nombre",
                props: {
                    name: "nombre",
                    label: "Nombre",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Nombre...",
                },
            },
        ],
        [
            // row
            {
                label: "Correo empresarial",
                props: {
                    name: "email_empresarial",
                    label: "Correo empresarial",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Correo empresarial...",
                    validate: email,
                },
            },
            {
                label: "Correo personal",
                props: {
                    name: "email",
                    label: "Correo personal",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Correo personal...",
                    validate: email,
                },
            },
        ],
        [
            {
                label: "Teléfono",
                props: {
                    name: "telefono",
                    label: "Teléfono",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Teléfono...",
                    validate: phone,
                },
            },
            {
                label: "DPI",
                props: {
                    name: "dpi",
                    label: "DPI",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "DPI...",
                },
            },
        ],
        [
            {
                label: "Dirección",
                props: {
                    name: "direccion",
                    label: "Dirección",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Dirección...",
                },
            },
            {
                label: "Fecha de nacimiento",
                props: {
                    name: "fecha_nacimiento",
                    component: renderField,
                    className: "input--style",
                    type: "date",
                },
            },
        ],
        [
            {
                label: "Género",
                props: {
                    name: "genero",
                    label: "Género",
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    options: genero,
                    className: "input--style",
                    placeholder: "Género...",
                },
            },
            {   
                // styleContainer: { zIndex: 2 },
                label: "Estado civil",  
                props: {
                    name: "estado_civil",
                    label: "Estado civil",
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    options: estadoCivil,  
                    className: "input--style",
                    placeholder: "Estado civil...", 
                },
            },
        ],
        [
            {
                label: "Nombre Contacto de emergencia",
                props: {
                    name: "nombre_contacto_emergencia",
                    label: "Nombre Contacto de emergencia",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Nombre Contacto de emergencia...",
                },
            },
            {
                label: "Teléfono Contacto de emergencia",
                props: {
                    name: "telefono_contacto_emergencia",
                    label: "Teléfono Contacto de emergencia",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Teléfono Contacto de emergencia...",
                    validate: phone,
                },
            },
        ],
        [
            {
                
                label: "Nivel Académico",
                props: {
                    name: "nivel_academico",
                    label: "Nivel Académico",
                    component: renderSelectField,
                    select_style,
                    labelKey: "label",
                    valueKey: "value",
                    options: nivelAcademico,
                    className: "input--style",
                    placeholder: "Nivel Académico...",
                },
            },
            {
                label: "Titulo",
                props: {
                    name: "titulacion",
                    label: "Titulo",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "Titulo...",
                },
            }
        ],
        [
            {
                label: "NIT",
                props: {
                    name: "nit",
                    label: "NIT",
                    component: renderField,
                    type: "text",
                    className: "input--style",
                    placeholder: "NIT...",
                },
            },
        ],
        [
            // row
            {
                label: "Habilidades y años de experiencia",
                array: true,
                props: {
                    name: "skills_experiencia",
                    component: CrearSkill,
                    edit: edit,
                    skills,
                    select_style,
                    label: "Skills",
                    skills: skillsColaborador,
                },
            },
        ],
    ];

    return (
        <React.Fragment>
            <form
                name="RecursosCrearEditarForm"
                className="form-validate mb-lg"
                onSubmit={handleSubmit}
            >
                <RenderFields fields={fields} />
                <div className="buttons-box">
                    {edit ? (
                        <button
                            className="btn btn-warning m-1"
                            style={{ fontSize: 15 }}
                            type="button"
                            onClick={() => setPopupOpened(true)}
                        >
                            Despido/Renuncia
                        </button>
                    ) : null}
                    {!fromModalCreate ? (
                        <Link
                            to="/recursos"
                            className="btn btn-secondary"
                            style={{ fontSize: 15 }}
                        >
                            Cancelar
                        </Link>
                    ) : null}

                    <button
                        type="submit"
                        style={{ fontSize: 15 }}
                        className="btn btn-primary m-1 align-self-center"
                    >
                        Siguiente
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

const form = reduxForm({
    form: "RecursosCrearEditarForm", // a unique identifier for this  form
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validateFields,
})(DatosBasicosForm);

export default form;
