import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Field } from "redux-form";
import { renderSelectFieldText } from "../../Utils/renderField";
import Swal from "sweetalert2";
import "./tablaSkills.css";

const CrearSkill = (props) => {
    const { skills, fields, meta: { error } } = props;

    const swalError = (msg) => {
        Swal.fire({
            title: "¡Atención!",
            text: msg,
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "Aceptar",
        });
    };

    // Transforma el array de skills en un array de opciones para el Select
    const opcionesFases = skills ? skills.map((fase) => ({
        value: fase.id,
        label: fase.label,
    })) : [];

    // Variables
    const [update_recurso, setUpdateRecurso] = useState(false);
    const [selectedFase, setSelectedFase] = useState(null);
    const [experience, setExperience] = useState("");
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        if (props.edit_recurso && !update_recurso) {
            setUpdateRecurso(true);
        }
    }, [props.edit_recurso, update_recurso]);

    // Funciones
    const cancelEdit = () => {
        setSelectedFase(null);
        setExperience("");
        setEditIndex(null);
    };

    const addSkill = () => {
        if (selectedFase && experience) {
            if (editIndex !== null) {
                fields.remove(editIndex);
                fields.insert(editIndex, { skill: selectedFase.value, experiencia: experience });
                setEditIndex(null);
            } else {
                fields.push({ skill: selectedFase.value, experiencia: experience });
            }
            setSelectedFase(null); // Resetea la selección después de agregar
            setExperience(""); // Resetea la experiencia después de agregar
        } else {
            swalError("Por favor, seleccione una habilidad y proporcione la experiencia.");
        }
    };

    const editSkill = (index) => {
        const skillToEdit = fields.get(index);
        setSelectedFase(opcionesFases.find(option => option.value === skillToEdit.skill));
        setExperience(skillToEdit.experiencia);
        setEditIndex(index);
    };

    // Detectar habilidades duplicadas
    const fieldArray = Object.values(fields);


    // Función para detectar habilidades duplicadas
    const isDuplicateSkill = (index) => {
        const allSkills = fields.getAll();
        const skillData = allSkills[index];
        return allSkills.some((otherSkill, otherIndex) => (
            otherIndex !== index && skillData.skill === otherSkill.skill
        ));
    };

    return (
        <div>
            <div className="row mt-12 mb-12">
                <div className="form-group has-feedback col-4">
                    <label htmlFor="puesto">Skills</label>
                    <Select
                        styles={props.select_style}
                        name="skill"
                        className="input--style"
                        isClearable={true}
                        isSearchable={true}
                        options={opcionesFases}
                        onChange={setSelectedFase}
                        value={selectedFase}
                    />
                </div>
                <div className="form-group has-feedback col-2">
                    <label htmlFor="puesto">Años</label>
                    <input
                        name="experiencia"
                        className="form-control input--style"
                        type="text"
                        placeholder="0"
                        onChange={(e) => {
                            const value = e.target.value.replace(/\D/g, ''); // Eliminar todo lo que no sea dígito
                            setExperience(value);
                        }}
                        value={experience}
                    />
                </div>
                <div
                    className="form-group has-feedback col"
                    style={{ display: "flex", alignItems: "flex-end" }}
                >
                    {editIndex !== null ? (
                        <button
                            onClick={cancelEdit}
                            style={{ fontSize: 15, marginRight: "5px" }}
                            className="btn btn-danger"
                            type="button"
                        >
                            Cancelar
                        </button>
                    ) : null}
                    <button
                        onClick={addSkill}
                        style={{ fontSize: 15 }}
                        className={`btn ${
                            editIndex !== null ? "btn-info" : "add--button"
                        }`}
                        type="button"
                    >
                        {editIndex !== null ? "Actualizar" : "Agregar"}
                    </button>
                </div>
            </div>
            {error && (
                <span className="text-danger">{error}</span>
            )}
            {fields.length > 0 && (
                <table className="table-skills">
                    <thead className="table-thead">
                        <tr>
                            <th style={{ width: '60%' }} scope="col">Skills</th>
                            <th style={{ width: '20%' }} scope="col">Años</th>
                            <th style={{ width: '20%' }} scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((skill, index) => (
                            <tr key={index} className={isDuplicateSkill(index) ? 'duplicate-skill' : 'normal-skill'}>
                                <td>
                                    <Field
                                        name={`${skill}.skill`}
                                        select_style={props.select_style}
                                        component={renderSelectFieldText}
                                        label={`Skill #${index + 1}`}
                                        options={skills}
                                        labelKey="label"
                                        valueKey="value"
                                        className="form-group"
                                        placeholder="Skill..."
                                        hideSelectedOptions={true}
                                    />
                                </td>
                                <td>
                                    <Field
                                        name={`${skill}.experiencia`}
                                        component={({ input }) => <span>{input.value}</span>}
                                        className="form-control"
                                    />
                                </td>
                                <td>
                                    <button
                                        className="btn btn-danger p-0 ml-2"
                                        type="button"
                                        onClick={() => fields.remove(index)}
                                    >
                                        <i className="material-icons">delete</i>
                                    </button>
                                    <button
                                        className="btn btn-info p-0 ml-2"
                                        type="button"
                                        onClick={() => editSkill(index)}
                                    >
                                        <i className="material-icons">edit</i>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

        </div>
    );
};

export default CrearSkill;
