// src/redux/modules/tipos_proyecto.js

import { handleActions } from 'redux-actions';
import { push } from "react-router-redux";
import { initialize as initializeForm } from 'redux-form';
import Swal from 'sweetalert2';
import { api } from "api";


// --------------------------------------------------
// Constantes de Acción
// --------------------------------------------------
const TIPO_LOADER = 'tipos_proyecto/TIPO_LOADER';
const TIPO_DATA = 'tipos_proyecto/TIPO_DATA';
const TIPO_FILTER = 'tipos_proyecto/TIPO_FILTER';

const CATEGORIA_LOADER = 'tipos_proyecto/CATEGORIA_LOADER';
const CATEGORIA_DATA = 'tipos_proyecto/CATEGORIA_DATA';
const CATEGORIA_ERROR = 'tipos_proyecto/CATEGORIA_ERROR';

const SUBCATEGORIA_LOADER = 'tipos_proyecto/SUBCATEGORIA_LOADER';
const SUBCATEGORIA_DATA = 'tipos_proyecto/SUBCATEGORIA_DATA';
const SUBCATEGORIA_ERROR = 'tipos_proyecto/SUBCATEGORIA_ERROR';

const SET_FILTERED_TIPOS = 'SET_FILTERED_TIPOS';
const SET_UNIDADES_NEGOCIO = 'tipos_proyecto/SET_UNIDADES_NEGOCIO';


// --------------------------------------------------
// Actions Creators
// --------------------------------------------------
export const setFilteredTipos = (data) => ({
    type: SET_FILTERED_TIPOS,
    payload: data,
});

export const setTipoLoader = loader => ({
    type: TIPO_LOADER,
    loader,
});

export const setTipoData = data => ({
    type: TIPO_DATA,
    data
});

export const setTipoFilter = filter => ({
    type: TIPO_FILTER,
    filter
});

export const setCategoriaLoader = loader => ({
    type: CATEGORIA_LOADER,
    loader,
});

export const setCategoriaData = data => ({
    type: CATEGORIA_DATA,
    data
});

export const setCategoriaError = error => ({
    type: CATEGORIA_ERROR,
    error
});

export const setSubCategoriaLoader = loader => ({
    type: SUBCATEGORIA_LOADER,
    loader,
});

export const setSubCategoriaData = data => ({
    type: SUBCATEGORIA_DATA,
    data
});

export const setSubCategoriaError = error => ({
    type: SUBCATEGORIA_ERROR,
    error
});

export const setUnidadesNegocio = (data) => ({
    type: SET_UNIDADES_NEGOCIO,
    payload: data,
});


// --------------------------------------------------
// Thunks / Funciones asíncronas
// --------------------------------------------------

// -- CRUD Tipo de Proyecto --
export const onSubmitTipo = (data = {}) => (dispatch) => {
    dispatch(setTipoLoader(true));
    api.post(`tipo_proyecto`, data)
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Se ha guardado correctamente',
                icon: 'success',
            });
            dispatch(push("/tipos-proyecto"));
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un error, inténtelo nuevamente.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setTipoLoader(false));
        });
};

export const listarTiposProyecto = (page = 1, filters = {}, pageSize = 10) => (dispatch) => {
    dispatch(setTipoLoader(true));

    const params = {
        page,
        page_size: pageSize,
        ...filters,
    };

    return api.get('tipo_proyecto', { params })
      .then((response) => {
          dispatch(setTipoData(response));
      })
      .catch(() => {
          Swal.fire({
              title: 'Error',
              text: 'Error al cargar los Tipos de Proyecto.',
              icon: 'error',
          });
      })
      .finally(() => {
          dispatch(setTipoLoader(false));
      });
};



export const obtenerTipo = (id = null) => (dispatch) => {
    dispatch(setTipoLoader(true));
    api.get(`tipo_proyecto/${id}`)
        .then((response) => {
            dispatch(initializeForm('CrearEditarForm', response));
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al cargar el Tipo de Proyecto.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setTipoLoader(false));
        });
};

export const actualizarTipo = (data = {}, id = null) => (dispatch) => {
    dispatch(setTipoLoader(true));
    api.put(`tipo_proyecto/${id}`, data)
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Se ha actualizado correctamente',
                icon: 'success',
            });
            dispatch(push("/tipos-proyecto"));
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Ha ocurrido un error, inténtelo nuevamente.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setTipoLoader(false));
        });
};

export const actualizarOrdenTipos = (tiposOrdenados) => (dispatch) => {
    dispatch(setTipoLoader(true));
    return api.post('tipo_proyecto/update_order', { tipos: tiposOrdenados })
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Orden actualizado correctamente',
                icon: 'success',
            });
            dispatch(listarTiposProyecto());
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'No se pudo actualizar el orden',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setTipoLoader(false));
        });
};
export const eliminarTipo = (id) => (dispatch) => {
    Swal.fire({
        title: '¿Eliminar?',
        text: 'Esta acción no se puede revertir.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
    }).then((res) => {
        if (res.value) {
            dispatch(setTipoLoader(true));
            api.eliminar(`tipo_proyecto/${id}`)
              .then(() => {
                  Swal.fire('Éxito', 'Tipo de Proyecto eliminado', 'success');
                  dispatch(listarTiposProyecto());
              })
              .catch(() => {
                  Swal.fire('Error', 'No se pudo eliminar el Tipo', 'error');
              })
              .finally(() => {
                  dispatch(setTipoLoader(false));
              });
        }
    });
};

// -- Categorías --
export const obtenerCategoria = (id = null) => (dispatch) => {
    dispatch(setTipoLoader(true));
    api.get(`categoria/${id}`)
        .then((response) => {
            dispatch(initializeForm('CrearEditarCategoriaForm', response));
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al cargar la Categoría.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setTipoLoader(false));
        });
};

export const fetchCategorias = (tipoProyectoId) => (dispatch) => {
    dispatch(setCategoriaLoader(true));
    api.get(`categoria`, { params: { tipo_proyecto: tipoProyectoId } })
        .then(response => {
            dispatch(setCategoriaData(response.data.results));
        })
        .catch((error) => {
            dispatch(setCategoriaError(error));
            Swal.fire({
                title: 'Error',
                text: 'Error al cargar las Categorías.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setCategoriaLoader(false));
        });
};

export const crearCategoria = (data) => (dispatch) => {
    dispatch(setCategoriaLoader(true));
    return api.post(`categoria`, data)
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Categoría creada correctamente.',
                icon: 'success',
            });
            dispatch(listarTiposProyecto());
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al crear la Categoría.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setCategoriaLoader(false));
        });
};

export const actualizarCategoria = (id, data) => (dispatch) => {
    dispatch(setCategoriaLoader(true));
    return api.put(`categoria/${id}`, data)
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Categoría actualizada correctamente.',
                icon: 'success',
            });
            dispatch(listarTiposProyecto());
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al actualizar la Categoría.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setCategoriaLoader(false));
        });
};

export const eliminarCategoria = (id) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro?',
        text: 'No podrá revertir esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.value) {
            dispatch(setCategoriaLoader(true));
            api.eliminar(`categoria/${id}`)
                .then(() => {
                    Swal.fire({
                        title: 'Éxito',
                        text: 'Se ha eliminado correctamente',
                        icon: 'success',
                    });
                    dispatch(listarTiposProyecto());
                })
                .catch(() => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al eliminar la Categoría.',
                        icon: 'error',
                    });
                })
                .finally(() => {
                    dispatch(setCategoriaLoader(false));
                });
        }
    });
};

// -- Subcategorías --
export const obtenerSubcategoria = (id = null) => (dispatch) => {
    dispatch(setTipoLoader(true));
    api.get(`subcategoria/${id}`)
        .then((response) => {
            dispatch(initializeForm('CrearEditarSubCategoriaForm', response));
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al cargar la Subcategoría.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setTipoLoader(false));
        });
};

export const fetchSubCategorias = (categoriaId) => (dispatch) => {
    dispatch(setSubCategoriaLoader(true));
    api.get(`subcategoria`, { params: { categoria: categoriaId } })
        .then(response => {
            dispatch(setSubCategoriaData({ categoriaId, subcategorias: response.data.results }));
        })
        .catch((error) => {
            dispatch(setSubCategoriaError(error));
            Swal.fire({
                title: 'Error',
                text: 'Error al cargar las Subcategorías.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setSubCategoriaLoader(false));
        });
};

export const crearSubCategoria = (data) => (dispatch) => {
    dispatch(setSubCategoriaLoader(true));
    return api.post(`subcategoria`, data)
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Subcategoría creada correctamente.',
                icon: 'success',
            });
            dispatch(listarTiposProyecto());
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al crear la Subcategoría.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setSubCategoriaLoader(false));
        });
};

export const actualizarSubCategoria = (id, data) => (dispatch) => {
    dispatch(setSubCategoriaLoader(true));
    return api.put(`subcategoria/${id}`, data)
        .then(() => {
            Swal.fire({
                title: 'Éxito',
                text: 'Subcategoría actualizada correctamente.',
                icon: 'success',
            });
            dispatch(listarTiposProyecto());
        })
        .catch(() => {
            Swal.fire({
                title: 'Error',
                text: 'Error al actualizar la Subcategoría.',
                icon: 'error',
            });
        })
        .finally(() => {
            dispatch(setSubCategoriaLoader(false));
        });
};

export const eliminarSubCategoria = (id) => (dispatch) => {
    Swal.fire({
        title: '¿Está seguro?',
        text: 'No podrá revertir esta acción',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.value) {
            dispatch(setSubCategoriaLoader(true));
            api.eliminar(`subcategoria/${id}`)
                .then(() => {
                    Swal.fire({
                        title: 'Éxito',
                        text: 'Se ha eliminado correctamente',
                        icon: 'success',
                    });
                    dispatch(listarTiposProyecto());
                })
                .catch(() => {
                    Swal.fire({
                        title: 'Error',
                        text: 'Error al eliminar la Subcategoría.',
                        icon: 'error',
                    });
                })
                .finally(() => {
                    dispatch(setSubCategoriaLoader(false));
                });
        }
    });
};


// --------------------------------------------------
// 1) FUNCIÓN ORIGINAL: filterList (mapea a "filter")
// --------------------------------------------------
export const filterList = () => async (dispatch) => {
    dispatch(setTipoLoader(true));
    try {
        const response = await api.get("tipo_proyecto", { params: { activo: true } });
        // Suponemos que la respuesta viene como { results: [...] }
        const tiposFormat = response.results.map(tipo => ({
            value: tipo.id,
            label: tipo.nombre,
        }));

        // Mantienes el funcionamiento original
        dispatch(setFilteredTipos(tiposFormat));
    } catch (err) {
        dispatch(setFilteredTipos([]));
    } finally {
        dispatch(setTipoLoader(false));
    }
};


// --------------------------------------------------
// 2) NUEVA FUNCIÓN: fetchUnidadesNegocio (mapea a "unidades_negocio")
// --------------------------------------------------
export const fetchUnidadesNegocio = () => async (dispatch) => {
    dispatch(setTipoLoader(true));
    try {
        const response = await api.get("tipo_proyecto", { params: { activo: true } });
        const { results } = response;

        // Mapeamos la estructura anidada
        const unidadesNegocio = results.map((unidad) => {
            const mappedCategorias = (unidad.categorias || []).map((cat) => {
                const mappedSubcategorias = (cat.subcategorias || []).map((sub) => ({
                    value: sub.id,
                    label: sub.nombre,
                    prefijo: sub.prefijo,     // si viene del backend
                    hasChildren: false,       // nivel final
                }));

                return {
                    value: cat.id,
                    label: cat.nombre,
                    prefijo: cat.prefijo,     // si viene del backend
                    hasChildren: mappedSubcategorias.length > 0,
                    subcategorias: mappedSubcategorias,
                };
            });

            return {
                value: unidad.id,
                label: unidad.nombre,
                prefijo: unidad.prefijo,  // si viene del backend
                hasChildren: mappedCategorias.length > 0,
                categorias: mappedCategorias,
            };
        });

        dispatch(setUnidadesNegocio(unidadesNegocio));
    } catch (err) {
        dispatch(setUnidadesNegocio([]));
    } finally {
        dispatch(setTipoLoader(false));
    }
};


// --------------------------------------------------
// Estado inicial
// --------------------------------------------------
export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
        next: null,
        previous: null
    },
    // Se mantiene "filter" para la lista simple
    filter: [],

    // Nuevo array para las unidades de negocio con categorías y subcategorías
    unidades_negocio: [],

    categoriaLoader: false,
    categorias: [],
    categoriaError: null,

    subCategoriaLoader: false,
    subcategorias: {},
    subCategoriaError: null,
};


// --------------------------------------------------
// Reducer
// --------------------------------------------------
export const reducer = handleActions(
    {
        [TIPO_LOADER]: (state, action) => ({
            ...state,
            loader: action.loader,
        }),

        [TIPO_DATA]: (state, action) => ({
            ...state,
            data: action.data,
        }),

        [TIPO_FILTER]: (state, action) => ({
            ...state,
            filter: action.filter,
        }),

        [CATEGORIA_LOADER]: (state, action) => ({
            ...state,
            categoriaLoader: action.loader,
        }),
        [CATEGORIA_DATA]: (state, action) => ({
            ...state,
            categorias: action.data,
        }),
        [CATEGORIA_ERROR]: (state, action) => ({
            ...state,
            categoriaError: action.error,
        }),

        [SUBCATEGORIA_LOADER]: (state, action) => ({
            ...state,
            subCategoriaLoader: action.loader,
        }),
        [SUBCATEGORIA_DATA]: (state, action) => ({
            ...state,
            subcategorias: {
                ...state.subcategorias,
                [action.data.categoriaId]: action.data.subcategorias,
            },
        }),
        [SUBCATEGORIA_ERROR]: (state, action) => ({
            ...state,
            subCategoriaError: action.error,
        }),

        // Mantiene la lista simple de tipos
        [SET_FILTERED_TIPOS]: (state, action) => ({
            ...state,
            filter: action.payload,
        }),

        // NUEVO: setear la estructura anidada en "unidades_negocio"
        [SET_UNIDADES_NEGOCIO]: (state, action) => ({
            ...state,
            unidades_negocio: action.payload,
        }),
    },
    initialState
);

export default reducer;


// --------------------------------------------------
// Exportar todas las acciones
// --------------------------------------------------
export const actions = {
    // Tipos de proyecto
    onSubmitTipo,
    listarTiposProyecto,
    obtenerTipo,
    actualizarTipo,
    actualizarOrdenTipos,
    eliminarTipo,

    // Categorías
    obtenerCategoria,
    fetchCategorias,
    crearCategoria,
    actualizarCategoria,
    eliminarCategoria,

    // Subcategorías
    obtenerSubcategoria,
    fetchSubCategorias,
    crearSubCategoria,
    actualizarSubCategoria,
    eliminarSubCategoria,

    // Filtrado original
    filterList,

    // Nueva función para obtener la estructura anidada
    fetchUnidadesNegocio,
};
