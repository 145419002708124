import React from 'react';
import { api } from "api";
import moment from 'moment';

class BSTable extends React.Component {

    state = {
        bitacora: [],
    };

    async fetchBitacora(idProyecto) {
        try {
            const bitacora = await api.get(`bitacora_detalle_proyecto/filter_list`);
            const bitacoraProyecto = idProyecto ? bitacora.filter((registro) => registro.proyecto === idProyecto) : [];
            const bitacoraConUsuario = await Promise.all(bitacoraProyecto.map(async (registro) => {
                const usuarioResponse = await api.get(`user/${registro.usuario}`);
                const usuario = usuarioResponse.first_name + " " + usuarioResponse.last_name;
                return {
                    ...registro,
                    usuario,
                };
            }));
            this.setState({ bitacora: bitacoraConUsuario });
        } catch (error) {
            console.error("Error fetching bitacora:", error);
        }
    }

    componentDidMount() {
        const idProyecto = this.props.data.id;
        this.fetchBitacora(idProyecto);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.id !== this.props.data.id) {
            const idProyecto = this.props.data.id;
            this.fetchBitacora(idProyecto);
        }
    }

    render() {
        return (
            <div className="project-details">
                <h3>Bitácora </h3>
                <table className="table">
                <thead>
                <tr>
                    <th>Fecha</th><th>Observación</th><th>Usuario</th>
                </tr>
            </thead>
                    <tbody>
                        {this.state.bitacora.map((detalle, index) => (
                            <tr key={index}>
                                
                                <td>{moment(detalle.fecha_inicio).format('DD/MM/YYYY')}</td>
                                <td>
                                    <textarea
                                    id="observacion"
                                    className="form-control"
                                    value={detalle.observacion}
                                    rows="1" 
                                    disabled =  {true}
                                    ></textarea>
                                </td>
                                <td>{detalle.usuario}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default BSTable;
