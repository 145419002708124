import React from 'react';
import Select, { Creatable, Async } from 'react-select';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import Switch from 'react-switch';
import DayPicker from '../DayPicker';
import FileUploader from '../FileUploader/FileUploader';
import DatePicker from "react-date-picker";
import _ from "lodash";


export const renderField = ({
                                input, placeholder, className, type, meta: { touched, error },
                                onKeyPress = () => {}, min, max, readonly, disabled
                            }) => {
    const invalid = touched && error;
    const classN = className ? 'form-control ' + className : 'form-control';
    return (
        <div>
            <input
                {...input}
                placeholder={placeholder}
                type={type}
                className={classNames(classN, { 'is-invalid': invalid })}
                onKeyPress={onKeyPress}
                style={{ color: "black", fontSize: 15, }}
                readOnly={readonly}
                disabled={disabled}
                min={min}
                max={max}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderTextArea = ({
                                   input, placeholder, rows, meta: { touched, error },
                                   disabled = false
                               }) => {
    const invalid = touched && error;
    return (
        <div>
      <textarea
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          style={{ resize: 'none' }}
          rows={rows || 3}
          className={classNames('form-control', { 'is-invalid': invalid })}
      />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderTextAreaWithStyle = ({
                                   input, placeholder, className, rows, meta: { touched, error }, disabled = false,
                               }) => {
    const invalid = touched && error;
    const classN = className ? 'form-control ' + className : 'form-control';
    return (
        <div>
      <textarea
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          style={{ resize: 'none' }}
          rows={rows || 3}
          className={classNames(classN, { 'is-invalid': invalid })}
      />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};
export const renderNumber = ({
                                 input, className, decimalScale, placeholder, meta: { touched, error }, prefix="", suffix="", numberFormat,
                                 readOnly = false,
                             }) => {
    const invalid = touched && error;
    const classN = className ? 'form-control ' + className : 'form-control';
    return (
        <div>
            <NumberFormat
                placeholder={placeholder}
                className={classNames(classN, { 'is-invalid': invalid })}
                decimalScale={decimalScale || 0}
                format={numberFormat}
                fixedDecimalScale
                value={input.value}
                thousandSeparator
                prefix={prefix}
                suffix={suffix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                readOnly={readOnly}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderCurrency = ({
                                   input, meta: { touched, error }, prefix="Q ", placeholder, className, readOnly=false, disabled = false,
                               }) => {
    const invalid = touched && error;
    const classN = className ? 'form-control ' + className : 'form-control';
    return (
        <div>
            <NumberFormat
                className={classNames(classN, { 'is-invalid': invalid })}
                decimalScale={2}
                fixedDecimalScale
                placeholder={placeholder}
                value={input.value}
                thousandSeparator
                prefix={prefix}
                onValueChange={(values) => {
                    input.onChange(values.value);
                }}
                readOnly={readOnly}
                disabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderSwitch = ({
                                 input, meta: { touched, error }, label, disabled,
                             }) => {
    const invalid = touched && error;
    return (
        <div className="d-flex align-items-center">
            <Switch
                onColor="#007bff"
                height={18}
                width={36}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                }}
                checked={input.value ? input.value : false}
                // id="normal-switch"
            />
            &nbsp;{label}
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderFieldCheck = ({ input, label, value, disabled, type, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="checkbox c-checkbox">
                <label className="needsclick">
                    <input
                        type="checkbox"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span className="fa fa-check" />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderFieldRadio = ({ input, label, value, disabled, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <React.Fragment>
            <div className="radio c-radio c-radio-nofont d-flex">
                <label className="negro font-weight-normal">
                    <input
                        type="radio"
                        disabled={disabled}
                        {...input}
                        className={classNames('', { 'is-invalid': invalid })}
                    />
                    <span />
                    &nbsp;{label}
                </label>
            </div>
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const renderSelectField = (
    {
        input,
        disabled,
        isClearable,
        isMulti,
        isSearchable,
        options,
        extra_change,
        extraChange,
        select_style = null,
        placeholder,
        labelKey="label",
        valueKey="value",
        objeto,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });
    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, {value});
    }
    return (
        <React.Fragment>
            <Select
                styles={select_style}
                isClearable={isClearable}
                className={classNames({ 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isMulti={isMulti}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    if(extra_change) {
                        objeto ? extraChange(e) : extraChange(e[valueKey])
                    }
                    input.onChange(e ? e[valueKey] : null);
                }}
                value={value || ''}
                isDisabled={disabled}
                menuPlacement="auto"
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};


export const AsyncSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        loadOptions,
        placeholder,
        meta: { touched, error }
    }) => {

    const invalid = touched && error;

    return (
        <React.Fragment>
            <Async
                isClearable={isClearable}
                cacheOptions
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                defaultOptions
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const CreatableSelectField = (
    {
        input,
        disabled,
        isClearable,
        isSearchable,
        options,
        placeholder,
        labelKey="label",
        valueKey="value",
        meta: { touched, error }
    }) => {

    const invalid = touched && error;
    const _options = [];
    options.forEach(option => {
        _options.push({...option, label: option[labelKey], value: option[valueKey]});
    });

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames('react-select-container', { 'is-invalid': invalid })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => { input.onChange(e ? e : null); }}
                value={input.value}
                isDisabled={disabled}
            />
            {invalid && (
                <div className="invalid-feedback">
                    {error}
                </div>
            )}
        </React.Fragment>
    )
};

export const renderSelectFieldText = ({
    input,
    options,
    labelKey = "label",
    valueKey = "value"
  }) => {
    const value = input.value;
    const selectedOption = options.find(option => option[valueKey] === value);
    const labelText = selectedOption ? selectedOption[labelKey] : "";
    return <span>{labelText}</span>;
  };

/* Creatable handle create */

export const RenderCreatable = ({
    input,
    isClearable,
    isSearchable,
    isDisabled = false,
    options,
    placeholder,
    labelKey = "label",
    valueKey = "value",
    isLoading = false,
    select_style,
    handleCreate = () => {},
    meta: { touched, error },
}) => {
    const invalid = touched && error;
    const _options = [];
    options.forEach((option) => {
        _options.push({
            ...option,
            label: option[labelKey],
            value: option[valueKey],
        });
    });

    let value = input.value;
    if (value !== null && value !== undefined) {
        value = _.find(_options, { value });
    }

    return (
        <React.Fragment>
            <Creatable
                isClearable={isClearable}
                className={classNames("react-select-container", {
                    "is-invalid": invalid,
                })}
                backspaceRemovesValue={false}
                isSearchable={isSearchable}
                options={_options}
                placeholder={placeholder}
                onChange={(e) => {
                    input.onChange(e ? e[valueKey] : null);
                }}
                onCreateOption={handleCreate}
                value={value || ""}
                menuPlacement="auto"
                isDisabled={isLoading || isDisabled}
                isLoading={isLoading}
                styles={select_style}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </React.Fragment>
    );
};


/**
 * @param photo: este parametro se usa para tener la imagen previa de una imagen en dado caso el formulario es
 * usado para una actualizacion, se espera que sea la ruta donde se encuentra la imagen
 * @param setFile
 * @param className
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * */
export const renderFilePicker = ({photo, setFile, className, disabled, input, meta: { touched, error }, types = [] }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <FileUploader
                types={types}
                disabled={disabled}
                img= {!!photo ? photo : null}
                onFileChange={(e, file) => {
                    file = file || e.target.files[0];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        input.onChange(reader.result);
                        if (!!setFile) {
                            setFile(file);
                        }
                    };
                    reader.readAsDataURL(file);
                }} />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDayPicker = ({className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    const classN = className ? 'form-control ' + className : 'form-control';
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DayPicker
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                onChange={e => input.onChange(e)}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};

export const renderDatePicker = ({className, disabled, maxDate, minDate, input, meta: { touched, error } }) => {
    const invalid = touched && error;
    return (
        <div className={classNames(`${className}`, { 'is-invalid': invalid })}>
            <DatePicker
                onChange={e => input.onChange(e)}
                disabled={disabled}
                maxDate={maxDate}
                minDate={minDate}
                value={input.value}
            />
            {invalid && <div className="invalid-feedback">
                {error}
            </div>}
        </div>
    )
};


export const renderSelectUnidadesNegocio = ({
    input,
    disabled = false,
    options = [],
    placeholder = "Seleccionar...",
    meta: { touched, error },
    // Propiedad extra para manejar cambios a nivel local
    onChangeSelect,
  }) => {
    const invalid = touched && error;
    // Si input.value es el objeto completo, se asigna directamente.
    const selectedOption = input.value && input.value.value ? input.value : null;
  
    const handleChange = (option) => {
      if (onChangeSelect) {
        onChangeSelect(option); // Ejecuta la función extra (por ejemplo, para actualizar estados locales)
      }
      input.onChange(option || null);
    };
  
    return (
      <div>
        <Select
          className={classNames({ "is-invalid": invalid })}
          isDisabled={disabled}
          options={options}
          placeholder={placeholder}
          onChange={handleChange}
          value={selectedOption}
          isClearable
        />
        {invalid && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  };

  export const renderSelectFieldObject = ({
    input,
    disabled = false,
    isClearable = true, // Valor por defecto para permitir limpiar
    isMulti = false,
    isSearchable = true,
    options = [],
    placeholder = "Seleccionar...",
    labelKey = "label",
    valueKey = "value",
    onChangeSelect, // Función extra para comunicar el cambio al componente padre
    select_style = null,
    meta: { touched, error },
  }) => {
    const invalid = touched && error;
    // Construir las opciones asegurando que cada opción tenga las propiedades label y value correctas
    const _options = options.map(function (option) {
      return Object.assign({}, option, {
        label: option[labelKey],
        value: option[valueKey],
      });
    });
  
    let value = input.value;
    // Si ya se guardó un objeto en redux-form se utiliza; si es un valor primitivo, se busca el objeto correspondiente.
    if (value && typeof value !== "object") {
      value = _.find(_options, { [valueKey]: value });
    }
  
    return (
      <React.Fragment>
        <Select
          styles={select_style}
          isClearable={isClearable}
          className={classNames({ "is-invalid": invalid })}
          isMulti={isMulti}
          isSearchable={isSearchable}
          options={_options}
          placeholder={placeholder}
          onChange={(option) => {
            if (onChangeSelect) {
              onChangeSelect(option);
            }
            // Se almacena el objeto completo en redux-form o null si se borra la selección
            input.onChange(option || null);
          }}
          value={value || ""}
          isDisabled={disabled}
          menuPlacement="auto"
        />
        {invalid && <div className="invalid-feedback">{error}</div>}
      </React.Fragment>
    );
  };
  
export const RenderField = {
    renderField,
    renderTextArea,
    renderTextAreaWithStyle,
    renderNumber,
    renderCurrency,
    renderSwitch,
    renderFieldCheck,
    renderFieldRadio,
    renderSelectField,
    renderDatePicker,
    renderDayPicker,
    renderSelectFieldText,
    renderSelectUnidadesNegocio,
    renderSelectFieldObject
};
