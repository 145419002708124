import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import usersIcon from "../../../../../assets/img/icon _users.svg";
import noPhothoImage from "../../../../../assets/img/avatar-placeholder.png";

export const ColaboradoresCard = ({
    colaboradores,
    title,
    children,
    onRowClick,
    show = true,
}) => {
    return (
        <div
            className="card mb-3 mt-3 card--reportes mx-auto"
            style={{
                display: show ? "block" : "none",
            }}
        >
            <div className="header-info">
                <h6 className="font-weight-bold m-0">{title}</h6>
            </div>
            <div className="card-body px-4">
                <BootstrapTable
                    tableContainerClass="content-table-permisos"
                    tableBodyClass="table--body"
                    tableHeaderClass="table--header"
                    data={colaboradores}
                    options={{
                        noDataText: "No hay datos",
                        onRowClick: onRowClick, // función de devolución de llamada al hacer clic en la fila
                    }}
                    tableStyle={{
                        border: "hidden",
                    }}
                >
                    <TableHeaderColumn
                        dataField="id"
                        dataFormat={(cell, row, enumObject, index) => (
                            <span className="pl-5">{index + 1}</span>
                        )}
                        width="10%"
                    >
                        <span className="pl-4">#</span>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width="10%"
                        dataField="foto_perfil"
                        dataFormat={(cell) => {
                            const photo = cell ? cell : noPhothoImage;
                            return (
                                <picture className="d-flex align-items-center justify-content-start">
                                    <img
                                        src={photo}
                                        width="50px"
                                        height="50px"
                                        style={{
                                            borderRadius: "50%",
                                            border: "none",
                                        }}
                                        alt="foto de perfil"
                                    />
                                </picture>
                            );
                        }}
                        thStyle={{ textAlign: "left" }}
                    >
                        <picture className="d-flex align-items-center justify-content-start">
                            <img src={usersIcon} />
                        </picture>
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="nombre"
                        isKey
                        dataFormat={(cell, row) => {
                            return (
                                <div
                                    className="m-0"
                                    style={{ cursor: "pointer" }}
                                >
                                    <span>{cell}</span>
                                    <p style={{ fontSize: ".9rem" }}>{row.puesto}</p>
                                </div>
                            );
                        }}
                        thStyle={{ textAlign: "left", padding: "10px" }}
                    >
                        Nombre
                    </TableHeaderColumn>
                    {children}
                </BootstrapTable>
            </div>
        </div>
    );
};
