import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";
import { api } from "api";
import "../../../../../style/custom.css";
import Swal from "sweetalert2";

class CrearEditar extends Component {
  state = {
    recursos: [],
    clientes: [],
    puestos: [],
    fases: [],
    edit: false,
    loader: true,
    tags: [],
    id: null,
    recursos_submit: [],
    edit_type: null,
    planificacion: [],
    paises: [],
    recursos_encargados: [],
  };

  async componentDidMount() {
    try {
      const paises = await api.get("pais/filter_list");
      this.setState({ paises });
    } catch (error) {
      console.error(error);
    }

    try {
      const recursos_encargados = await api.get("recurso/encargados_proyectos");
      this.setState({ recursos_encargados });
    } catch (error) {
      console.error(error);
    }

    this.props.getRole();
    this.props.obtenerTipos();
    this.props.getUnidadesNegocio();

    const edit = !!this.props.match.params.id;
    this.setState({ edit });

    try {
      const [recursos, puestos, clientes, fases] = await Promise.all([
        api.get("recurso/all", { activo: true, despedido: false, renuncia: false }),
        api.get("puesto/filter_list"),
        api.get("cliente/filter_list"),
        api.get("fase_proyecto/filter_list"),
      ]);
      this.setState({ recursos, puestos, clientes, fases, loader: false });
      if (edit) {
        this.setState({
          edit_type: this.props.match.params.tipo,
          id: this.props.match.params.id,
        });
        this.props.obtenerProyecto(this.props.match.params.id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formData !== this.props.formData) {
      const tags = this.props.formData.tags;
      this.setState({ tags: tags || [] });
    }
  }

  onSubmit = (data) => {
    const { edit, id } = this.state;
    const { onSubmit, actualizarProyecto } = this.props;
    let recursos = [];
    this.state.recursos_submit.forEach((e) => {
      recursos = recursos.concat(e.recursos);
    });
    data = {
      ...data,
      recursos,
      tags: this.state.tags,
      fases: this.state.planificacion,
      tipo_proyecto: data.tipo_proyecto ? data.tipo_proyecto.value : null,
      subcategoria: data.subcategoria ? data.subcategoria.value : null,
      categoria: data.categoria ? data.categoria.value : null,
    };

    if (edit) {
      actualizarProyecto(data, id);
    } else {
      onSubmit(data);
    }
  };

  setTags = (tags) => {
    this.setState({ tags });
  };

  setRecurosSubmit = (recursos_submit) => {
    this.setState({ recursos_submit });
  };

  setPlanificacion = (planificacion) => {
    this.setState({ planificacion });
  };

  finalizarProyecto = (data = null) => {
    Swal.fire({
      title: "¿Finalizar?",
      text: "¡No podrá revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "¡Sí, finalizar!",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (data) {
          let recursos = [];
          this.state.recursos_submit.forEach((e) => {
            recursos = recursos.concat(e.recursos);
          });
          const data1 = {
            ...data,
            recursos,
            tags: this.state.tags,
            fases: this.state.planificacion,
          };
          this.props.finalizarProyecto1(this.state.id, data1);
        } else {
          this.props.finalizarProyecto(this.state.id);
        }
      }
    });
  };

  activarProyecto = () => {
    Swal.fire({
      title: "¿Activar?",
      text: "¡El proyecto será activado!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "¡Sí, activar!",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.activarProyecto(this.state.id);
      }
    });
  };

  pausarProyecto = () => {
    Swal.fire({
      title: "¿Pausar?",
      text: "¡Está a punto de pausar este proyecto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "¡Sí, pausar!",
      cancelButtonText: "No, cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.pausarProyecto(this.state.id);
      }
    });
  };

  render() {
    const { loader } = this.props;
    const finalizado = this.props.formData.finalizado;

    return (
      <React.Fragment>
        <LoadMask loading={loader || this.state.loader} light>
          <div className="d-flex flex-column align-items-start pt-3">
            <h3 className="title--blue">
              {this.state.edit ? "EDITAR" : "CREAR"} PROYECTO
            </h3>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <CrearEditarForm
                onSubmit={this.onSubmit}
                recursos={this.state.recursos}
                fases={this.state.fases}
                puestos={this.state.puestos}
                clientes={this.state.clientes}
                paises={this.state.paises}
                recursos_encargados={this.state.recursos_encargados}
                tipos_proyecto={this.props.tipos_proyectos}
                change={this.props.change}
                tags={this.state.tags}
                setTags={this.setTags}
                recursos_submit={this.state.recursos_submit}
                setRecurosSubmit={this.setRecurosSubmit}
                recursos_update={this.props.formData.recursos}
                planificacion_update={this.props.formData.fases}
                edit={this.state.edit}
                role={this.props.user.role}
                finalizarProyecto={this.finalizarProyecto}
                finalizado={finalizado}
                edit_type={this.state.edit_type}
                planificacion={this.state.planificacion}
                setPlanificacion={this.setPlanificacion}
                tipo_proyecto={this.props.formData.tipo_proyecto}
                finalizar_data={this.props.formData}
                activarProyecto={this.activarProyecto}
                pausarProyecto={this.pausarProyecto}
              />
            </div>
          </div>
        </LoadMask>
      </React.Fragment>
    );
  }
}

export default CrearEditar;
