import React from "react";
import Select from "react-select";
import DayPicker from "../../Utils/DayPicker";

const FiltersData = ({
    colaboradores,
    tipos_permiso,
    onChangeParams,
    currentParams,
}) => {
    const select_style = {
        control: (styles) => ({
            ...styles,
            color: "#1F4D7B",
            borderColor: "#1F4D7B",
        }),
    };

    return (
        <React.Fragment>
            <div className="col-2">
                <label htmlFor="fecha_inicio" className="text-dark">
                    Fecha inicio
                </label>
                <br />
                <div style={{ display: "flex" }}>
                    <DayPicker
                        onChange={(date) => {
                            onChangeParams({
                                name: "fecha_inicio",
                                value: date,
                            });
                        }}
                        value={currentParams.fecha_inicio || ""}
                    />
                    <button
                        className="btn"
                        style={{ padding: 0 }}
                        onClick={() =>
                            onChangeParams({
                                name: "fecha_inicio",
                                value: "",
                            })
                        }
                    >
                        <i className="material-icons">clear</i>
                    </button>
                </div>
            </div>

            <div className="col-2">
                <label htmlFor="fecha_inicio" className="text-dark">
                    Fecha fin
                </label>
                <br />
                <div style={{ display: "flex" }}>
                    <DayPicker
                        onChange={(date) => {
                            onChangeParams({ name: "fecha_fin", value: date });
                        }}
                        value={currentParams.fecha_fin || ""}
                    />
                    <button
                        className="btn"
                        style={{ padding: 0 }}
                        onClick={() =>
                            onChangeParams({
                                name: "fecha_fin",
                                value: "",
                            })
                        }
                    >
                        <i className="material-icons">clear</i>
                    </button>
                </div>
            </div>

            <div className="col-3">
                <label htmlFor="colaborador" className="text-dark">
                    Colaborador
                </label>
                <br />
                <Select
                    value={colaboradores.find(
                        (colaborador) =>
                            colaborador.value === currentParams.colaborador
                    )}
                    styles={select_style}
                    name="colaborador"
                    placeholder="Colaborador"
                    isSearchable
                    isClearable
                    options={colaboradores}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({
                                name: "colaborador",
                                value: e.value,
                            });
                        } else {
                            onChangeParams({
                                name: "colaborador",
                                value: null,
                            });
                        }
                    }}
                />
            </div>
            <div className="col-3">
                <label htmlFor="tipo_permiso" className="text-dark">
                    Tipo permiso
                </label>
                <br />
                <Select
                    value={tipos_permiso.find(
                        (tipo) =>
                            tipo.value === currentParams.tipo_permiso
                    )}
                    styles={select_style}
                    name="tipo_permiso"
                    placeholder="Tipo permiso"
                    isSearchable
                    isClearable
                    options={tipos_permiso}
                    onChange={(e) => {
                        if (e) {
                            onChangeParams({
                                name: "tipo_permiso",
                                value: e.value,
                            });
                        } else {
                            onChangeParams({
                                name: "tipo_permiso",
                                value: null,
                            });
                        }
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default FiltersData;
