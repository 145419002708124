import moment from "moment";
import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import Select from "react-select";
import '../../Recursos/Listar/Listado.css';
import { api } from "api";

import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class Listar extends Component {
    state = {
        page: 1,
        tags: "",
        search: "",
        BreakLineCenter: {
            whiteSpace: "normal",
            textAlign: "center",
        },
        BreakLine: {
            whiteSpace: "normal",
        },
        AlignCenter: {
            textAlign: "center",
        },
        params: {
            activo: true,
            finalizado: false,
            page_size: "99999",
            viene_de_presupuestos: false,
            encargado__id: null,
        },
        filteredData: [],
        recursos_encargadosFiltrados: [],
        tiposPermitidos: ["Project Based", "Dedicated"],
    };

    async componentDidMount() {
       
        this.props.getTipos();
        this.props.getScrums({activo: true, despedido: false, renuncia: false});
        this.props.getRole();
        this.props.listar(this.state.page, this.state.params);
       
        try{
            const recursos_encargadosFiltrados = await api.get("recurso/encargados_proyectos");
            this.setState({recursos_encargadosFiltrados});
        } catch (error) {
            console.log("Error: ", error);
        }

        
        
    }

    onPageChange = (page) => {
        const params = { ...this.state.params, search: this.state.search };
        if (this.state.search == "") delete params.search;
        this.props.listar(page, params);
        this.setState({ page });
    };


    render() {
        const { data, loader, tipos_proyecto } = this.props;
        const { recursos_encargadosFiltrados, tiposPermitidos } = this.state;

        const opcionesRecursosEncargados = recursos_encargadosFiltrados.map(recurso => ({
            label: recurso.nombre,
            value: recurso.id, 
        }));

        const proyectosFiltrados = tipos_proyecto.filter(item => 
            tiposPermitidos.includes(item.label)
        );
        
        const role = this.props.user.role;
        const select_style = {
            control: (styles) => ({
                ...styles,
                color: "#1F4D7B",
                borderColor: "#1F4D7B",
            }),
        };

        const filteredData = data && data.results ? {
            ...data,
            results: data.results.filter(proyecto =>
                proyecto.tipo_proyecto && tiposPermitidos.includes(proyecto.tipo_proyecto.nombre)
            )
        } : data;


        let multiDataSet = []
        console.log("DATA AQUI: ", data);
        if (filteredData && filteredData.results){
            console.log("DATA AQUI TAMBIEN: ", data);
            if (filteredData.results.length > 0){
                console.log("y AQUI: ", data);
                multiDataSet= [
                    {
                   
                        columns: [
                            {title: "Id", width: {wpx: 50},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } }, 
                            {title: "Proyecto", width: {wpx: 250},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } }, 
                            {title: "Tipo de Proyecto", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } }, 
                            {title: "Encargado", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } }, 
                            {title: "Fecha estimada de finalización", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } }, 
                            {title: "Fase del Proyecto ", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } }, 
                            {title: "Fecha real de finalización", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            {title: "Explicación de atraso", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            {title: "Etapa real del proyecto", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                            {title: "Observaciones", width: {wpx: 200},  style: { font: { bold: true, color: { rgb: 'ffffff' } }, fill: { patternType: "solid", fgColor: { rgb: "2596BE" } } } },
                        ],
                        data: filteredData.results.map((proyecto) => {

                            return [
                                {value: proyecto.id}, 
                                {value: proyecto.nombre}, 
                                {value: proyecto.tipo_proyecto.nombre}, 
                                {value: proyecto.encargado ? proyecto.encargado.nombre : "---"}, 
                                {value: proyecto.fecha_fin ? moment(proyecto.fecha_fin).format("DD/MM/YYYY") : "---"}, 
                                {value: proyecto.fases && proyecto.fases.length > 0 ? 
                                    (proyecto.fases.find(f =>
                                        moment(f.fecha_inicio).isBefore(moment()) && moment(f.fecha_fin).isAfter(moment())
                                    ) || { nombre_fase: "---" }).nombre_fase
                                : "---"},

                                { value: " " }, // Fecha real de finalización sin datos
                                { value: " " }, // Explicación de atraso sin datos
                                { value: " " }, // Etapa real del proyecto sin datos
                                { value: " " }, // Observaciones sin datos
                            ]
                        })
                        
                    }
                ]
            }
        } else {
            console.log("No data to export or data.export is empty");
        }
        

        return (
            <React.Fragment>
                <div className="row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">LISTADO DE PROYECTOS</h2>
                    </div>
                </div>
                <hr />
                
                <div className="row mt-4">
                    <div className="col-12 col-md-3" style={{ display: "flex" }}>
                        <input
                            placeholder="Buscar por nombre..."
                            type="text"
                            className="form-control input--style"
                            value={this.state.search}
                            onKeyDown={(key) => {
                                if (key.key == "Enter") {
                                    const { search, params } = this.state;
                                    this.props.listar(1, { ...params, search });
                                }
                            }}
                            onChange={(tx) => {
                                this.setState({ search: tx.target.value });
                            }}
                            style={{
                                color: "black",
                                fontSize: 15,
                                float: "right",
                            }}
                        />
                    </div>
                    {role == 1 || role == 11 ?
                        <React.Fragment>
                            <div className="col-12 col-md-3">
                                <Select
                                    styles={select_style}
                                    name="encargados"
                                    placeholder="Encargado"
                                    isSearchable={true}
                                    isClearable={true}
                                    options={opcionesRecursosEncargados}
                                    onChange={(e) => {
                                        const { search, params } = this.state;
                                        if(e) {
                                            params.encargado__id = e.value;
                                        } else {
                                            delete params.encargado__id;
                                        }
                                        this.setState({params});
                                        this.props.listar(1, { ...params, search });
                                    }}
                                />
                            </div>
                        </React.Fragment> : null}
                        <div className="col-12 col-md-3">
                            <Select
                                styles={select_style}
                                name="tipos"
                                placeholder="Tipo"
                                isSearchable={true}
                                isClearable={true}
                                options={proyectosFiltrados}
                                onChange={(e) => {
                                    const { search, params } = this.state;
                                    if(e) {
                                        params.tipo_proyecto = e.value;
                                    } else {
                                        delete params.tipo_proyecto;
                                    }
                                    this.setState({params});
                                    this.props.listar(1, { ...params, search });
                                }}
                            />
                        </div>

                </div>

                 <div className="col-12 col-md-2">
                        {role == 1 || role == 10 ? (
                            <React.Fragment>
                                <ExcelFile element={<button className="btn btn-light mt-4"
                                    style={{ borderColor: '#1F4D7B', color: '#1F4D7B', fontSize: 15 }}>Exportar</button>} filename="proyectos">
                                    <ExcelSheet dataSet={multiDataSet} name="Proyectos Activos" />
                                    
                                        
                                </ExcelFile>
                            </React.Fragment>
                        ) : null}
                    </div>
               
                {filteredData && filteredData.results? <Grid
                       
                        data={filteredData}
                        loading={loader}
                        page={this.state.page}
                        // page_size={this.state.params.page_size}
                        onPageChange={this.onPageChange}
                        // pagination={false}

                        
                    >
                         <TableHeaderColumn
                            dataField="id"
                            dataSort
                            tdStyle={this.state.BreakLine}
                            dataFormat={(cell, row) => {
                                return cell ? <span>{cell}</span> : "---";
                            }}
                           
                        >
                            Id 
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                            tdStyle={this.state.BreakLine}
                            dataFormat={(cell, row) => {
                                return cell ? <span>{cell}</span> : "---";
                            }}
                            // className="thead--blue--individual"
                        >
                            Proyecto
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="tipo_proyecto"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                            tdStyle={this.state.BreakLine}
                            // className="thead--blue--individual"
                        >
                            Tipo de Proyecto
                        </TableHeaderColumn>
                      
                        <TableHeaderColumn
                            dataField="encargado"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell ? cell.nombre : "---";
                            }}
                            tdStyle={this.state.BreakLine}
                            // className="thead--blue--individual"
                        >
                            Encargado
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell
                                    ? moment(cell).format("DD/MM/YYYY")
                                    : "";
                            }}
                            // className="thead--blue--individual"
                        >
                            Fecha estimada de finalización
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            dataField="fases"  
                            dataSort
                            dataFormat={(cell, row) => {
                                // Verifica si la propiedad "fases" existe en el objeto y tiene elementos
                                if (row.fases && row.fases.length > 0) {
                                    // Encuentra la fase que esté dentro del rango de fechas
                                    const fase = row.fases.find(f =>
                                        moment(f.fecha_inicio).isBefore(moment()) && moment(f.fecha_fin).isAfter(moment())
                                    );
                                    if (fase) {
                                        return fase.nombre_fase;  // Devuelve el nombre de la fase  dentro del rango
                                    } else {
                                        return "---";  // Si no se encuentra ningún recurso en el rango, muestra un valor predeterminado
                                    }
                                } else {
                                    return "---";  
                                }
                            }}
                           
                        >
                            Fase del Proyecto (Fecha Actual)
                        </TableHeaderColumn>
                       
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            dataFormat={(cell, row) => (
                                <Link to={`/reporte_detallado/${row.id}/editar/reporteDetallado`} className="btn btn-primary">
                                    Ver
                                </Link>
                        )}
                        >
                            Acciones
                        </TableHeaderColumn>
                    </Grid> :  <span>No hay datos</span>}
            </React.Fragment>
        );
    }
}

export default Listar;