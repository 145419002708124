import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/tipos_proyecto/tipos_proyecto';
import CrearEditarCategoria from './CrearEditarCategoria';

const mapStateToProps = (state) => ({
    ...state.tipos_proyecto,
    categorias: state.tipos_proyecto.categorias,
    tipos_proyecto: state.tipos_proyecto.all_tipos_proyectos,
});

const mapDispatchToProps = { 
    ...actions 
};

export default connect(mapStateToProps, mapDispatchToProps)(CrearEditarCategoria);
