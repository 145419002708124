import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarForm from "./CrearEditarForm";
import React, { Component } from "react";

class CrearEditar extends Component {
    state = {
        edit: false,
        loader: false,
        id: null,
    };

    async componentDidMount() {
        this.props.limpiarForm();
        this.props.obtenerSeniority();
        this.props.obtenerGastos();
        this.props.obtenerPlantillas();
        this.props.obtenerClientes();
        this.props.obtenerTipos();
        this.props.obtenerFases();
        this.props.obtenerActividades();
        this.props.obtenerPuestos();
        this.props.getUnidadesNegocio();
        this.setState({ loader: false });
        const edit = this.props.match.params.id ? true : false;
        this.setState({ edit });
        if (edit == true) {
            const id = this.props.match.params.id;
            this.setState({ id });
            this.props.obtenerProyectoOperaciones(id);
        } else {
            this.props.loadFromDraft(this.props.draft);
        }
    }

    onSubmit = (data, revision = false) => {
        const { edit, id } = this.state;
        const { onSubmit, actualizarProyecto } = this.props;
        if (edit) {
            return actualizarProyecto(data, id);
        } else {
            onSubmit(data, revision);
        }
    };

    aprobarProyecto = () => {
        const { edit, id } = this.state;
        const { aprobarProyecto: aprobar } = this.props;
        if (edit) {
            aprobar(id);
        }
    };

    enviarRevision = () => {
        const { edit, id } = this.state;
        const { enviarRevision: revision } = this.props;
        if (edit) {
            revision(id);
        }
    };

    ventaGanadaPerdida = (ganada = true) => {
        const { edit, id } = this.state;
        const { ventaGanadaPerdida: venta } = this.props;
        if (edit) {
            venta(id, ganada);
        }
    }

    render() {
        const {
            loader,
            loaderActividades,
            crearActividad,
            actividades,
            fases,
            loaderFases,
            puestos,
            loaderPuestos,
            clientes,
            tipos_proyectos,
            plantillas,
            loaderPlantillas,
            gastos,
            loaderGastos,
            currentPlantilla,
            obtenerPlantilla,
            seniority,
            loaderSeniority,
            updateDraft,
        } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {this.state.edit ? "EDITAR" : "CREAR"} PROYECTO
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-center">
                    <div className="w-100">
                        <LoadMask
                            loading={
                                loaderSeniority ||
                                loaderGastos ||
                                loaderPlantillas ||
                                loaderPuestos ||
                                loaderFases ||
                                loaderActividades ||
                                loader ||
                                this.state.loader
                            }
                            light
                        >
                            <CrearEditarForm
                                onSubmit={this.onSubmit}
                                actividades={actividades}
                                fases={fases}
                                puestos={puestos}
                                clientes={clientes}
                                tipos_proyectos={tipos_proyectos}
                                plantillas={plantillas}
                                gastos={gastos}
                                seniority={seniority}
                                currentPlantilla={currentPlantilla}
                                obtenerPlantilla={obtenerPlantilla}
                                crearActividad={crearActividad}
                                updateDraft={updateDraft}
                                edit={this.state.edit}
                                history={this.props.history}
                                aprobarProyecto={this.aprobarProyecto}
                                enviarRevision={this.enviarRevision}
                                ventaGanadaPerdida={this.ventaGanadaPerdida}
                                updateField={this.props.updateField}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditar;
