import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import CrearEditarSubCategoriaForm from "./CrearEditarSubCategoriaForm";

class CrearEditarSubCategoria extends Component {
    state = {
        edit: false,
        loader: true,
        id: null,
    };

    componentDidMount() {
        const { match } = this.props;
        const subCategoriaId = match.params.id;

        if (subCategoriaId) {
            this.setState({ edit: true, id: subCategoriaId, loader: false });
            this.props.obtenerSubcategoria(subCategoriaId);
        } else {
            this.setState({ edit: false, loader: false });
        }
    }

    onSubmit = (data) => {
        const { edit, id } = this.state;
        const { crearSubCategoria, actualizarSubCategoria, history } = this.props;
        const categoriaId = new URLSearchParams(this.props.location.search).get('categoria');

        const dataWithTipoId = { ...data, categoria: categoriaId };

        if (edit) {
            actualizarSubCategoria(id, dataWithTipoId).then(() => {
                history.push("/tipos-proyecto");
            });
        } else {
            crearSubCategoria(dataWithTipoId).then(() => {
                history.push("/tipos-proyecto");
            });
        }
    };

    render() {
        const { loader, edit } = this.state;
        const { categorias } = this.props;

        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-12">
                        <h2 className="title--blue">
                            {edit ? "EDITAR" : "CREAR"} SUBCATEGORÍA
                        </h2>
                    </div>
                </div>
                <hr />
                <div className="login-wrapper">
                    <div className="card card-login w-100">
                        <LoadMask loading={loader} light>
                            <CrearEditarSubCategoriaForm
                                onSubmit={this.onSubmit}
                                categorias={categorias}
                            />
                        </LoadMask>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CrearEditarSubCategoria;
