import React, { Component } from "react";
import Grid from "../../Utils/Grid";
import { standardActions } from "../../Utils/Grid/StandardActions";
import { Link } from "react-router-dom";
import { TableHeaderColumn } from "react-bootstrap-table";
import FiltersData from "./FiltersData"
import Visualizar from "./Visualizar";
import { api } from "api";
import moment from "moment";

class Listar extends Component {
    state = {
        page: 1,
        params: {
            activo: true,
        },
        paises: [],
        years: [],
        activeTab: 'calendario',
    };

    async componentDidMount() {
        this.props.listar(this.state.page, this.state.params);
        try {
            const paises = await api.get("pais/filter_list");
            const years = await api.get("asueto/years");
            this.setState({ paises, years });
        } catch (error) {}
    }

    async componentDidUpdate(prevProps, prevState){
        if(prevState.params !== this.state.params){
            const page = 1
            this.setState({ page });
            this.props.listar(page, this.state.params);
        }
    }

    onPageChange = (page) => {
        this.props.listar(page, this.state.params);
        this.setState({ page });
    };

    onChangeParams = (newParam) => {
        let params = this.state.params;
        if(!newParam.value){
            delete params[newParam.name]
            params = {
                ...params,
            }
        }else{
            params = {
                ...params,
                [newParam.name]: newParam.value,
            }
        }
        this.setState({ params });
    }

    setActiveTab = (tab) => {
        this.setState({ activeTab: tab });
    };

    render() {
        const { data, loader, eliminar, visualizar } = this.props;
        const { activeTab } = this.state;
        
        return (
            <React.Fragment>
                <div className="flex row mt-2">
                    <div className="col-12">
                        <h2 className="title--blue">ASUETOS</h2>
                    </div>
                </div>
                <hr className="mb-3"/>

                <div className="flex row mt-1 mb-4">
                    <div className="col-12 col-md-3 ">
                        <fieldset className="display--fieldset">
                        <label className="container--1">
                                <input
                                    type="radio"
                                    value="calendario"
                                    checked={activeTab === 'calendario'}
                                    onChange={() => this.setActiveTab('calendario')}
                                    className="input--style"
                                />
                                Calendario
                            </label>
                            <label className="container--1">
                                <input
                                    type="radio"
                                    value="listar"
                                    checked={activeTab === 'listar'}
                                    onChange={() => this.setActiveTab('listar')}
                                    className="input--style"
                                />
                                Listado de asuetos
                            </label>
                        </fieldset>
                    </div>
                </div>
                
                {activeTab === 'listar' && (
                <React.Fragment>    
                <div className="row">
                    <FiltersData
                        onChangeParams={this.onChangeParams.bind(this)}
                        years={this.state.years}
                        paises={this.state.paises}
                        currentParams={this.state.params}
                    />
                     <div
                        className="col-12 col-md-3"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Link
                            to="/asuetos/crear"
                            className="btn btn-primary mt-4"
                            style={{ fontSize: 15, borderRadius: "5px" }}
                        >
                            Nuevo asueto
                        </Link>
                    </div>
                </div>

                <Grid
                    data={data}
                    loading={loader}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                >
                    <TableHeaderColumn
                        dataField="nombre"
                        dataSort
                        width="25%"
                    >
                        Nombre del asueto
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="pais"
                        dataSort
                    >
                        País
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="year"
                        dataSort
                    >
                        Año
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_inicio"
                        dataSort
                        dataFormat={(cell, row) => {
                            moment.locale('es')
                            const m = moment(cell);
                            return m.format('l');
                        }}
                    >
                        Fecha inicio
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="fecha_fin"
                        dataSort
                        dataFormat={(cell, row) => {
                            moment.locale('es')
                            const m = moment(cell);
                            return m.format('l');
                        }}
                    >
                        Fecha fin
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataField="id"
                        dataSort
                        isKey
                        dataFormat={(cell, row) => {
                            let props = {};
                            props.editar = "asuetos";
                            props.eliminar = eliminar;
                            const actions = standardActions({ ...props });
                            return actions(cell, row);
                        }}
                    >
                        Acciones
                    </TableHeaderColumn>
                </Grid>
                </React.Fragment>
                )}

                {activeTab === 'calendario' && (
                    <Visualizar data={visualizar} />
                )}
               
            </React.Fragment>

        );
    }
}

export default Listar;
