// DatosProyecto.js
import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import {
  renderField,
  renderSelectField,
  renderSelectUnidadesNegocio,
  renderSelectFieldObject,
} from "../../../Utils/renderField";

const DatosProyecto = (props) => {
  const {
    clientes,
    plantillas,
    obtenerPlantilla,
    isDisabled = false,
    tipos_proyectos,
    tipoProyectoValue,
    categoriaValue,
    subcategoriaValue,
    updateField,
  } = props;

  const [nombrePais, setNombrePais] = useState(null);
  const [selectedUnidadNegocio, setSelectedUnidadNegocio] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [selectedSubcategoria, setSelectedSubcategoria] = useState(null);
  const [prefix, setPrefix] = useState("");
  const [codigoInput, setCodigoInput] = useState("");


  function calcPrefix(unidad, categoria, subcategoria) {
    if (subcategoria && subcategoria.prefijo) {
      return subcategoria.prefijo;
    } else if (categoria && categoria.prefijo) {
      return categoria.prefijo;
    } else if (unidad && unidad.prefijo) {
      return unidad.prefijo;
    }
    return "";
  }


  function handleClienteChange(selectedCliente) {
    if (selectedCliente && selectedCliente.pais) {
      setNombrePais(selectedCliente.pais);
    } else {
      setNombrePais(null);
    }
  }

  function handlePlantillaChange(value) {
    if (value) {
      obtenerPlantilla(value, true);
    }
  }

  function handleUnidadNegocioChange(unidad) {
    setSelectedUnidadNegocio(unidad || null);
    setSelectedCategoria(null);
    setSelectedSubcategoria(null);
    if (updateField) {
      updateField("tipo_proyecto", unidad || null);
      updateField("categoria", null);
      updateField("subcategoria", null);
    }
  }

  function handleCategoriaChange(categoria) {
    setSelectedCategoria(categoria || null);
    setSelectedSubcategoria(null);
    if (updateField) {
      updateField("categoria", categoria || null);
      updateField("subcategoria", null);
    }
  }

  function handleSubcategoriaChange(subcategoria) {
    setSelectedSubcategoria(subcategoria || null);
    if (updateField) {
      updateField("subcategoria", subcategoria || null);
    }
  }

  function handleCodigoChange(e) {
    setCodigoInput(e.target.value);
  }

  useEffect(() => {
    const newPrefix = calcPrefix(
      selectedUnidadNegocio,
      selectedCategoria,
      selectedSubcategoria
    );
    setPrefix(newPrefix);
  }, [selectedUnidadNegocio, selectedCategoria, selectedSubcategoria]);

  useEffect(() => {
    if (updateField) {
      const finalCode = prefix !== "" ? prefix + "-" + codigoInput : codigoInput;
      updateField("codigo", finalCode);
    }
  }, [prefix, codigoInput, updateField]);

  useEffect(() => {
    let tp = tipoProyectoValue;
    if (tp && typeof tp !== "object") {
      for (let i = 0; i < tipos_proyectos.length; i++) {
        if (tipos_proyectos[i].value === tp) {
          tp = tipos_proyectos[i];
          break;
        }
      }
      if (typeof tp !== "object") {
        tp = null;
      }
    }
    setSelectedUnidadNegocio(tp);

    let cat = categoriaValue;
    if (cat && typeof cat !== "object" && tp && tp.categorias) {
      for (let j = 0; j < tp.categorias.length; j++) {
        if (tp.categorias[j].value === cat) {
          cat = tp.categorias[j];
          break;
        }
      }
      if (typeof cat !== "object") {
        cat = null;
      }
    }
    setSelectedCategoria(cat);

    let sub = subcategoriaValue;
    if (sub && typeof sub !== "object" && cat && cat.subcategorias) {
      for (let k = 0; k < cat.subcategorias.length; k++) {
        if (cat.subcategorias[k].value === sub) {
          sub = cat.subcategorias[k];
          break;
        }
      }
      if (typeof sub !== "object") {
        sub = null;
      }
    }
    setSelectedSubcategoria(sub);

    const newPrefix = calcPrefix(tp, cat, sub);
    setPrefix(newPrefix);

    if (updateField) {
      updateField("tipo_proyecto", tp);
      updateField("categoria", cat);
      updateField("subcategoria", sub);
    }
  }, [tipoProyectoValue, categoriaValue, subcategoriaValue, tipos_proyectos, updateField]);

  const availableCategorias =
    selectedUnidadNegocio && selectedUnidadNegocio.categorias
      ? selectedUnidadNegocio.categorias
      : [];
  const availableSubcategorias =
    selectedCategoria && selectedCategoria.subcategorias
      ? selectedCategoria.subcategorias
      : [];

  return (
    <div>
      <h5 className="title--blue--1">Datos del proyecto</h5>
      <div className="card-form">
        <div className="row">
          {/* Nombre del Proyecto */}
          <div className="form-group has-feedback col-6">
            <label htmlFor="nombre">Nombre Proyecto</label>
            <Field
              name="nombre"
              component={renderField}
              type="text"
              className="input--style"
              placeholder="Nombre..."
              readOnly={isDisabled}
            />
          </div>

          {/* Cliente y País */}
          <div className="form-group has-feedback col-6">
            <div className="d-flex">
              <label htmlFor="cliente">Cliente</label>
              {nombrePais && <label>&emsp;(País: {nombrePais})</label>}
            </div>
            <Field
              name="cliente"
              component={renderSelectField}
              options={clientes}
              className="input--style"
              labelKey="label"
              valueKey="value"
              placeholder="Cliente..."
              select_style={{
                control: (styles) =>
                  Object.assign({}, styles, {
                    color: "#1F4D7B",
                    borderColor: "#1F4D7B",
                  }),
              }}
              disabled={isDisabled}
              extra_change
              objeto
              extraChange={handleClienteChange}
            />
          </div>
        </div>

        <div className="row">
          {/* Plantilla del Proyecto */}
          <div className="form-group has-feedback col-6">
            <label htmlFor="plantilla">Plantilla del proyecto</label>
            <Field
              name="plantilla"
              component={renderSelectField}
              options={plantillas}
              className="input--style"
              labelKey="label"
              valueKey="value"
              placeholder="Plantilla..."
              select_style={{
                control: (styles) =>
                  Object.assign({}, styles, {
                    color: "#1F4D7B",
                    borderColor: "#1F4D7B",
                  }),
              }}
              extra_change
              extraChange={handlePlantillaChange}
              disabled={isDisabled}
            />
          </div>

          {/* Unidad de Negocio */}
          <div className="form-group has-feedback col-6">
            <label htmlFor="tipo_proyecto">Unidad de negocio</label>
            <Field
              name="tipo_proyecto"
              component={renderSelectUnidadesNegocio}
              options={tipos_proyectos}
              placeholder="Seleccionar unidad de negocio..."
              disabled={isDisabled}
              onChangeSelect={handleUnidadNegocioChange}
            />
          </div>
        </div>

        <div className="row">
          {/* Categoría: se muestra si la unidad tiene categorías */}
          {selectedUnidadNegocio && availableCategorias.length > 0 && (
            <div className="form-group has-feedback col-6">
              <label htmlFor="categoria">Categoría</label>
              <Field
                name="categoria"
                component={renderSelectFieldObject}
                options={availableCategorias}
                placeholder="Seleccionar categoría..."
                select_style={{
                  control: (styles) =>
                    Object.assign({}, styles, {
                      color: "#1F4D7B",
                      borderColor: "#1F4D7B",
                    }),
                }}
                onChangeSelect={handleCategoriaChange}
                disabled={isDisabled}
                isClearable={true}
              />
            </div>
          )}

          {/* Subcategoría: se muestra si la categoría tiene subcategorías */}
          {selectedCategoria && availableSubcategorias.length > 0 && (
            <div className="form-group has-feedback col-6">
              <label htmlFor="subcategoria">Subcategoría</label>
              <Field
                name="subcategoria"
                component={renderSelectFieldObject}
                options={availableSubcategorias}
                placeholder="Seleccionar subcategoría..."
                select_style={{
                  control: (styles) =>
                    Object.assign({}, styles, {
                      color: "#1F4D7B",
                      borderColor: "#1F4D7B",
                    }),
                }}
                onChangeSelect={handleSubcategoriaChange}
                disabled={isDisabled}
                isClearable={true}
              />
            </div>
          )}
        </div>

        <div className="row">
          {/* Campo Código (ID) */}
          <div className="form-group has-feedback col-6">
            <label htmlFor="codigo">
              ID <small>(se almacenará con el prefijo)</small>
            </label>
            <Field
              name="codigo"
              component="input"
              type="text"
              className="form-control input--style"
              placeholder="Ingresa el ID..."
              value={codigoInput}
              onChange={handleCodigoChange}
              disabled={isDisabled}
            />
            {prefix !== "" && (
              <small className="text-muted">Prefijo actual: {prefix}</small>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { DatosProyecto };
