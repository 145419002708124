import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Eliminar from "../../Recursos/CrearEditar/Eliminar";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { ExtraActions } from "./ExtraActions"

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popup_opened: false
        }
    }

    eliminar = (id, params={}) => {
        return () => {
            Swal.fire({
                title: '¿Eliminar?',
                text: '¡No podrá revertir esta acción!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: '¡Sí, eliminar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    if(params) this.props.eliminar(id, params);
                    else this.props.eliminar(id);
                }
            });
        }
    };

    //swal para reactivar colaborador
    reactivar = (id, params={}) => {
        return () => {
            Swal.fire({
                title: '¿Reactivar?',
                text: '¿Estás seguro de que quieres reactivar este recurso?',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, reactivar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    if(params) {
                        if (this.props.reactivar !== undefined) {
                            this.props.reactivar(id, params);
                        }
                    } else {
                        if (this.props.reactivar !== undefined) {
                            this.props.reactivar(id);
                        }
                    }
                }
            });
        }
    };

    //swal para enviar a operaciones
    enviarAOperaciones = (id, params={}) => {
        return () => {
            Swal.fire({
                title: '¿Enviar a operaciones?',
                text: '¿Estás seguro de que quieres enviar a operaciones este proyecto?',
                type: 'question',
                showCancelButton: true,
                confirmButtonText: '¡Sí, enviar!',
                cancelButtonText: 'No, cancelar',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    if(params) {
                        if (this.props.enviarAOperaciones !== undefined) {
                            this.props.enviarAOperaciones(id, params);
                        }
                    } else {
                        if (this.props.enviarAOperaciones !== undefined) {
                            this.props.enviarAOperaciones(id);
                        }
                    }
                }
            });
        }
    };
    



    finalizar = () => {
        this.setState({ popup_opened: true });
    }

    setPopupOpened = () => {
        this.setState({ popup_opened: false });
    }


    //se añade reactivar a las props
    //se añade enviarAOperaciones a las props
    render() {
        const { id, ver, editar, eliminar, reactivar, enviarAOperaciones,  general, reporteDetallado, recursos, finalizar, params, extraActions = [] } = this.props;

        return (
            <React.Fragment>
                <Popup
                    open={this.state.popup_opened}
                    modal
                    closeOnEscape={false}
                    closeOnDocumentClick={false}
                >
                    <Eliminar id={id} renunciaDespido={finalizar} params={params} close={this.setPopupOpened}/>
                </Popup>
                <div className="d-flex justify-content-center">
                    {(ver !== undefined) && (
                        <Link to={`${ver}/${id}/`} className="px-2" ><i className="material-icons">remove_red_eye</i></Link>
                    )}
                    {(editar !== undefined) && (
                        <Link className="standard-action action--edit" to={`${editar}/${id}/editar`} ><i className="material-icons">edit</i></Link>
                    )}
                    {(general !== undefined) && (
                        <Link className="standard-action text-secundary" to={`proyectos/${id}/editar/general`} ><i className="material-icons">receipt</i></Link>
                    )}
                    

                    {(reporteDetallado !== undefined) && (
                        <Link className="standard-action action--resources" to={`/reporte_detallado/${id}/editar/reporteDetallado`} ><i className="material-icons">remove_red_eye</i></Link>
                    )}

                    {(recursos !== undefined) && (
                        <Link className="standard-action action--resources" to={`proyectos/${id}/editar/recursos`} ><i className="material-icons">supervisor_account</i></Link>
                    )}
                    {(eliminar !== undefined) && (
                        <a className="standard-action action--delete" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminar(id, params)}><i className="material-icons">delete</i></a>
                    )}
                    {(finalizar !== undefined) && (
                        <a className="standard-action action--end" style={{cursor: "pointer", color: "#ffb53b"}} onClick={() => this.finalizar()}><i className="material-icons">receipt</i></a>
                    )}
                            

                    {(reactivar !== undefined) && (
                        <a className="standard-action action--reactivate" style={{cursor: "pointer", color: "#007bff"}} onClick={this.reactivar(id, params)}>
                            <i className="material-icons">check_circle</i>
                        </a>
                    )}

                    {(enviarAOperaciones !== undefined) && (
                        <a className="standard-action action--reactivate" style={{cursor: "pointer", color: "#007bff"}} onClick={this.enviarAOperaciones(id, params)}>
                            <i className="material-icons">double_arrow</i>
                        </a>
                    )}


                    {extraActions}
                    {(extraActions.length < 1 && finalizar == undefined && recursos == undefined && general == undefined && reporteDetallado == undefined && eliminar == undefined && editar == undefined && ver == undefined) && (
                        <span>Acciones no disponibles</span>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
Acciones.propTypes = {
};

export function standardActions(acciones, extraActionsConfig = []) {

    const extraActions = extraActionsConfig
        .map((action) => {
            const ActionComponent = ExtraActions[action.type];
            if (ActionComponent) return <ActionComponent {...action.props} />;
            return null;
        })
        .filter((action) => action !== null);

    return (cell, row) => {
        return ( <Acciones id={cell} {...acciones} extraActions={extraActions} /> )
    };
}
