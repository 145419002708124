import React, { Component } from "react";
import LoadMask from "Utils/LoadMask/LoadMask";
import { api } from "api";
import "./home.css";
import "./clientes.css";
import moment from "moment";
import Popup from "reactjs-popup";
import Recursos from "./Recursos";
import { DateRange } from "react-date-range";
import locale from "date-fns/locale/es";
import Clientes from './Clientes';
import Visualizar from "./Visualizar";
import { Chart, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2';
import Finalizados from "./Finalizados";
import { Holidays } from "./Holidays";

//=================== CSS =============================
import "reactjs-popup/dist/index.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

Chart.register(...registerables); 

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popup_opened: false,
            data: [],
            date_range: {
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
            },
            fecha_inicio: "",
            fecha_fin: "",
            popup_dates_open: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
    }    


    filterHolidays() {
        const today = moment().format("YYYY-MM-DD");
        const oneMonth = moment().add(30, "d").format("YYYY-MM-DD");
        const { getHolidays } = this.props;
        getHolidays({ inicio_after: today, inicio_before: oneMonth});
    }

    componentDidMount() {
        this.props.listar();
        this.props.getClientes();
        this.props.getFinalizados();
        this.filterHolidays();
        const fecha_inicio = this.getDateFormtar(new Date());
        let date_one_month = new Date();
        date_one_month.setMonth(date_one_month.getMonth() + 1);
        const fecha_fin = this.getDateFormtar(date_one_month);
        this.setState({
            fecha_inicio,
            fecha_fin,
            date_range: {
                startDate: new Date(),
                endDate: date_one_month,
                key: "selection",
            }
        });
        this.props.getHoras(fecha_inicio, fecha_fin);
    }

    handleSelect(range) {
        const inicio = range.selection.startDate, fin = range.selection.endDate;
        const fecha_inicio = this.getDateFormtar(inicio);
        const fecha_fin = this.getDateFormtar(fin);
        this.setState({
            date_range: {
                startDate: inicio,
                endDate: fin,
                key: range.selection.key,
            },
            fecha_inicio,
            fecha_fin
        });
        this.props.getHoras(fecha_inicio, fecha_fin);
    };

    getDateFormtar(date) {
        const month1 = (date.getMonth() + 1).toString();
        const month = month1.length == 1 ? "0" + month1 : month1;
        const day =
            date.getDate().toString().length == 1
                ? "0" + date.getDate().toString()
                : date.getDate().toString();
        return `${date.getFullYear()}-${month}-${day}`;
    }

    openModal(fases) {
        this.setState({
            popup_opened: true,
            data: fases,
        });
    }

    render() {
        const { loader, data, horas, loader1, loader2, clientes, loader3, finalizados, holidays, loaderHolidays, visualizar, tableData } = this.props;

        let options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'right',
              },
              title: {
                display: true,
                text: 'Horas atrasadas por fase',
              },
            },
          };
          if(horas.data) {
            options.plugins.tooltip = {
                enabled: false,
                external: function(context) {
                    let tooltipEl = document.getElementById('chartjs-tooltip');

                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.innerHTML = `<div class="card">
                                                    <div class="card-body">
                                                        <p class="card-title"></p>
                                                        <span class="card-subtitle mb-2 text-muted">Proyectos:</span>
                                                        <ul></ul>
                                                    </div>
                                                </div>`;
                        document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }

                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem) {
                        return {"dataIndex": bodyItem.dataIndex, "dataset": bodyItem.dataset, "formattedValue": bodyItem.formattedValue};
                    }

                    if (tooltipModel.body) {
                        const bodyLines = tooltipModel.dataPoints.map(getBody);
                        let innerHtml = '';

                        if(bodyLines[0].dataset.proyectos.length > 0) {
                            if(bodyLines[0].dataset.proyectos[bodyLines[0].dataIndex].length > 0){
                                innerHtml += `<li>`;
                                bodyLines[0].dataset.proyectos[bodyLines[0].dataIndex].forEach(e => {
                                    innerHtml += `${e}`
                                });
                                innerHtml += '</li>'
                            }
                        }

                        let tableRoot = tooltipEl.querySelector('ul');
                        let title = tooltipEl.querySelector('p');
                        title.innerHTML = context.tooltip.body[0].lines[0] + ' horas';
                        tableRoot.innerHTML = innerHtml;
                    }

                    const position = context.chart.canvas.getBoundingClientRect();

                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            }
          }
        return (
            <React.Fragment>
                <Popup
                    open={this.state.popup_opened}
                    modal
                    onClose={() => this.setState({ popup_opened: false })}
                >
                    <div className="row">
                        <div className="col-12" style={{overflowX: 'auto'}}>
                            <table className="content--table table--fixed" style={{margin: '0', borderRadius: '0'}}>
                                <thead className="thead--fixed">
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Fechas</th>
                                    </tr>
                                </thead>
                                <tbody className="tbody--fixed">
                                    {this.state.data.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.nombre}</td>
                                            <td>
                                                <ul style={{ margin: "10px" }}>
                                                    <li>
                                                        Fechas programadas:
                                                        <br />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-around",
                                                            }}
                                                        >
                                                            <p>
                                                                Inicio:{" "}
                                                                {moment(
                                                                    e.fecha_inicio
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                            </p>
                                                            <p>
                                                                Fin:{" "}
                                                                {moment(
                                                                    e.fecha_fin
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        Fechas ejecución:
                                                        <br />
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-around",
                                                            }}
                                                        >
                                                            <p>
                                                                Inicio:{" "}
                                                                {moment(
                                                                    e.fecha_inicio_ejecucion
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                            </p>
                                                            <p>
                                                                Fin:{" "}
                                                                {moment(
                                                                    e.fecha_fin_ejecucion
                                                                ).format(
                                                                    "DD/MM/YYYY"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Popup>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="title--blue">DASHBOARD</h2>
                        </div>
                    </div>
                    <hr />
                    <LoadMask loading={loader || loader1 || loader2 || loader3 || loaderHolidays} light>
                        <Holidays holidays={holidays}/>
                        <Visualizar data={visualizar} />
                        <div className="row">
                            <div className="col-12">
                                <h4 className="title--blue--1">Recursos total</h4>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            {data.total_personal ? (
                                <Recursos
                                    title="Total de personal contratado:"
                                    data={data.total_personal}
                                />
                            ) : null}

                            <div className="col-12 col-md-6 d-flex align-items-center justify-content-center flex-column">

                                <div>
                                    <h5 className="text--blue--1">Personal disponible: {data.cantidad_sin_proyectos}</h5>
                                </div>

                                <div>
                                    <h5 className="text--blue--1">Proyectos asignados por SM</h5>
                                    <BootstrapTable
                                    tableContainerClass="content--table--1"
                                    data={data.proyectos_tipo}
                                    options={{
                                        noDataText: "No hay datos",
                                    }}
                                    >
                                        <TableHeaderColumn
                                            dataField="nombre"
                                            dataSort
                                            isKey
                                        >
                                            Scrum Master
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="proyectos" dataSort
                                        dataFormat={(cell, row) => {
                                            let total = 0;
                                            for(let i = 0; i < cell.length - 2; i++){
                                                total+= cell[i].activos;
                                            }
                                            return total;
                                        }}
                                        >
                                            Cantidad activos
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </div>

                                <div>
                                    <h5 className="text--blue--1">Proyectos activos</h5>
                                    <BootstrapTable
                                    tableContainerClass="content--table--1"
                                    data={data.proyectos_activos_por_tipo}
                                    options={{
                                        noDataText: "No hay datos",
                                    }}
                                    >
                                        <TableHeaderColumn
                                            dataField="nombre"
                                            dataSort
                                            isKey
                                        >
                                            Nombre
                                        </TableHeaderColumn>
                                        <TableHeaderColumn dataField="cantidad" dataSort
                                        >
                                            Cantidad activos
                                        </TableHeaderColumn>
                                    </BootstrapTable>
                                </div>

                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                <h4 className="title--blue--1">Recursos este año</h4>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            {data.total_personal_parcial ? (
                                <Recursos
                                    title="Total de personal contratado en el año:"
                                    data={data.total_personal_parcial}
                                />
                            ) : null}
                            {data.renuncias_despidos_parcial ? (
                                <Recursos
                                    title="Renuncias y despidos año actual:"
                                    data={data.renuncias_despidos_parcial}
                                    align={{display: 'flex', alignItems: 'end', justifyContent: 'flex-end' }}
                                />
                            ) : null}
                        </div>
                        <div className="row mt-4">
                            <Clientes title="Recursos por clientes" data={clientes} />
                        </div>
                        
                        <div className="row mt-4">
                            {data.proyectos_tipo &&
                            data.proyectos_tipo.length > 0 ? (
                                <div className="col-12">
                                    <h4 className="title--blue--1">
                                        Proyectos por tipo {"&"} SCRUM Master
                                    </h4>
                                    <hr />
                                    <div style={{overflowX: 'auto'}}>
                                    <table className="content--table">
                                        <thead>
                                            <tr>
                                                <td scope="col"></td>
                                                {data.proyectos_tipo_nombre.map(
                                                    (e, i) => (
                                                        <td scope="col" key={i}>
                                                            {e}
                                                        </td>
                                                    )
                                                )}
                                                <td>GT</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th style={{backgroundColor: "#D4DBED"}}>Nombre</th>
                                                {data.proyectos_tipo_nombre.map(
                                                    (e, i) => (
                                                        <th scope="col" key={i} style={{backgroundColor: "#D4DBED"}}>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-around",
                                                                }}
                                                            >
                                                                <div>A</div>
                                                                <div>C</div>
                                                            </div>
                                                        </th>
                                                    )
                                                )}
                                                <th style={{backgroundColor: "#D4DBED"}}></th>
                                            </tr>
                                            {data.proyectos_tipo.map((e, i) => (
                                                <tr key={i}>
                                                    <td>{e.nombre}</td>
                                                    {e.proyectos.map((f, j) => (
                                                        <td key={j}>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    justifyContent:
                                                                        "space-around",
                                                                }}
                                                            >
                                                                <div>
                                                                    {f.activos}
                                                                </div>
                                                                <div>
                                                                    {
                                                                        f.finalizados
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="row mt-4">
                            <div className="col-12">
                                <h4 className='title--blue--1'>Proyectos finalizados por SCRUM Master</h4>
                                <hr />
                                <Finalizados data={finalizados} />
                            </div>
                        </div>
                        <div className="row mt-4">
                            {horas.data ? (
                                <div className="col-12">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            marginBottom: '1%'
                                        }}
                                    >
                                        <h4 className="title--blue--1">Fases atrasadas</h4>
                                        <button
                                            className="btn btn-light"
                                            style={{borderColor: '#1F4D7B'}}
                                            onClick={() => {
                                                this.setState({
                                                    popup_dates_open: true,
                                                });
                                            }}
                                        >
                                            {moment(
                                                this.state.fecha_inicio
                                            ).format("DD/MM/YYYY")}
                                            &nbsp;-&nbsp;
                                            {moment(
                                                this.state.fecha_fin
                                            ).format("DD/MM/YYYY")}
                                        </button>
                                    </div>
                                    <Popup
                                        open={this.state.popup_dates_open}
                                        onClose={() => { this.setState({ popup_dates_open: false }) }}
                                    >
                                        <div className="row">
                                            <div className="col" />
                                            <div className="col">
                                                <DateRange
                                                    editableDateInputs={true}
                                                    ranges={[
                                                        this.state.date_range,
                                                    ]}
                                                    onChange={this.handleSelect}
                                                    locale={locale}
                                                />
                                            </div>
                                            <div className="col" />
                                        </div>
                                    </Popup>
                                    <hr />
                                    <div className="chart-container">
                                        <Bar options={options} data={horas.data}/>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="row mt-4">
                            {data.proyectos_atrasados &&
                            data.proyectos_atrasados.length > 0 ? (
                                <div className="col-12">
                                    <h4 className="title--blue--1">Proyectos atrasados</h4>
                                    <hr />
                                    <div style={{overflowX: 'auto'}}>
                                        <table className="content--table table--fixed--1">
                                            <thead className="thead--fixed--1">
                                                <tr>
                                                    <th scope="col">Nombre</th>
                                                    <th scope="col">Fechas</th>
                                                    <th scope="col">Fases</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbody--fixed--1">
                                                {data.proyectos_atrasados.map(
                                                    (e, i) => (
                                                        <tr
                                                            key={i}
                                                            className={
                                                                e.fases.length >
                                                                0
                                                                    ? "table-warning"
                                                                    : "table-danger"
                                                            }
                                                        >
                                                            <td scope="row">
                                                                {e.nombre}
                                                            </td>
                                                            <td>
                                                                <ul
                                                                    style={{
                                                                        margin: "10px",
                                                                    }}
                                                                >
                                                                    <li>
                                                                        Fechas
                                                                        programadas:
                                                                        <br />
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                Inicio:{" "}
                                                                                {moment(
                                                                                    e.fecha_inicio
                                                                                ).format(
                                                                                    "DD/MM/YYYY"
                                                                                )}
                                                                            </p>
                                                                            <p>
                                                                                Fin:{" "}
                                                                                {moment(
                                                                                    e.fecha_fin
                                                                                ).format(
                                                                                    "DD/MM/YYYY"
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        Fechas
                                                                        ejecución:
                                                                        <br />
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                justifyContent:
                                                                                    "space-between",
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                Inicio:{" "}
                                                                                {moment(
                                                                                    e.fecha_inicio_ejecucion
                                                                                ).format(
                                                                                    "DD/MM/YYYY"
                                                                                )}
                                                                            </p>
                                                                            <p>
                                                                                Fin:{" "}
                                                                                {moment(
                                                                                    e.fecha_fin_ejecucion
                                                                                ).format(
                                                                                    "DD/MM/YYYY"
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                            <td>
                                                                {e.fases
                                                                    .length >
                                                                0 ? (
                                                                    <button
                                                                        className="btn btn-info btn-sm"
                                                                        onClick={() =>
                                                                            this.openModal(
                                                                                e.fases
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="material-icons">
                                                                            receipt
                                                                        </i>
                                                                    </button>
                                                                ) : (
                                                                    <span>
                                                                        No hay
                                                                        fechas
                                                                        planificadas.
                                                                    </span>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </LoadMask>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;
