import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import { api } from "api";

const LOADER = "PERMISO_LOADER";
const DATA = "PERMISO_DATA";
const SUBMIT = "PERMISO_SUBMIT";
const SET_COLABORADOR = "PERMISO_SET_COLABORADOR_FILTER";

const ENDPOINT = "permiso";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

export const setData = (data) => ({
    type: DATA,
    data,
});

export const setColaborador = (colaborador) => ({
    type: SET_COLABORADOR,
    currentColaboradorFilter: colaborador,
});

// ------------------------------------
// Actions
// ------------------------------------
export const onSubmit =
    (data = {}) =>
    (dispatch, getStore) => {
        dispatch(setLoader(true));
        api.post(`${ENDPOINT}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha guardado correctamente",
                    type: "success",
                });
                dispatch(push("/permisos"));
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const listar =
    (page = 1, params = {}) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}`, { page, ...params })
            .then((response) => {
                dispatch(setData(response));
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const obtenerPermiso =
    (id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.get(`${ENDPOINT}/${id}`)
            .then((response) => {
                dispatch(
                    initializeForm("CrearEditarForm", {
                        tipo_permiso: response.tipo_permiso,
                        fecha_inicio: response.fecha_inicio,
                        fecha_fin: response.fecha_fin,
                        horas: response.horas,
                        colaborador: response.colaborador,
                        autorizado_por: response.autorizado_por,
                        documento_url: response.documento,
                        hora_dia: response.hora_dia,
                        tipo: response.tipo,
                    })
                );
            })
            .catch(() => {})
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const actualizarPermiso =
    (data = {}, id = null) =>
    (dispatch) => {
        dispatch(setLoader(true));
        api.put(`${ENDPOINT}/${id}`, data)
            .then(() => {
                Swal.fire({
                    title: "Éxito",
                    text: "Se ha actualizado correctamente",
                    type: "success",
                });
                dispatch(push("/permisos"));
            })
            .catch((error) => {
                const textError =
                    error && error.non_field_errors
                        ? error.non_field_errors[0]
                        : "Ha ocurrido un error, inténtelo nuevamente.";
                Swal.fire({
                    title: "Error",
                    text: textError,
                    type: "error",
                });
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const eliminar = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${ENDPOINT}/${id}`)
        .then(() => {
            Swal.fire({
                title: "Éxito",
                text: "Se ha eliminado correctamente",
                type: "success",
            });
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const colaboradorFilter = (colaborador) => (dispatch) => {
    dispatch(setColaborador(colaborador));
    dispatch(push("/permisos"));
};


export const colaboradorFilter2 = (colaborador) => (dispatch) => {
    dispatch(setColaborador(colaborador));

    dispatch(push("/permisos?vacaciones=1"));
};

export const clearFilter = () => (dispatch) => {
    dispatch(setColaborador(null));
};

export const actions = {
    onSubmit,
    eliminar,
    obtenerPermiso,
    actualizarPermiso,
    listar,
    colaboradorFilter,
    clearFilter,
    colaboradorFilter2,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_COLABORADOR]: (state, { currentColaboradorFilter }) => {
        return {
            ...state,
            currentColaboradorFilter,
        };
    },
};

export const initialState = {
    loader: false,
    data: {},
    currentColaboradorFilter: null,
};

export default handleActions(reducers, initialState);
